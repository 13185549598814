import React, { ReactElement } from 'react'

interface Props {
  className?: string
  children?: React.ReactNode
}

const H1 = ({ children, className = '' }: Props): ReactElement => {
  return (
    <h1 className={`text-2xl md:text-3xl font-semibold ${className}`}>
      {children}
    </h1>
  )
}

export default H1
