import React, { ReactElement } from 'react'

interface Props {
  children?: React.ReactNode
  className?: string
}

export const Content = ({ children, className = '' }: Props): ReactElement => {
  return (
    <div className={`p-l md:p-xl flex flex-col flex-grow ${className}`}>
      {children}
    </div>
  )
}
