import { makeObservable, observable } from 'mobx'
import VerificationStepEntity from '../dto/VerificationStepEntity.interface'
import {
  updatePersonalInformation,
  getPersonalInfromation,
  getCompanyInformation,
  updateCompanyInformation,
  saveWallet,
  getProofOfResidency,
  updateProofOfResidency,
  getIdAndLiveness,
  updateIdAndLiveness,
  getQuestionnaire,
  updateQuestionnaire,
  loadWallet,
  updateLegalDocument,
  getLegalDocuments,
  updateDirectorApproval,
  getDirectorApproval,
  deleteProofOfResidency,
  requestIdAndLivenessQRCode,
  getBeneficialOwner,
  completeLegalDocuments
} from '../api/verificationStepAPI'
import PersonalInfoEntity from '../dto/PersonalInfoEntity.interface'
import ProofOfResidencyEntity from '../dto/ProofOfResidencyEntity.interface'
import IdAndLivenessEntity from '../dto/IdAndLivenessEntity.interface'
import QuestionnaireEntity from '../dto/QuestionnaireEntity.interface'
import { toast } from 'react-toastify'
import WalletEntity from '../dto/WalletEntity.interface'
import FinishEntity from '../dto/FinishEntity.interface'
import DirectorApproval from '../dto/CompanyOfficer.interface'

export class VerificationStore {
  @observable personalInformation: PersonalInfoEntity | undefined
  @observable proofOfResidency: ProofOfResidencyEntity | undefined
  @observable verification: VerificationStepEntity | undefined
  @observable idAndLiveness: IdAndLivenessEntity | undefined
  @observable questionnaire: QuestionnaireEntity | undefined
  @observable companyInfo: any
  @observable beneficialOwner: any
  @observable directorApproval: DirectorApproval | undefined
  @observable legalDocuments: any
  @observable wallet: WalletEntity | undefined
  @observable finish: FinishEntity | undefined

  @observable idAndLivenessURL: string | undefined

  readonly isDirector: boolean = false

  constructor (args?: { isDirector?: boolean }) {
    this.isDirector = args?.isDirector ?? false
    makeObservable(this)
  }

  loadWallet = async (): Promise<void> => {
    const response = await loadWallet({
      id: this.wallet?.id,
      isDirector: this.isDirector
    })
    if (response?.status === 200) {
      this.wallet = response.data
    }
  }

  saveWallet = async (data: any): Promise<void> => {
    try {
      const response = await saveWallet({
        data: data.wallet,
        isDirector: this.isDirector
      })
      if (response?.status === 200) {
        this.wallet = response.data
      }
    } catch (err: any) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        toast.error(err.response.data.message)
      }
    }
  }

  loadPersonalInformation = async (): Promise<void> => {
    const response = await getPersonalInfromation({
      id: this.personalInformation?.id,
      isDirector: this.isDirector
    })
    if (response?.status === 200) {
      this.personalInformation = response.data
    }
  }

  updatePersonalInformation = async (
    data: any
  ): Promise<PersonalInfoEntity | undefined> => {
    try {
      const response = await updatePersonalInformation({
        data,
        isDirector: this.isDirector
      })
      if (response?.status === 200) {
        return response.data
      }
    } catch (err: any) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        toast.error(err.response.data.message)
      }
    }
    return undefined
  }

  loadCompanyInformation = async (): Promise<void> => {
    try {
      const response = await getCompanyInformation({
        id: this.companyInfo?.id
      })
      if (response?.status === 200) {
        this.companyInfo = response.data
      }
    } catch (err) {}
  }

  loadBeneficialOwner = async (): Promise<void> => {
    try {
      const response = await getBeneficialOwner({
        id: this.beneficialOwner?.id
      })
      if (response?.status === 200) {
        this.beneficialOwner = response.data
      }
    } catch (err) {}
  }

  updateCompanyInformation = async (
    data: any
  ): Promise<PersonalInfoEntity | undefined> => {
    try {
      const response = await updateCompanyInformation(data)
      if (response?.status === 200) {
        return response.data
      }
    } catch (err: any) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        toast.error(err.response.data.message)
      }
    }
    return undefined
  }

  loadDirectorApproval = async (): Promise<void> => {
    const response = await getDirectorApproval(this.directorApproval?.id)
    if (response?.status === 200) {
      this.directorApproval = response.data
    }
  }

  updateDirectorApproval = async (
    data: any
  ): Promise<DirectorApproval | undefined> => {
    try {
      const response = await updateDirectorApproval(data)
      if (response?.status === 200) {
        return response.data
      }
    } catch (err: any) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        toast.error(err.response.data.message)
      }
    }
    return undefined
  }

  loadQuestionnaireInformation = async (): Promise<void> => {
    try {
      const response = await getQuestionnaire({
        id: this.questionnaire?.id,
        isDirector: this.isDirector
      })
      if (response?.status === 200) {
        this.questionnaire = response.data
      }
    } catch (err) {}
  }

  saveQuestionnaireInformation = async (data: any): Promise<void> => {
    try {
      const response = await updateQuestionnaire({
        data,
        isDirector: this.isDirector
      })
      if (response?.status === 200) {
        this.questionnaire = response.data
      }
    } catch (err) {}
  }

  loadIdAndlivenessInformation = async (): Promise<void> => {
    try {
      const response = await getIdAndLiveness({
        id: this.idAndLiveness?.id,
        isDirector: this.isDirector
      })
      if (response?.status === 200) {
        this.idAndLiveness = response.data
      }
    } catch (err) {}
  }

  saveIdAndLivenessInformation = async (data: any): Promise<void> => {
    try {
      const response = await updateIdAndLiveness({
        data,
        isDirector: this.isDirector
      })
      if (response?.status === 200) {
        this.idAndLiveness = response.data
      }
    } catch (err) {}
  }

  loadProofOfResidencyInformation = async (): Promise<void> => {
    try {
      const response = await getProofOfResidency({
        id: this.proofOfResidency?.id,
        isDirector: this.isDirector
      })
      if (response?.status === 200) {
        this.proofOfResidency = response.data
      }
    } catch (err) {}
  }

  saveProofOfResidencyInformation = async (data: any): Promise<void> => {
    try {
      const response = await updateProofOfResidency({
        data,
        isDirector: this.isDirector
      })
      if (response?.status === 200) {
        this.proofOfResidency = response.data
      }
    } catch (err) {}
  }

  delteProofOfResidencyInformation = async (): Promise<void> => {
    try {
      if (this.proofOfResidency && this.proofOfResidency.id) {
        const response = await deleteProofOfResidency({
          id: this.proofOfResidency.id,
          isDirector: this.isDirector
        })
        if (response?.status === 200) {
          this.proofOfResidency = undefined
        }
      }
    } catch (err) {}
  }

  loadLegalDocuments = async (): Promise<void> => {
    try {
      const response = await getLegalDocuments()
      if (response?.status === 200) {
        this.legalDocuments = response.data
      }
    } catch (err) {}
  }

  saveLegalDocument = async (data: any): Promise<void> => {
    try {
      const result = await updateLegalDocument(data)
      if (result?.status === 200) {
        this.legalDocuments = result.data
      }
    } catch (err) {}
  }

  completeLegalDocuments = async (): Promise<void> => {
    try {
      await completeLegalDocuments()
    } catch (err) {}
  }

  generateQRForIDAndLiveness = async (): Promise<void> => {
    try {
      const result = await requestIdAndLivenessQRCode({
        isDirector: this.isDirector
      })
      if (result.status === 200) {
        this.idAndLivenessURL = result.data.url
      }
    } catch (err) {}
  }
}

const verificationStore = new VerificationStore()
export default verificationStore
