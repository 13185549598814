import React, { ReactElement } from 'react'

interface Props {
  children?: JSX.Element | string | JSX.Element[]
  header?: string
  newLine?: boolean
}

const UserCompletedItem = ({
  children,
  header = '',
  newLine = false
}: Props): ReactElement => {
  return (
    <li className={`flex flex-col grow ${newLine ? 'col-start-1' : ''}`}>
      <div className="text-sm text-gray-500">{header}</div>
      <div className="text-m whitespace-pre-line">{children}</div>
    </li>
  )
}

export default UserCompletedItem
