import { NodeType } from '../../../../dto/CreateNode.dto'
import { FlowType } from '../../../../dto/User.interface'
import { Node } from '../dto/Organigram'

const generateAddNode = (): Node => {
  return {
    id: String(Math.floor(Math.random() * 1000)),
    type: NodeType.ADD,
    status: 'DONE',
    children: []
  }
}

const generateCompanyNode = (): Node => {
  return {
    id: String(Math.floor(Math.random() * 1000)),
    type: NodeType.LEGAL_PERSON,
    status: 'PENDING',
    user: {
      flowType: FlowType.KYB_COMPANY
    },
    children: []
  }
}

const generatePersonNode = (): Node => {
  return {
    id: String(Math.floor(Math.random() * 1000)),
    type: NodeType.NATURAL_PERSON,
    status: 'PENDING',
    user: {
      email: 'A@a.at',
      flowType: FlowType.KYB_USER
    },
    children: []
  }
}

export { generateAddNode, generateCompanyNode, generatePersonNode }
