import React, { ReactElement } from 'react'
import UserCompletedItem from './Item'
import UserCompletedList from './List'
import UserAddress from '../../../../../../dto/UserAddress.interface'
import UserCompletedCountry from './Country'

interface Props {
  address?: UserAddress
  hideHeader?: boolean
  className?: string
}

const UserCompletedAddress = ({
  address,
  hideHeader = false,
  className = ''
}: Props): ReactElement => {
  if (!address) {
    return <></>
  }

  return (
    <div className={className}>
      {!hideHeader && (
        <h3 className="text-l font-semibold text-gray-900 mt-3">Address</h3>
      )}
      <UserCompletedList>
        <UserCompletedItem header="Street">{address.street}</UserCompletedItem>
        <UserCompletedItem header="Zip">{address.zip}</UserCompletedItem>
        <UserCompletedItem header="City">{address.city}</UserCompletedItem>
        <UserCompletedCountry header="Country" country={address.country} />
      </UserCompletedList>
    </div>
  )
}

export default UserCompletedAddress
