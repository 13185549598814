import { AxiosResponse } from 'axios'
import LoginDto from '../dto/Login.dto'
import axios from '../services/Axios'

const registerOrLogin = (
  loginData: LoginDto
): Promise<AxiosResponse<any, any>> | undefined => {
  try {
    return axios.post('/auth', loginData)
  } catch (error) {
    console.error(error)
  }
}

const verifyToken = (
  token: string,
  verificationToken: string
): Promise<AxiosResponse<any, any>> | undefined => {
  try {
    return axios.get('/auth/verify', { params: { token, verificationToken } })
  } catch (error) {
    console.error(error)
  }
}

const getMe = (): Promise<any> | undefined => {
  try {
    return axios.get('/auth/me')
  } catch (error) {
    console.error(error)
  }
}

const sendFinishLinkToDirector = (): Promise<any> | undefined => {
  const config: any = {}
  try {
    return axios.post('/auth/send-finish-link', config)
  } catch (error: any) {
    console.log(error)
  }
}

export { registerOrLogin, verifyToken, getMe, sendFinishLinkToDirector }
