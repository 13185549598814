/* eslint-disable multiline-ternary */
import React, { ReactElement } from 'react'
import Text from '../text/Text'
import { ChevronRightIcon, PlusIcon } from '@heroicons/react/24/solid'

interface Props {
  className?: string
  title: string
  icon: JSX.Element
  onClick?: () => void
  id?: string
  key?: string
  style?: 'big' | 'small'
  type?: 'next' | 'add'
}

export const IconListButton = ({
  className = '',
  title,
  icon,
  key,
  id,
  onClick,
  style = 'small',
  type = 'next'
}: Props): ReactElement => {
  return (
    <div
      key={key}
      id={id}
      className={`flex flex-row p-xs justify-between items-center bg-backgroundLight rounded-lg cursor-pointer border hover:border-primary ${className}`}
      onClick={onClick}
    >
      <div className="flex flex-row items-center">
        <div
          className={`bg-backgroundPrimary rounded-lg mr-xs ${
            style === 'small' ? 'w-6 p-1' : 'w-10 p-1.5'
          }`}
        >
          <icon.type className="text-primary" />
        </div>
        <Text className={`ml-xs ${style === 'big' ? 'font-semibold' : ''}`}>
          {title}
        </Text>
      </div>
      {type === 'next' ? (
        <ChevronRightIcon className="text-primary h-6 p-0.5" />
      ) : (
        <PlusIcon className="text-primary h-6 p-0.5" />
      )}
    </div>
  )
}
