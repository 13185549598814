import { countries } from '../../../../data/countries'
import React, { ReactElement, useEffect } from 'react'
import {
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  useForm
} from 'react-hook-form'
import { onPromise } from '../../../../util/formHelper'
import DateField from '../../../inputs/DateField'
import CountryDropdownSelected from '../../../inputs/Dropdowns/CountryDropdownSelected'
import TextInput from '../../../inputs/TextInput'
import dayjs from 'dayjs'
import ButtonRow from '../../../button/ButtonRow'

import InputOptionType from '../../../../enums/InputOptionType'
import configStore from '../../../../stores/config.store'
import { Content } from '../../../content/Content'

interface Props {
  companyInfo: any
  save: (personalInfo: any) => void
  isSent?: boolean
  navigateBack: () => void
  additionalFields?: JSX.Element
}

const CompanyInformationForm = ({
  companyInfo,
  save,
  isSent = false,
  navigateBack,
  additionalFields
}: Props): ReactElement => {
  const methods: UseFormReturn<any, any> = useForm<any>({ mode: 'onSubmit' })

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    const { companyInfo } = data

    if (companyInfo && companyInfo.address && companyInfo.address.country) {
      companyInfo.address.country = companyInfo.address.country.value
    }

    if (companyInfo && companyInfo.placeOfIncorperation) {
      companyInfo.placeOfIncorperation = companyInfo.placeOfIncorperation.value
    }

    if (companyInfo && companyInfo.dateOfIncorporation) {
      companyInfo.dateOfIncorporation = dayjs(
        companyInfo.dateOfIncorporation,
        'DD.MM.YYYY'
      ).toDate()
    }
    save(companyInfo)
  }

  useEffect(() => {
    methods.setValue('companyInfo', companyInfo)

    if (companyInfo && companyInfo.address && companyInfo.address.country) {
      for (const country of countries) {
        if (country.value === companyInfo.address.country) {
          methods.setValue('companyInfo.address.country', country)
        }
        if (country.value === companyInfo.placeOfIncorperation) {
          methods.setValue('companyInfo.placeOfIncorperation', country)
        }
      }
    }

    if (companyInfo && companyInfo.dateOfIncorporation) {
      methods.setValue(
        'companyInfo.dateOfIncorporation',
        dayjs(companyInfo.dateOfIncorporation).format('DD.MM.YYYY')
      )
    }
  }, [companyInfo])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onPromise(methods.handleSubmit(onSubmit))}
        className="h-full flex flex-col lg:justify-between"
      >
        <Content className="flex-grow">
          <TextInput
            variable="companyInfo.companyName"
            title="Company Name"
            optionType={InputOptionType.TEXT}
            className="w-full"
            disabled={isSent}
          />

          <TextInput
            variable="companyInfo.legalForm"
            title="Legal Form"
            optionType={InputOptionType.TEXT}
            className="w-full"
            disabled={isSent}
          />
          <div className="mb-4">
            <CountryDropdownSelected
              variable="companyInfo.placeOfIncorperation"
              title="Place of Incorporation"
              options={countries}
              required={true}
              disabled={isSent}
              restricted={configStore.config?.countryRestriction?.residences}
            />
          </div>
          <DateField
            control={methods.control}
            variable="companyInfo.dateOfIncorporation"
            title="Date of Incorporation"
            className="w-full"
            disabled={isSent}
            required
            initialDate={methods.watch('companyInfo.dateOfIncorporation')}
          />
          <TextInput
            variable="companyInfo.registartionNumber"
            title="Registration Number"
            optionType={InputOptionType.TEXT_AND_NUMBER}
            className="w-full"
            disabled={isSent}
          />
          <div className="font-sm font-semibold mb-4 mt-5">
            Registered Address
          </div>
          <TextInput
            variable="companyInfo.address.street"
            title="Street & Nr."
            className="w-full"
            optionType={InputOptionType.TEXT}
            disabled={isSent}
          />
          <div className="grid grid-cols-2 gap-4">
            <TextInput
              variable="companyInfo.address.zip"
              title="Zip"
              optionType={InputOptionType.NUMBER}
              className="w-full"
              disabled={isSent}
            />
            <TextInput
              variable="companyInfo.address.city"
              title="City"
              optionType={InputOptionType.TEXT}
              className="w-full"
              disabled={isSent}
            />
          </div>
          <CountryDropdownSelected
            variable="companyInfo.address.country"
            title="Domicile Country"
            options={countries}
            required={true}
            disabled={isSent}
            restricted={configStore.config?.countryRestriction?.residences}
          />
          {additionalFields && additionalFields}
        </Content>
        <ButtonRow
          secondary={{ onClick: navigateBack }}
          primary={{ title: 'Save', type: 'submit', hide: isSent }}
        />
      </form>
    </FormProvider>
  )
}

export default CompanyInformationForm
