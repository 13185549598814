import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CardScrollable from '../../components/CardScrollable/CardScrollable'
import Finish from '../../components/verification-steps/steps/finish/Finish'
import configStore from '../../stores/config.store'
import userStore from '../../stores/user.store'

const UserFinishPage: React.FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const getStepMySteps = async (): Promise<void> => {
      setIsLoading(true)
      await userStore?.getMe().catch((err) => console.log(err))
      setIsLoading(false)
    }

    getStepMySteps().catch(console.error)
  }, [])

  useEffect(() => {
    const loadConfig = async (): Promise<void> => {
      setIsLoading(true)
      await configStore
        ?.loadConfigFromServer()
        .catch((_err) => console.log(_err))
      setIsLoading(false)
    }
    loadConfig().catch((_err) => console.log(_err))
  }, [])

  if (!configStore.config?.verificationStepsConfig) {
    return <></>
  }

  if (!userStore.user) {
    return <></>
  }

  return (
    <CardScrollable title="Finish Registration" loading={isLoading}>
      <Finish
        verificationStepsConfig={configStore.config.verificationStepsConfig}
        user={userStore.user}
        onSubmit={async (data: any) => {
          const id = userStore.user?.finish?.id
          await userStore
            .finish({
              data: {
                id,
                ...data
              }
            })
            .then(() => {
              navigate('/finished')
            })
        }}
      />
    </CardScrollable>
  )
}

export default observer(UserFinishPage)
