import React, { ReactElement } from 'react'
import ProofOfResidencyDocument from '../../../components/verification-steps/steps/proof-of-residency/ProofOfResidencyDocument'
import directorVerificationStore from '../../../stores/director-verification.store'

const DirectorProofOfResidencyDocument: React.FC = (): ReactElement => {
  return (
    <ProofOfResidencyDocument verificationStore={directorVerificationStore} isDirector/>
  )
}

export default DirectorProofOfResidencyDocument
