import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CardScrollable from '../components/CardScrollable/CardScrollable'
import VerificationProcess from '../components/verification-steps/VerificationProcess'
import configStore from '../stores/config.store'
import userStore from '../stores/user.store'
import { FlowType } from '../dto/User.interface'
import { notifyError, notifySuccess } from '../util/notifications'
import { sendFinishLinkToDirector } from '../api/authAPI'
import { VerificationProcessProps } from '../components/verification-steps/VerificationProcessBar'
import { getActiveVerificationSteps } from '../util/stepHelper'

const VerificationProcessPage: React.FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const getStepMySteps = async (): Promise<void> => {
      setIsLoading(true)
      await userStore?.getMe().catch((err) => console.log(err))
      setIsLoading(false)
    }

    getStepMySteps().catch(console.error)
  }, [])

  useEffect(() => {
    const loadConfig = async (): Promise<void> => {
      setIsLoading(true)
      await configStore
        ?.loadConfigFromServer()
        .catch((_err) => console.log(_err))
      setIsLoading(false)
    }
    loadConfig().catch((_err) => console.log(_err))
  }, [])

  if (!configStore.config?.verificationStepsConfig) {
    return <></>
  }

  if (!userStore.user) {
    return <></>
  }

  const sendFinishLink = (): void => {
    const sendLink = async (): Promise<void> => {
      setIsLoading(true)
      try {
        await sendFinishLinkToDirector()
        notifySuccess('Verification link sent')
      } catch (err) {
        notifyError('Something went wrong')
      }
      setIsLoading(false)
    }

    if (
      userStore.user?.flowType === FlowType.KYB &&
      !userStore.isUserDirector()
    ) {
      sendLink().catch((_err) => console.log(_err))
    }
  }

  const processProps: VerificationProcessProps = {
    user: userStore.user,
    activeSteps: getActiveVerificationSteps(
      configStore.config.verificationStepsConfig
    )
  }

  return (
    <CardScrollable
      title="Verification Process"
      loading={isLoading}
      verificationProcess={processProps}
    >
      <VerificationProcess
        user={userStore.user}
        canFinish={
          userStore.user.flowType === FlowType.KYC || userStore.isUserDirector()
        }
        verificationStepsConfig={configStore.config.verificationStepsConfig}
        navFinish={() => {
          if (
            configStore.config?.verificationStepsConfig?.beneficialOwner
              ?.isEnabled &&
            !userStore.user?.beneficialOwner?.verifiedData
          ) {
            navigate('/finish-beneficial-owner')
          } else {
            navigate('/finish')
          }
        }}
        sendFinishLink={sendFinishLink}
      />
    </CardScrollable>
  )
}

export default observer(VerificationProcessPage)
