/* eslint-disable multiline-ternary */
import React, { ReactElement } from 'react'
import H4 from '../Header/H4'
import { CheckIcon } from '@heroicons/react/24/solid'
import { UploadRequirement } from '../../dto/UploadRequirement.interface'
import H5 from '../Header/H5'
import SmallText from '../text/SmallText'

interface Props {
  title?: string
  informationList: string[] | UploadRequirement[]
  className?: string
}

const InformationList = ({
  title,
  informationList,
  className
}: Props): ReactElement => {
  return (
    <div className={className}>
      {title && <H4 className="mb-xs">{title}</H4>}
      <div>
        {informationList.map((info, key) => {
          return (
            <div key={key} className="flex flex-row items-center mb-xs">
              <div className="bg-backgroundPrimary mr-sm h-6 w-6 rounded p-1">
                <CheckIcon className="text-primary" />
              </div>
              {typeof info === 'string' ? (
                <SmallText>{info}</SmallText>
              ) : (
                <div>
                  <H5>{info.title}</H5>
                  <SmallText>{info.subTitle}</SmallText>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default InformationList
