import React, { ReactElement } from 'react'
import Button from './Button'

export interface ButtonProps {
  onClick?: () => void
  title?: string
  hide?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

interface Props {
  secondary?: ButtonProps
  primary?: ButtonProps
}

const ButtonRow = ({ secondary, primary }: Props): ReactElement => {
  const hasSec = secondary && !secondary.hide && secondary.onClick
  const hasPrim =
    primary && !primary.hide && (primary.onClick ?? primary.type === 'submit')

  if (!hasPrim && !hasSec) return <></>

  return (
    <div className="flex flex-row w-full border-t justify-end px-l md:px-xl py-sm md:py-md bg-backgroundLight">
      {hasSec && (
        <Button
          type={secondary.type ? secondary.type : 'button'}
          style="secondary"
          title={secondary.title ? secondary.title : 'Back'}
          onClick={secondary.onClick}
          disabled={secondary.disabled}
          className='mr-sm'
        />
      )}
      {hasPrim && (
        <Button
          type={primary.type ? primary.type : 'button'}
          style="primary"
          title={primary.title ? primary.title : 'Next'}
          disabled={primary.disabled}
          onClick={primary.onClick}
        />
      )}
    </div>
  )
}

export default ButtonRow
