import { BuildingOffice2Icon } from '@heroicons/react/24/solid'
import React, { ReactElement } from 'react'
import H5 from '../../../../components/Header/H5'

interface Props {
  name: string
}

const CompanyNode = ({ name }: Props): ReactElement => {
  return (
    <div className="flex justify-center">
      <div className="rounded-md p-sm bg-black text-white flex flex-row justify-center items-center border">
        <BuildingOffice2Icon className="w-8 h-8 " />
        {name && <H5 className="font-semibold ml-sm text-xs">{name}</H5>}
      </div>
    </div>
  )
}

export default CompanyNode
