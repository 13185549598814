import React, { ReactElement } from 'react'
import VerificationStepType from '../../enums/VerificationStepType'
import { VerificationStepStatus } from '../../enums/VerificationStepStatus'

export interface VerificationProcessProps {
  activeSteps: VerificationStepType[]
  user?: any
}

const VerificationProcessBar = ({
  activeSteps,
  user
}: VerificationProcessProps): ReactElement => {
  const length = activeSteps.length
  if (length <= 0) return <></>

  let completedSteps = 0

  if (user) {
    activeSteps.forEach((step) => {
      const userStep = user[step]
      if (
        userStep &&
        userStep.status &&
        userStep.status !== VerificationStepStatus.NOT_STARTED &&
        userStep.status !== VerificationStepStatus.STARTED &&
        userStep.status !== VerificationStepStatus.REDO
      ) {
        completedSteps++
      }
    })
  }

  return (
    <div className="px-l md:px-xl flex flex-row w-full items-center">
      <div className="rounded-full bg-primary px-sm py-0.5 text-white font-semibold">{`${completedSteps} / ${length}`}</div>
      <div className="ml-md h-2 flex-1 rounded-full relative">
        <div>
          <div className="h-full w-full absolute rounded-full bg-primary opacity-20"></div>
          <div
            className="h-full absolute rounded-full bg-primary"
            style={{ width: `${(completedSteps / length) * 100}%` }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default VerificationProcessBar
