import { VerificationStepStatus } from '../enums/VerificationStepStatus'

enum FlowType {
  KYB_USER = 'KYB_USER',
  KYB_COMPANY = 'KYB_COMPANY',
  KYC = 'KYC',
  KYB = 'KYB',
  KYB_DIRECTOR = 'KYB_DIRECTOR'
}

interface User {
  id?: string
  email?: string
  isTermsAndConditionsAccepted?: boolean
  verifiedData?: boolean
  flowType: FlowType
  personalInfo?: any
  proofOfResidency?: any
  questionnaire?: any
  idAndLiveness?: any
  wallet?: any
  finish?: any
  companyInfo?: any
  directorApproval?: any
  legalDocuments?: any
  status?: VerificationStepStatus
  beneficialOwner?: any
}

export { FlowType }

export default User
