import React, { ReactElement } from 'react'
import FilePreview from '../../../../../filepreview/FilePreview'

interface Props {
  file?: string
  header?: string
}

const UserCompletedFile = ({ file, header = '' }: Props): ReactElement => {
  if (!file) return <></>

  return (
    <div>
      <h4 className="font-semibold text-gray-900 mt-3">{header}</h4>
      <FilePreview file={file} requirementsIn={[]} />
    </div>
  )
}

export default UserCompletedFile
