import React, { ReactElement } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { intendedUseOfAssets } from '../../../../../data/intendedUseOfAssets'
import { investmentAmounts } from '../../../../../data/investmentAmounts'
import VerificationStepEntity from '../../../../../dto/VerificationStepEntity.interface'
import DropdownSelected from '../../../../inputs/Dropdowns/DropdownSelected'
import TextInput from '../../../../inputs/TextInput'
import InputOptionType from '../../../../../enums/InputOptionType'

interface Props {
  disabled?: boolean
  methods: UseFormReturn<VerificationStepEntity, any>
}

const InvestmentQuestions = ({ methods, disabled }: Props): ReactElement => {
  return (
    <div className="h-full flex flex-col justify-between">
      <div className="flex-grow">
        <DropdownSelected
          variable="questionnaire.intendedInvestmentAmount"
          title="Intended Investment Amount (USD)"
          options={investmentAmounts}
          required={true}
          isSearchable={false}
          disabled={disabled}
        />
        <DropdownSelected
          disabled={disabled}
          variable="questionnaire.intendedUseOfAssets"
          options={intendedUseOfAssets}
          title="Intended Use of Assets"
          required={true}
        />

        {methods.watch('questionnaire.intendedUseOfAssets') &&
          (methods.watch('questionnaire.intendedUseOfAssets') as any).label ===
            'Other (please describe)' && (
            <TextInput
              variable="questionnaire.intendedUseOfAssetsOther"
              title="Other use of Assets"
              optionType={InputOptionType.TEXT}
              className="w-full"
              disabled={disabled}
            />
          )}
      </div>
    </div>
  )
}

export default InvestmentQuestions
