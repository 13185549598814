import React, { ReactElement } from 'react'
import complieLogo from '../../assets/complie_logo.svg'

const Footer = (): ReactElement => {
  return (
    <div className="w-full flex flex-row justify-center items-center mt-6 text-xs md:text-sm">
      <span className='mr-2'>Compliance powered by</span>
      <img src={complieLogo} className='mr-1'/>
      <span className='font-semibold'>complie</span>
    </div>
  )
}

export default Footer
