import React, { ReactElement } from 'react'

import { countries } from '../../../../../../data/countries'
import * as Flags from 'country-flag-icons/react/3x2'
import UserCompletedItem from './Item'

const countryCodeToName = (name: string): string => {
  if (!name) return ''
  const country = countries.find((country) => country.value === name)

  if (!country) {
    return name
  }

  return country.label
}

interface Props {
  country?: string
  header?: string
  newLine?: boolean
}

const UserCompletedCountry = ({
  country,
  header = '',
  newLine
}: Props): ReactElement => {
  if (!country) {
    return <></>
  }

  const Flag: any = (Flags as any)[country]

  return (
    <UserCompletedItem header={header} newLine={newLine}>
      <div className="flex items-center">
        <Flag className="w-4 h-4 mr-2" />
        {countryCodeToName(country)}
      </div>
    </UserCompletedItem>
  )
}

export default UserCompletedCountry
