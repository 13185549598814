import React, { ReactElement } from 'react'
import { Node } from '../dto/Organigram'
import Button from '../../../../components/button/Button'
import kybStore from '../../../../stores/kyb.store'
import { TrashIcon } from '@heroicons/react/24/outline'

interface Props {
  node?: Node
  canComplete?: boolean
  isSent?: boolean
  close: () => void
  type: 'company' | 'person'
}

const CompleteNodeBtns = ({
  node,
  canComplete = false,
  isSent = false,
  type,
  close
}: Props): ReactElement => {
  if (!node?.id) {
    return <></>
  }
  return (
    <div className="flex flex-col justify-between flex-grow items-center">
      <div className="flex flex-row justify-end w-full">
        {!isSent && (
          <Button
            title="Complete"
            onClick={() => {
              if (node.id) {
                kybStore.verifyNode(node.id).catch((err) => console.log(err))
                close()
              }
            }}
            style="primary"
            disabled={!canComplete}
            className="w-2/3 sm:w-1/3"
          />
        )}
      </div>

      <button
        className="text-error underline h-8 px-2 flex items-center"
        onClick={() => {
          if (node.id) {
            kybStore.deleteNode(node.id).catch((err) => console.log(err))
            close()
          }
        }}
      >
        <TrashIcon className="w-5 mr-xs" />
        <span>
          Delete {type === 'company' ? 'Corporate' : 'Natural Person'}
        </span>
      </button>
    </div>
  )
}

export default CompleteNodeBtns
