import React, { ReactElement } from 'react'
import { components } from 'react-select'
import * as Flags from 'country-flag-icons/react/3x2'

const CustomCountryControl = (props: any): ReactElement => {
  const values = props.getValue()
  const data = values[0]
  if (!data) {
    return (
      <components.Control
        {...props}
        className="flex flex-row items-center text"
      >
        {props.children}
      </components.Control>
    )
  }

  const Flag: any = (Flags as any)[data.value]
  return (
    <components.Control {...props}>
      <div className="flex flex-row items-center text ml-2">
        <Flag className="w-4 h-4" />
      </div>
      {props.children}
    </components.Control>
  )
}

export default CustomCountryControl
