import React, { ReactElement } from 'react'
import { ReviewUserStepProps } from '../ReviewUserData'
import UserCompletedFile from '../components/File'
import UserCompletedSectionHeader from '../components/SectionHeader'

const ReviewLegalDocuments = ({
  user,
  parentUrl,
  readonly = false
}: ReviewUserStepProps): ReactElement => {
  if (!user?.legalDocuments) return <></>
  const { legalDocuments } = user

  return (
    <>
      <UserCompletedSectionHeader
        title="Legal Documents"
        path={`${parentUrl}/legal-documents`}
        readonly={readonly}
      />
      <UserCompletedFile
        header="Certificate Of Incorporation"
        file={legalDocuments.certificateOfIncorporation}
      />
      <UserCompletedFile
        header="Register Of Directors"
        file={legalDocuments.registerOfDirectors}
      />
      <UserCompletedFile
        header="Register Of Members"
        file={legalDocuments.registerOfMembers}
      />
      <UserCompletedFile
        header="Proof Of Address"
        file={legalDocuments.proofOfAddress}
      />
    </>
  )
}

export default ReviewLegalDocuments
