/* eslint-disable multiline-ternary */
import React, { ReactElement, useEffect, useState } from 'react'
import {
  defineComponents,
  DocumentReaderService,
  DocumentReaderWebComponent
} from '@regulaforensics/vp-frontend-document-components'
import { useNavigate } from 'react-router-dom'
import LivenessDocument from '../../../../../dto/LivenessDocument.interface'
import configStore from '../../../../../stores/config.store'
import Loader from '../../../../loader/Loader'
import { VerificationStore } from '../../../../../stores/verificaton.store'
import { checkRedoNavigate } from '../../../../../util/navigateHelper'
import CardScrollable from '../../../../CardScrollable/CardScrollable'
import ButtonRow from '../../../../button/ButtonRow'
import { Content } from '../../../../content/Content'
import FilePreview from '../../../../filepreview/FilePreview'
import { getSortedRegulaImages } from '../../../../../util/idAndLivenessHelper'

interface Props {
  verificationStore: VerificationStore
  uploadEnabled?: boolean
  enableCard?: boolean
  close: () => void
  navSelected?: () => void
  customBack?: () => void
  customSave?: (documents: LivenessDocument[]) => void
  initDocuments?: LivenessDocument[]
}

const DocumentCheck = ({
  verificationStore,
  enableCard,
  close,
  customBack,
  customSave,
  navSelected,
  uploadEnabled = false,
  initDocuments = []
}: Props): ReactElement => {
  const component = React.useRef<DocumentReaderWebComponent>(null)
  const [documents, setDocuments] = useState<LivenessDocument[]>(initDocuments)
  const [hasChanged, setHasChanged] = useState(false)
  const [livenessDocumentPreview, setLivenessDocumentPreview] = useState<
    LivenessDocument | undefined
  >(undefined)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (documents.length > 0) {
      const sortedDocuments = getSortedRegulaImages(documents);

      if (sortedDocuments.length > 0) {
        setLivenessDocumentPreview(sortedDocuments[0])
      } else {
        for (const livenessDocument of documents) {
          if (livenessDocument.fieldName === 'Document front side') {
            setLivenessDocumentPreview(livenessDocument)
            return
          }
        }
      }
    } else {
      setLivenessDocumentPreview(undefined)
    }
  }, [documents])

  const memoizedListener = React.useCallback((event: any) => {
    if (event.detail.action === 'CLOSE') {
      close()
    }

    if (
      event.detail.action === 'PROCESS_FINISHED' &&
      event.detail.data.status === 1
    ) {
      const response = event.detail.data.response

      const livenessDocuments: LivenessDocument[] = []
      for (const field of response.images.fieldList) {
        for (const value of field.valueList) {
          livenessDocuments.push({
            fieldName: field.fieldName,
            ...value
          })
        }
      }

      setHasChanged(true)
      setDocuments(livenessDocuments)
    }
  }, [])

  useEffect(() => {
    const componentCurrent = component.current

    window.RegulaDocumentSDK = new DocumentReaderService()

    defineComponents()
      .then(async () => {
        await window.RegulaDocumentSDK.prepare()
      })
      .catch((err) => console.log(err))

    if (componentCurrent) {
      componentCurrent.addEventListener('document-reader', memoizedListener)
    }

    return () => {
      if (componentCurrent) {
        componentCurrent.removeEventListener(
          'document-reader',
          memoizedListener
        )
      }
    }
  }, [])

  const saveDocuments = async (docs: LivenessDocument[]): Promise<void> => {
    setLoading(true)
    await verificationStore
      .saveIdAndLivenessInformation({
        documentImages: docs
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const renderDocumentCheck = (): ReactElement => {
    return (
      <document-reader
        ref={component}
        license={process.env.REACT_APP_DOCUMENT_LICENSE}
        locale="en"
        style={{ maxWidth: '100%' }}
        internal-scenario="MrzAndLocate"
        start-screen={uploadEnabled}
        multipage-processing={true}
        multiple-file-input={uploadEnabled}
      />
    )
  }

  const renderPreview = (image: string): ReactElement => {
    return (
      <Content>
        <FilePreview
          file={hasChanged ? `data:image/png;base64,${image}` : image}
          onDelete={() => {
            saveDocuments([]).catch((err) => console.log(err))
            if (navSelected) {
              navSelected()
            }
          }}
          withoutBackground
          withoutPadding
        />
      </Content>
    )
  }

  const renderContent = (): ReactElement => {
    return (
      <div className="flex flex-col flex-grow">
        {livenessDocumentPreview ? (
          renderPreview(livenessDocumentPreview.value)
        ) : !enableCard ? (
          <Content>{renderDocumentCheck()}</Content>
        ) : (
          renderDocumentCheck()
        )}
        <ButtonRow
          secondary={{
            onClick: () => {
              if ((!initDocuments || hasChanged) && navSelected) {
                navSelected()
              } else if (customBack) {
                customBack()
              } else {
                checkRedoNavigate(navigate, -1)
              }
            }
          }}
          primary={{
            title: 'Continue',
            disabled: !livenessDocumentPreview,
            onClick: () => {
              if (hasChanged) {
                if (customSave) {
                  customSave(documents)
                  close()
                } else {
                  saveDocuments(documents)
                    .then(() => {
                      close()
                    })
                    .catch((err) => console.log(err))
                }
              } else {
                close()
              }
            }
          }}
        />
      </div>
    )
  }

  if (enableCard) {
    return (
      <CardScrollable title="ID & Liveness Verification" loading={loading}>
        {renderContent()}
      </CardScrollable>
    )
  }
  if (loading) {
    return (
      <div className="w-full h-full flex flex-col justify-around items-center">
        <Loader color={configStore?.config?.primaryColor} />
      </div>
    )
  }

  return renderContent()
}

export default DocumentCheck
