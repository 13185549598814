import React, { ReactElement } from 'react'
import dayjs from 'dayjs'
import UserCompletedAddress from '../components/Address'
import UserCompletedItem from '../components/Item'
import UserCompletedList from '../components/List'
import UserCompletedSectionHeader from '../components/SectionHeader'
import { ReviewUserStepProps } from '../ReviewUserData'

const ReviewCompanyInfo = ({
  user,
  parentUrl,
  readonly = false
}: ReviewUserStepProps): ReactElement => {
  if (!user?.companyInfo) return <></>

  const { companyInfo } = user

  const { address } = companyInfo

  return (
    <>
      <UserCompletedSectionHeader
        title="Company Information"
        path={`${parentUrl}/company-info`}
        readonly={readonly}
      />
      <UserCompletedList>
        <UserCompletedItem header="Company Name">
          {companyInfo?.companyName}
        </UserCompletedItem>
        <UserCompletedItem header="Legal Form">
          {companyInfo?.legalForm}
        </UserCompletedItem>
        <UserCompletedItem header="Place of Incorporation">
          {companyInfo?.placeOfIncorperation}
        </UserCompletedItem>
        <UserCompletedItem header="Date of Incorporation">
          {dayjs(companyInfo?.dateOfIncorporation).format('DD.MM.YYYY')}
        </UserCompletedItem>
        <UserCompletedItem header="Registration Number">
          {companyInfo?.registartionNumber}
        </UserCompletedItem>
      </UserCompletedList>
      <UserCompletedAddress address={address} />
    </>
  )
}

export default ReviewCompanyInfo
