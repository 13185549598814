export const occupation = [
  {
    value: '3D Artist',
    label: '3D Artist'
  },
  {
    value: 'Account Manager',
    label: 'Account Manager'
  },
  {
    value: 'Accountant',
    label: 'Accountant'
  },
  {
    value: 'Actress',
    label: 'Actress'
  },
  {
    value: 'Actuarial Statistician',
    label: 'Actuarial Statistician'
  },
  {
    value: 'Actuary',
    label: 'Actuary'
  },
  {
    value: 'Addiction Counsellor',
    label: 'Addiction Counsellor'
  },
  {
    value: 'Administrative Assistant',
    label: 'Administrative Assistant'
  },
  {
    value: 'Adult Educator',
    label: 'Adult Educator'
  },
  {
    value: 'Aerobics Trainer',
    label: 'Aerobics Trainer'
  },
  {
    value: 'Agricultural Economist',
    label: 'Agricultural Economist'
  },
  {
    value: 'Agricultural Machinery Technician',
    label: 'Agricultural Machinery Technician'
  },
  {
    value: 'Agricultural Manager',
    label: 'Agricultural Manager'
  },
  {
    value: 'Agricultural Technician',
    label: 'Agricultural Technician'
  },
  {
    value: 'Air Conditioning Technician / Heating Technician',
    label: 'Air Conditioning Technician / Heating Technician'
  },
  {
    value: 'Air Traffic Controller',
    label: 'Air Traffic Controller'
  },
  {
    value: 'Aircraft Manufacturer',
    label: 'Aircraft Manufacturer'
  },
  {
    value: 'Aircraft Technician',
    label: 'Aircraft Technician'
  },
  {
    value: 'Airport Staff',
    label: 'Airport Staff'
  },
  {
    value: 'Animal Keeper',
    label: 'Animal Keeper'
  },
  {
    value: 'Antiquarian',
    label: 'Antiquarian'
  },
  {
    value: 'Antique and Art Dealer',
    label: 'Antique and Art Dealer'
  },
  {
    value: 'App Developer',
    label: 'App Developer'
  },
  {
    value: 'Arborist',
    label: 'Arborist'
  },
  {
    value: 'Archaeologist',
    label: 'Archaeologist'
  },
  {
    value: 'Architect',
    label: 'Architect'
  },
  {
    value: 'Archivist',
    label: 'Archivist'
  },
  {
    value: 'Arms and Ammunition Dealer',
    label: 'Arms and Ammunition Dealer'
  },
  {
    value: 'Art Buyer',
    label: 'Art Buyer'
  },
  {
    value: 'Art Consultant',
    label: 'Art Consultant'
  },
  {
    value: 'Art Critic',
    label: 'Art Critic'
  },
  {
    value: 'Art Director',
    label: 'Art Director'
  },
  {
    value: 'Art Historian',
    label: 'Art Historian'
  },
  {
    value: 'Artist',
    label: 'Artist'
  },
  {
    value: 'Artist Agent',
    label: 'Artist Agent'
  },
  {
    value: 'Assistant',
    label: 'Assistant'
  },
  {
    value: 'Assistant Director',
    label: 'Assistant Director'
  },
  {
    value: 'Astrobiologist',
    label: 'Astrobiologist'
  },
  {
    value: 'Astrologer',
    label: 'Astrologer'
  },
  {
    value: 'Astronaut',
    label: 'Astronaut'
  },
  {
    value: 'Astronomer',
    label: 'Astronomer'
  },
  {
    value: 'Astrophysicist',
    label: 'Astrophysicist'
  },
  {
    value: 'Athlete',
    label: 'Athlete'
  },
  {
    value: 'Atomic Physicist',
    label: 'Atomic Physicist'
  },
  {
    value: 'Auctioneer',
    label: 'Auctioneer'
  },
  {
    value: 'Auditor',
    label: 'Auditor'
  },
  {
    value: 'Automation Technician',
    label: 'Automation Technician'
  },
  {
    value: 'Automotive Engineer',
    label: 'Automotive Engineer'
  },
  {
    value: 'Bailiff',
    label: 'Bailiff'
  },
  {
    value: 'Baker',
    label: 'Baker'
  },
  {
    value: 'Ballet Dancer',
    label: 'Ballet Dancer'
  },
  {
    value: 'Ballistics Specialist',
    label: 'Ballistics Specialist'
  },
  {
    value: 'Bank Employee',
    label: 'Bank Employee'
  },
  {
    value: 'Barista',
    label: 'Barista'
  },
  {
    value: 'Bartender/Barmaid',
    label: 'Bartender/Barmaid'
  },
  {
    value: 'Beautician',
    label: 'Beautician'
  },
  {
    value: 'Beverage Manufacturer',
    label: 'Beverage Manufacturer'
  },
  {
    value: 'Bicycle Mechanic',
    label: 'Bicycle Mechanic'
  },
  {
    value: 'Bicycle Messenger',
    label: 'Bicycle Messenger'
  },
  {
    value: 'Biochemist',
    label: 'Biochemist'
  },
  {
    value: 'Biologist',
    label: 'Biologist'
  },
  {
    value: 'Biomedical Engineer',
    label: 'Biomedical Engineer'
  },
  {
    value: 'Biotechnologist',
    label: 'Biotechnologist'
  },
  {
    value: 'Blasting Authorised Persons',
    label: 'Blasting Authorised Persons'
  },
  {
    value: 'Blogger',
    label: 'Blogger'
  },
  {
    value: 'Boat Builder',
    label: 'Boat Builder'
  },
  {
    value: 'Body Construction Technician',
    label: 'Body Construction Technician'
  },
  {
    value: 'Bodyguard',
    label: 'Bodyguard'
  },
  {
    value: 'Bookbinder',
    label: 'Bookbinder'
  },
  {
    value: 'Booker',
    label: 'Booker'
  },
  {
    value: 'Bookmaker',
    label: 'Bookmaker'
  },
  {
    value: 'Bookseller',
    label: 'Bookseller'
  },
  {
    value: 'Botanist',
    label: 'Botanist'
  },
  {
    value: 'Branch Manager',
    label: 'Branch Manager'
  },
  {
    value: 'Brand Manager',
    label: 'Brand Manager'
  },
  {
    value: 'Builder',
    label: 'Builder'
  },
  {
    value: 'Building Ecologist',
    label: 'Building Ecologist'
  },
  {
    value: 'Business Coach',
    label: 'Business Coach'
  },
  {
    value: 'Business Economist',
    label: 'Business Economist'
  },
  {
    value: 'Business Educator',
    label: 'Business Educator'
  },
  {
    value: 'Business Informatics Specialist',
    label: 'Business Informatics Specialist'
  },
  {
    value: 'Business Mathematician (Operations Research)',
    label: 'Business Mathematician (Operations Research)'
  },
  {
    value: 'Butcher',
    label: 'Butcher'
  },
  {
    value: 'Butler',
    label: 'Butler'
  },
  {
    value: 'Cab Driver',
    label: 'Cab Driver'
  },
  {
    value: 'Cabaret Artist',
    label: 'Cabaret Artist'
  },
  {
    value: 'CAD Technician',
    label: 'CAD Technician'
  },
  {
    value: 'Call Center Agent',
    label: 'Call Center Agent'
  },
  {
    value: 'Car Mechanic',
    label: 'Car Mechanic'
  },
  {
    value: 'Car Salesman',
    label: 'Car Salesman'
  },
  {
    value: 'Caricaturist',
    label: 'Caricaturist'
  },
  {
    value: 'Carpenter',
    label: 'Carpenter'
  },
  {
    value: 'Carrier',
    label: 'Carrier'
  },
  {
    value: 'Cashier',
    label: 'Cashier'
  },
  {
    value: 'Casting Director',
    label: 'Casting Director'
  },
  {
    value: 'Catering Specialist',
    label: 'Catering Specialist'
  },
  {
    value: 'Cattle Dealer',
    label: 'Cattle Dealer'
  },
  {
    value: 'Cave Guide',
    label: 'Cave Guide'
  },
  {
    value: 'Celtologist',
    label: 'Celtologist'
  },
  {
    value: 'Ceramist',
    label: 'Ceramist'
  },
  {
    value: 'Chairman',
    label: 'Chairman'
  },
  {
    value: 'Chauffeur',
    label: 'Chauffeur'
  },
  {
    value: 'Chef',
    label: 'Chef'
  },
  {
    value: 'Chemical Computer Scientist',
    label: 'Chemical Computer Scientist'
  },
  {
    value: 'Chemical Laboratory Technician',
    label: 'Chemical Laboratory Technician'
  },
  {
    value: 'Chemical Technician',
    label: 'Chemical Technician'
  },
  {
    value: 'Chemist',
    label: 'Chemist'
  },
  {
    value: 'Chief Executive Officer (CEO)',
    label: 'Chief Executive Officer (CEO)'
  },
  {
    value: 'Chief Financial Officer (CFO)',
    label: 'Chief Financial Officer (CFO)'
  },
  {
    value: 'Chief Operating Officer (COO)',
    label: 'Chief Operating Officer (COO)'
  },
  {
    value: 'Child and Youth Counsellor',
    label: 'Child and Youth Counsellor'
  },
  {
    value: 'Childminder',
    label: 'Childminder'
  },
  {
    value: 'Chip Designer',
    label: 'Chip Designer'
  },
  {
    value: 'Choreographer',
    label: 'Choreographer'
  },
  {
    value: 'Chorister',
    label: 'Chorister'
  },
  {
    value: 'Cinematographer',
    label: 'Cinematographer'
  },
  {
    value: 'Civil Engineer',
    label: 'Civil Engineer'
  },
  {
    value: 'Civil Engineer',
    label: 'Civil Engineer'
  },
  {
    value: 'Civil Engineering Technician',
    label: 'Civil Engineering Technician'
  },
  {
    value: 'Cleaning Specialist',
    label: 'Cleaning Specialist'
  },
  {
    value: 'Clerk',
    label: 'Clerk'
  },
  {
    value: 'Cloud Architect',
    label: 'Cloud Architect'
  },
  {
    value: 'Cloud Engineer',
    label: 'Cloud Engineer'
  },
  {
    value: 'Cloud Software Engineer',
    label: 'Cloud Software Engineer'
  },
  {
    value: 'Cloud Systems Engineer',
    label: 'Cloud Systems Engineer'
  },
  {
    value: 'Coach',
    label: 'Coach'
  },
  {
    value: 'Coach (amateur sports)',
    label: 'Coach (amateur sports)'
  },
  {
    value: 'Coach (professional sports)',
    label: 'Coach (professional sports)'
  },
  {
    value: 'Coffee Roaster',
    label: 'Coffee Roaster'
  },
  {
    value: 'Coin Dealer',
    label: 'Coin Dealer'
  },
  {
    value: 'Columnist',
    label: 'Columnist'
  },
  {
    value: 'Commercial Agent',
    label: 'Commercial Agent'
  },
  {
    value: 'Commercial Scholar',
    label: 'Commercial Scholar'
  },
  {
    value: 'Communication Scientist',
    label: 'Communication Scientist'
  },
  {
    value: 'Communication Technician',
    label: 'Communication Technician'
  },
  {
    value: 'Communications Engineer',
    label: 'Communications Engineer'
  },
  {
    value: 'Communications Manager',
    label: 'Communications Manager'
  },
  {
    value: 'Community Educator',
    label: 'Community Educator'
  },
  {
    value: 'Community Manager',
    label: 'Community Manager'
  },
  {
    value: 'Competitive Athlete',
    label: 'Competitive Athlete'
  },
  {
    value: 'Compliance Officer',
    label: 'Compliance Officer'
  },
  {
    value: 'Composer',
    label: 'Composer'
  },
  {
    value: 'Composite Engineer',
    label: 'Composite Engineer'
  },
  {
    value: 'Compositing Artist',
    label: 'Compositing Artist'
  },
  {
    value: 'Computer Game Programmer',
    label: 'Computer Game Programmer'
  },
  {
    value: 'Computer Scientist',
    label: 'Computer Scientist'
  },
  {
    value: 'Computer Technician',
    label: 'Computer Technician'
  },
  {
    value: 'Concierge',
    label: 'Concierge'
  },
  {
    value: 'Conductor',
    label: 'Conductor'
  },
  {
    value: 'Conference Manager',
    label: 'Conference Manager'
  },
  {
    value: 'Construction Machine Operator',
    label: 'Construction Machine Operator'
  },
  {
    value: 'Construction Technician',
    label: 'Construction Technician'
  },
  {
    value: 'Construction Wworker',
    label: 'Construction Wworker'
  },
  {
    value: 'Consultant',
    label: 'Consultant'
  },
  {
    value: 'Consumer Advisor',
    label: 'Consumer Advisor'
  },
  {
    value: 'Content Manager',
    label: 'Content Manager'
  },
  {
    value: 'Controller',
    label: 'Controller'
  },
  {
    value: 'Co-Pilot',
    label: 'Co-Pilot'
  },
  {
    value: 'Copywriter',
    label: 'Copywriter'
  },
  {
    value: 'Coroner',
    label: 'Coroner'
  },
  {
    value: 'Correspondent',
    label: 'Correspondent'
  },
  {
    value: 'Cost Engineer',
    label: 'Cost Engineer'
  },
  {
    value: 'Costume Designer',
    label: 'Costume Designer'
  },
  {
    value: 'Court Interpreter',
    label: 'Court Interpreter'
  },
  {
    value: 'Craftsman',
    label: 'Craftsman'
  },
  {
    value: 'Crane Operator',
    label: 'Crane Operator'
  },
  {
    value: 'Creative Director',
    label: 'Creative Director'
  },
  {
    value: 'Credit Examiner',
    label: 'Credit Examiner'
  },
  {
    value: 'Criminal Analyst',
    label: 'Criminal Analyst'
  },
  {
    value: 'Criminologist',
    label: 'Criminologist'
  },
  {
    value: 'Crop Protection Consultant',
    label: 'Crop Protection Consultant'
  },
  {
    value: 'Croupier',
    label: 'Croupier'
  },
  {
    value: 'Cultural Manager',
    label: 'Cultural Manager'
  },
  {
    value: 'Cultural Scientist',
    label: 'Cultural Scientist'
  },
  {
    value: 'Cultural Technician',
    label: 'Cultural Technician'
  },
  {
    value: 'Curator (Art)',
    label: 'Curator (Art)'
  },
  {
    value: 'Customer Relation Manager',
    label: 'Customer Relation Manager'
  },
  {
    value: 'Customs Guard',
    label: 'Customs Guard'
  },
  {
    value: 'Customs Investigator',
    label: 'Customs Investigator'
  },
  {
    value: 'Cutlerist',
    label: 'Cutlerist'
  },
  {
    value: 'Cyberneticist',
    label: 'Cyberneticist'
  },
  {
    value: 'Dance Teacher',
    label: 'Dance Teacher'
  },
  {
    value: 'Dancer',
    label: 'Dancer'
  },
  {
    value: 'Data Analyst',
    label: 'Data Analyst'
  },
  {
    value: 'Data Scientist',
    label: 'Data Scientist'
  },
  {
    value: 'Data Security Advisor',
    label: 'Data Security Advisor'
  },
  {
    value: 'Database Administrator',
    label: 'Database Administrator'
  },
  {
    value: 'Database Developer',
    label: 'Database Developer'
  },
  {
    value: 'Database Professional',
    label: 'Database Professional'
  },
  {
    value: 'Dating Agent',
    label: 'Dating Agent'
  },
  {
    value: 'Debt Counsellor',
    label: 'Debt Counsellor'
  },
  {
    value: 'Decorator',
    label: 'Decorator'
  },
  {
    value: 'Decorator',
    label: 'Decorator'
  },
  {
    value: 'Department Store Detective',
    label: 'Department Store Detective'
  },
  {
    value: 'Design Engineer',
    label: 'Design Engineer'
  },
  {
    value: 'Design Technician',
    label: 'Design Technician'
  },
  {
    value: 'Detective',
    label: 'Detective'
  },
  {
    value: 'Development Economist',
    label: 'Development Economist'
  },
  {
    value: 'Development Worker',
    label: 'Development Worker'
  },
  {
    value: 'Digital Marketing Specialist',
    label: 'Digital Marketing Specialist'
  },
  {
    value: 'Diplomat',
    label: 'Diplomat'
  },
  {
    value: 'Direct Marketing Manager',
    label: 'Direct Marketing Manager'
  },
  {
    value: 'Director',
    label: 'Director'
  },
  {
    value: 'Disaster Manager',
    label: 'Disaster Manager'
  },
  {
    value: 'Dispatcher',
    label: 'Dispatcher'
  },
  {
    value: 'Disposal and Recycling Specialist',
    label: 'Disposal and Recycling Specialist'
  },
  {
    value: 'Distiller',
    label: 'Distiller'
  },
  {
    value: 'Diver',
    label: 'Diver'
  },
  {
    value: 'Division Manager',
    label: 'Division Manager'
  },
  {
    value: 'DJ (Disc Jockey)',
    label: 'DJ (Disc Jockey)'
  },
  {
    value: 'Documentary',
    label: 'Documentary'
  },
  {
    value: 'Dog Handler',
    label: 'Dog Handler'
  },
  {
    value: 'Draughtsman',
    label: 'Draughtsman'
  },
  {
    value: 'Drill Operator',
    label: 'Drill Operator'
  },
  {
    value: 'Driver',
    label: 'Driver'
  },
  {
    value: 'Driving Instructor',
    label: 'Driving Instructor'
  },
  {
    value: 'Ecologist',
    label: 'Ecologist'
  },
  {
    value: 'Ecommerce Manager',
    label: 'Ecommerce Manager'
  },
  {
    value: 'Economic Geographer',
    label: 'Economic Geographer'
  },
  {
    value: 'Economic Researcher',
    label: 'Economic Researcher'
  },
  {
    value: 'Economist',
    label: 'Economist'
  },
  {
    value: 'Ecosystem Scientist',
    label: 'Ecosystem Scientist'
  },
  {
    value: 'Editor',
    label: 'Editor'
  },
  {
    value: 'Education and Career Counsellor',
    label: 'Education and Career Counsellor'
  },
  {
    value: 'E-Gamer',
    label: 'E-Gamer'
  },
  {
    value: 'E-Learning Author',
    label: 'E-Learning Author'
  },
  {
    value: 'Electrical Engineer',
    label: 'Electrical Engineer'
  },
  {
    value: 'Electromedical Technician',
    label: 'Electromedical Technician'
  },
  {
    value: 'Electronics Technician',
    label: 'Electronics Technician'
  },
  {
    value: 'Encryption Technician (Cryptographer)',
    label: 'Encryption Technician (Cryptographer)'
  },
  {
    value: 'Energetic',
    label: 'Energetic'
  },
  {
    value: 'Energy Consultant',
    label: 'Energy Consultant'
  },
  {
    value: 'Energy Technician',
    label: 'Energy Technician'
  },
  {
    value: 'Engineer',
    label: 'Engineer'
  },
  {
    value: 'Entertainer',
    label: 'Entertainer'
  },
  {
    value: 'Entrepreneur',
    label: 'Entrepreneur'
  },
  {
    value: 'Environmental and Sustainability Manager',
    label: 'Environmental and Sustainability Manager'
  },
  {
    value: 'Environmental Consultant',
    label: 'Environmental Consultant'
  },
  {
    value: 'Environmental Engineer',
    label: 'Environmental Engineer'
  },
  {
    value: 'Equipment Manufacturer',
    label: 'Equipment Manufacturer'
  },
  {
    value: 'Ergonomist',
    label: 'Ergonomist'
  },
  {
    value: 'Estimator',
    label: 'Estimator'
  },
  {
    value: 'Ethnologist',
    label: 'Ethnologist'
  },
  {
    value: 'Ethologist',
    label: 'Ethologist'
  },
  {
    value: 'Event Manager',
    label: 'Event Manager'
  },
  {
    value: 'Exchange Dealer',
    label: 'Exchange Dealer'
  },
  {
    value: 'Extra',
    label: 'Extra'
  },
  {
    value: 'Facility Manager',
    label: 'Facility Manager'
  },
  {
    value: 'Farmer',
    label: 'Farmer'
  },
  {
    value: 'Farrier',
    label: 'Farrier'
  },
  {
    value: 'Fashion Designer',
    label: 'Fashion Designer'
  },
  {
    value: 'Fashion Model',
    label: 'Fashion Model'
  },
  {
    value: 'Fashion Retailer',
    label: 'Fashion Retailer'
  },
  {
    value: 'Fermentation Technician',
    label: 'Fermentation Technician'
  },
  {
    value: 'Fiduciary Assistant',
    label: 'Fiduciary Assistant'
  },
  {
    value: 'Field Consultant',
    label: 'Field Consultant'
  },
  {
    value: 'Field Support',
    label: 'Field Support'
  },
  {
    value: 'Film Editor',
    label: 'Film Editor'
  },
  {
    value: 'Finance and Accounting Assistant',
    label: 'Finance and Accounting Assistant'
  },
  {
    value: 'Financial Accountant',
    label: 'Financial Accountant'
  },
  {
    value: 'Financial Analyst',
    label: 'Financial Analyst'
  },
  {
    value: 'Firefighter',
    label: 'Firefighter'
  },
  {
    value: 'Flight Instructor',
    label: 'Flight Instructor'
  },
  {
    value: 'Floorer',
    label: 'Floorer'
  },
  {
    value: 'Florist',
    label: 'Florist'
  },
  {
    value: 'Folklorist',
    label: 'Folklorist'
  },
  {
    value: 'Food & Beverage Manager',
    label: 'Food & Beverage Manager'
  },
  {
    value: 'Food Analyst',
    label: 'Food Analyst'
  },
  {
    value: 'Food Delivery',
    label: 'Food Delivery'
  },
  {
    value: 'Food Technician',
    label: 'Food Technician'
  },
  {
    value: 'Foreign Correspondent',
    label: 'Foreign Correspondent'
  },
  {
    value: 'Foreign Language Correspondent',
    label: 'Foreign Language Correspondent'
  },
  {
    value: 'Foreign Trade Secretary',
    label: 'Foreign Trade Secretary'
  },
  {
    value: 'Forensic Scientist',
    label: 'Forensic Scientist'
  },
  {
    value: 'Forester',
    label: 'Forester'
  },
  {
    value: 'Forklift Driver',
    label: 'Forklift Driver'
  },
  {
    value: 'Foundry Technician',
    label: 'Foundry Technician'
  },
  {
    value: 'Franchise Entrepreneur',
    label: 'Franchise Entrepreneur'
  },
  {
    value: 'Fraud Analyst',
    label: 'Fraud Analyst'
  },
  {
    value: 'Fund Manager',
    label: 'Fund Manager'
  },
  {
    value: 'Fundraiser',
    label: 'Fundraiser'
  },
  {
    value: 'Gallery Owner',
    label: 'Gallery Owner'
  },
  {
    value: 'Game Designer',
    label: 'Game Designer'
  },
  {
    value: 'Gardener',
    label: 'Gardener'
  },
  {
    value: 'Gas Station Attendant',
    label: 'Gas Station Attendant'
  },
  {
    value: 'Gemstone Cutter',
    label: 'Gemstone Cutter'
  },
  {
    value: 'Genealogist',
    label: 'Genealogist'
  },
  {
    value: 'General Foreman',
    label: 'General Foreman'
  },
  {
    value: 'Genetic Technologist',
    label: 'Genetic Technologist'
  },
  {
    value: 'Geneticist',
    label: 'Geneticist'
  },
  {
    value: 'Geochemist',
    label: 'Geochemist'
  },
  {
    value: 'Geographer',
    label: 'Geographer'
  },
  {
    value: 'Geologist',
    label: 'Geologist'
  },
  {
    value: 'Geophysicist',
    label: 'Geophysicist'
  },
  {
    value: 'Geotechnical Engineer',
    label: 'Geotechnical Engineer'
  },
  {
    value: 'Germanist',
    label: 'Germanist'
  },
  {
    value: 'Gerontologist',
    label: 'Gerontologist'
  },
  {
    value: 'Glass Designer',
    label: 'Glass Designer'
  },
  {
    value: 'Glazier',
    label: 'Glazier'
  },
  {
    value: 'Goldsmith',
    label: 'Goldsmith'
  },
  {
    value: 'Golf Instructor',
    label: 'Golf Instructor'
  },
  {
    value: 'Graphic Designer',
    label: 'Graphic Designer'
  },
  {
    value: 'Greenkeeper',
    label: 'Greenkeeper'
  },
  {
    value: 'Groundskeeper',
    label: 'Groundskeeper'
  },
  {
    value: 'Gymnastics Trainer',
    label: 'Gymnastics Trainer'
  },
  {
    value: 'Hairdresser',
    label: 'Hairdresser'
  },
  {
    value: 'Harbour Master',
    label: 'Harbour Master'
  },
  {
    value: 'Hardware Developer',
    label: 'Hardware Developer'
  },
  {
    value: 'Head of Development',
    label: 'Head of Development'
  },
  {
    value: 'Health Trainer',
    label: 'Health Trainer'
  },
  {
    value: 'Helicopter Pilot',
    label: 'Helicopter Pilot'
  },
  {
    value: 'Help Desk Agent',
    label: 'Help Desk Agent'
  },
  {
    value: 'Historian',
    label: 'Historian'
  },
  {
    value: 'Home Manager',
    label: 'Home Manager'
  },
  {
    value: 'Home Stager',
    label: 'Home Stager'
  },
  {
    value: 'Horse Groom',
    label: 'Horse Groom'
  },
  {
    value: 'Horticultural Technician',
    label: 'Horticultural Technician'
  },
  {
    value: 'Hospital Manager',
    label: 'Hospital Manager'
  },
  {
    value: 'Hotel Assistant',
    label: 'Hotel Assistant'
  },
  {
    value: 'Hotel Clerk',
    label: 'Hotel Clerk'
  },
  {
    value: 'Hotel Manager',
    label: 'Hotel Manager'
  },
  {
    value: 'Hotel Porter',
    label: 'Hotel Porter'
  },
  {
    value: 'Hotel Secretary',
    label: 'Hotel Secretary'
  },
  {
    value: 'Housekeeper',
    label: 'Housekeeper'
  },
  {
    value: 'Housewife',
    label: 'Housewife'
  },
  {
    value: 'HR Administrator',
    label: 'HR Administrator'
  },
  {
    value: 'HR Manager',
    label: 'HR Manager'
  },
  {
    value: 'Hunter',
    label: 'Hunter'
  },
  {
    value: 'Hydraulic Engineer',
    label: 'Hydraulic Engineer'
  },
  {
    value: 'IC-Designer',
    label: 'IC-Designer'
  },
  {
    value: 'Illustrator',
    label: 'Illustrator'
  },
  {
    value: 'Imam',
    label: 'Imam'
  },
  {
    value: 'Industrial Clerk',
    label: 'Industrial Clerk'
  },
  {
    value: 'Industrial Climber',
    label: 'Industrial Climber'
  },
  {
    value: 'Industrial Designer',
    label: 'Industrial Designer'
  },
  {
    value: 'Industrial Logistics Specialist',
    label: 'Industrial Logistics Specialist'
  },
  {
    value: 'Industrial Technician',
    label: 'Industrial Technician'
  },
  {
    value: 'Infographic Artist',
    label: 'Infographic Artist'
  },
  {
    value: 'Information Broker',
    label: 'Information Broker'
  },
  {
    value: 'Information Manager',
    label: 'Information Manager'
  },
  {
    value: 'Innovation Technician',
    label: 'Innovation Technician'
  },
  {
    value: 'Instagrammer',
    label: 'Instagrammer'
  },
  {
    value: 'Installation Artist',
    label: 'Installation Artist'
  },
  {
    value: 'Instructor',
    label: 'Instructor'
  },
  {
    value: 'Insurance Agent',
    label: 'Insurance Agent'
  },
  {
    value: 'Insurance Broker',
    label: 'Insurance Broker'
  },
  {
    value: 'Insurance Consultant',
    label: 'Insurance Consultant'
  },
  {
    value: 'Insurance Specialist',
    label: 'Insurance Specialist'
  },
  {
    value: 'Interface Designer',
    label: 'Interface Designer'
  },
  {
    value: 'Interior Consultant',
    label: 'Interior Consultant'
  },
  {
    value: 'Interior Designer',
    label: 'Interior Designer'
  },
  {
    value: 'Interpreter',
    label: 'Interpreter'
  },
  {
    value: 'Interviewer',
    label: 'Interviewer'
  },
  {
    value: 'Investment Aadvisor',
    label: 'Investment Aadvisor'
  },
  {
    value: 'Investment Advisor',
    label: 'Investment Advisor'
  },
  {
    value: 'Investment Analyst',
    label: 'Investment Analyst'
  },
  {
    value: 'Investor',
    label: 'Investor'
  },
  {
    value: 'IT Assistant',
    label: 'IT Assistant'
  },
  {
    value: 'IT Clerk',
    label: 'IT Clerk'
  },
  {
    value: 'IT Consultant',
    label: 'IT Consultant'
  },
  {
    value: 'IT Forensics Scientist',
    label: 'IT Forensics Scientist'
  },
  {
    value: 'IT Organizational Consultant',
    label: 'IT Organizational Consultant'
  },
  {
    value: 'IT Project Manager',
    label: 'IT Project Manager'
  },
  {
    value: 'IT Sales Manager',
    label: 'IT Sales Manager'
  },
  {
    value: 'IT Security Manager',
    label: 'IT Security Manager'
  },
  {
    value: 'IT System Electronics Technician',
    label: 'IT System Electronics Technician'
  },
  {
    value: 'Jeweller',
    label: 'Jeweller'
  },
  {
    value: 'Jewelry Designer',
    label: 'Jewelry Designer'
  },
  {
    value: 'Jockey',
    label: 'Jockey'
  },
  {
    value: 'Journalist',
    label: 'Journalist'
  },
  {
    value: 'Judge',
    label: 'Judge'
  },
  {
    value: 'Key Account  Manager',
    label: 'Key Account  Manager'
  },
  {
    value: 'Kindergarten Assistant',
    label: 'Kindergarten Assistant'
  },
  {
    value: 'Kitchen Assistant',
    label: 'Kitchen Assistant'
  },
  {
    value: 'Label Manager',
    label: 'Label Manager'
  },
  {
    value: 'Laboratory Technician',
    label: 'Laboratory Technician'
  },
  {
    value: 'Landscape Planner',
    label: 'Landscape Planner'
  },
  {
    value: 'Language Teacher',
    label: 'Language Teacher'
  },
  {
    value: 'Lawyer',
    label: 'Lawyer'
  },
  {
    value: 'Learning Coach',
    label: 'Learning Coach'
  },
  {
    value: 'Learning Therapist',
    label: 'Learning Therapist'
  },
  {
    value: 'Leasing Specialist',
    label: 'Leasing Specialist'
  },
  {
    value: 'Lecturer',
    label: 'Lecturer'
  },
  {
    value: 'Legal Assistant',
    label: 'Legal Assistant'
  },
  {
    value: 'Legal Scholar',
    label: 'Legal Scholar'
  },
  {
    value: 'Librarian',
    label: 'Librarian'
  },
  {
    value: 'Life and Social Counsellor',
    label: 'Life and Social Counsellor'
  },
  {
    value: 'Lifeguard',
    label: 'Lifeguard'
  },
  {
    value: 'Lighting Technician',
    label: 'Lighting Technician'
  },
  {
    value: 'Linguist',
    label: 'Linguist'
  },
  {
    value: 'Literary Scholar',
    label: 'Literary Scholar'
  },
  {
    value: 'Lithographer',
    label: 'Lithographer'
  },
  {
    value: 'Logistics Specialist',
    label: 'Logistics Specialist'
  },
  {
    value: 'Magician',
    label: 'Magician'
  },
  {
    value: 'Maid',
    label: 'Maid'
  },
  {
    value: 'Make-Up Artist',
    label: 'Make-Up Artist'
  },
  {
    value: 'Management Assistant',
    label: 'Management Assistant'
  },
  {
    value: 'Management Consultant (advice on foreign trade matters)',
    label: 'Management Consultant (advice on foreign trade matters)'
  },
  {
    value: 'Management Consultant (Business Mediation)',
    label: 'Management Consultant (Business Mediation)'
  },
  {
    value: 'Management Consultant (Engineering / Technology)',
    label: 'Management Consultant (Engineering / Technology)'
  },
  {
    value: 'Management Consultant (Environmental Management)',
    label: 'Management Consultant (Environmental Management)'
  },
  {
    value: 'Management Consultant (Finance and Accounting)',
    label: 'Management Consultant (Finance and Accounting)'
  },
  {
    value: 'Management Consultant (Human Resources)',
    label: 'Management Consultant (Human Resources)'
  },
  {
    value: 'Management Consultant (Logistics)',
    label: 'Management Consultant (Logistics)'
  },
  {
    value:
      'Management Consultant (Management Management / Management Consulting)',
    label:
      'Management Consultant (Management Management / Management Consulting)'
  },
  {
    value: 'Management Consultant (Marketing)',
    label: 'Management Consultant (Marketing)'
  },
  {
    value: 'Management Consultant (Organisation)',
    label: 'Management Consultant (Organisation)'
  },
  {
    value: 'Manager',
    label: 'Manager'
  },
  {
    value: 'Manager (Controlling)',
    label: 'Manager (Controlling)'
  },
  {
    value: 'Manager (Export)',
    label: 'Manager (Export)'
  },
  {
    value: 'Manager (Hospital)',
    label: 'Manager (Hospital)'
  },
  {
    value: 'Manager (Human Resources)',
    label: 'Manager (Human Resources)'
  },
  {
    value: 'Manager (Investment Planning)',
    label: 'Manager (Investment Planning)'
  },
  {
    value: 'Manager (Logistics)',
    label: 'Manager (Logistics)'
  },
  {
    value: 'Manager (Materials Management)',
    label: 'Manager (Materials Management)'
  },
  {
    value: 'Manager (Product)',
    label: 'Manager (Product)'
  },
  {
    value: 'Manager (Production)',
    label: 'Manager (Production)'
  },
  {
    value: 'Manager (Purchasing, Procurement)',
    label: 'Manager (Purchasing, Procurement)'
  },
  {
    value: 'Manager (Sales)',
    label: 'Manager (Sales)'
  },
  {
    value: 'Managing Director',
    label: 'Managing Director'
  },
  {
    value: 'Mannequin / Dressman',
    label: 'Mannequin / Dressman'
  },
  {
    value: 'Manufacturing Technician',
    label: 'Manufacturing Technician'
  },
  {
    value: 'Marine Biologist',
    label: 'Marine Biologist'
  },
  {
    value: 'Marketing Manager',
    label: 'Marketing Manager'
  },
  {
    value: 'Marketing Specialist',
    label: 'Marketing Specialist'
  },
  {
    value: 'Marriage and Family Counselor',
    label: 'Marriage and Family Counselor'
  },
  {
    value: 'Mason',
    label: 'Mason'
  },
  {
    value: 'Masseur',
    label: 'Masseur'
  },
  {
    value: 'Material and Processing Technician',
    label: 'Material and Processing Technician'
  },
  {
    value: 'Materials Engineer',
    label: 'Materials Engineer'
  },
  {
    value: 'Mathematician',
    label: 'Mathematician'
  },
  {
    value: 'Mechanic',
    label: 'Mechanic'
  },
  {
    value: 'Mechanical Technician',
    label: 'Mechanical Technician'
  },
  {
    value: 'Mechatronics Engineer',
    label: 'Mechatronics Engineer'
  },
  {
    value: 'Media Archivist',
    label: 'Media Archivist'
  },
  {
    value: 'Media Designer',
    label: 'Media Designer'
  },
  {
    value: 'Media Planner',
    label: 'Media Planner'
  },
  {
    value: 'Media Specialist',
    label: 'Media Specialist'
  },
  {
    value: 'Mediator',
    label: 'Mediator'
  },
  {
    value: 'Medical Device Clerk',
    label: 'Medical Device Clerk'
  },
  {
    value: 'Medical Technician',
    label: 'Medical Technician'
  },
  {
    value: 'Member of the Board of Directors',
    label: 'Member of the Board of Directors'
  },
  {
    value: 'Merchandiser',
    label: 'Merchandiser'
  },
  {
    value: 'Metal Caster',
    label: 'Metal Caster'
  },
  {
    value: 'Metallurgist',
    label: 'Metallurgist'
  },
  {
    value: 'Meteorologist',
    label: 'Meteorologist'
  },
  {
    value: 'Metrologist',
    label: 'Metrologist'
  },
  {
    value: 'Microbiologist',
    label: 'Microbiologist'
  },
  {
    value: 'Microelectronics Technician',
    label: 'Microelectronics Technician'
  },
  {
    value: 'Microtechnician',
    label: 'Microtechnician'
  },
  {
    value: 'Milliner',
    label: 'Milliner'
  },
  {
    value: 'Mineralogist',
    label: 'Mineralogist'
  },
  {
    value: 'Mining Technician',
    label: 'Mining Technician'
  },
  {
    value: 'Model Maker',
    label: 'Model Maker'
  },
  {
    value: 'Molecular Biologist',
    label: 'Molecular Biologist'
  },
  {
    value: 'Molecular Medicine Specialist',
    label: 'Molecular Medicine Specialist'
  },
  {
    value: 'Molecular Technician',
    label: 'Molecular Technician'
  },
  {
    value: 'Mortician',
    label: 'Mortician'
  },
  {
    value: 'Motor Vehicle Expert',
    label: 'Motor Vehicle Expert'
  },
  {
    value: 'Mountain Guide',
    label: 'Mountain Guide'
  },
  {
    value: 'Multimedia Artist',
    label: 'Multimedia Artist'
  },
  {
    value: 'Multimedia Designer',
    label: 'Multimedia Designer'
  },
  {
    value: 'Museum Director',
    label: 'Museum Director'
  },
  {
    value: 'Music Manager',
    label: 'Music Manager'
  },
  {
    value: 'Music Producer',
    label: 'Music Producer'
  },
  {
    value: 'Music Teacher',
    label: 'Music Teacher'
  },
  {
    value: 'Music Vendors',
    label: 'Music Vendors'
  },
  {
    value: 'Musical Actror',
    label: 'Musical Actror'
  },
  {
    value: 'Musician',
    label: 'Musician'
  },
  {
    value: 'Musicologist',
    label: 'Musicologist'
  },
  {
    value: 'Nanny',
    label: 'Nanny'
  },
  {
    value: 'Nanobiotechnologist',
    label: 'Nanobiotechnologist'
  },
  {
    value: 'Nanotechnologist',
    label: 'Nanotechnologist'
  },
  {
    value: 'Network Administrator',
    label: 'Network Administrator'
  },
  {
    value: 'Network Architect',
    label: 'Network Architect'
  },
  {
    value: 'Network Designer',
    label: 'Network Designer'
  },
  {
    value: 'Network Technician',
    label: 'Network Technician'
  },
  {
    value: 'Neurolinguist',
    label: 'Neurolinguist'
  },
  {
    value: 'New Media Consultant',
    label: 'New Media Consultant'
  },
  {
    value: 'Night Auditor',
    label: 'Night Auditor'
  },
  {
    value: 'Notary Public',
    label: 'Notary Public'
  },
  {
    value: 'Nutritionist',
    label: 'Nutritionist'
  },
  {
    value: 'Office Assistant',
    label: 'Office Assistant'
  },
  {
    value: 'Office Manager',
    label: 'Office Manager'
  },
  {
    value: 'Online Editor',
    label: 'Online Editor'
  },
  {
    value: 'Online Marketing Specialist',
    label: 'Online Marketing Specialist'
  },
  {
    value: 'Operations Controller - Air Traffic',
    label: 'Operations Controller - Air Traffic'
  },
  {
    value: 'Operations Technician',
    label: 'Operations Technician'
  },
  {
    value: 'Other (please describe)',
    label: 'Other (please describe)'
  },
  {
    value: 'Outplacer',
    label: 'Outplacer'
  },
  {
    value: 'Paint Technician',
    label: 'Paint Technician'
  },
  {
    value: 'Painter (Art)',
    label: 'Painter (Art)'
  },
  {
    value: 'Panel-Beater',
    label: 'Panel-Beater'
  },
  {
    value: 'Paramedic',
    label: 'Paramedic'
  },
  {
    value: 'Parcel Carrier',
    label: 'Parcel Carrier'
  },
  {
    value: 'Party Organizer',
    label: 'Party Organizer'
  },
  {
    value: 'Pastor',
    label: 'Pastor'
  },
  {
    value: 'Pastry Chef',
    label: 'Pastry Chef'
  },
  {
    value: 'Patent Attorney',
    label: 'Patent Attorney'
  },
  {
    value: 'Patent Professional',
    label: 'Patent Professional'
  },
  {
    value: 'Patissier',
    label: 'Patissier'
  },
  {
    value: 'Paver',
    label: 'Paver'
  },
  {
    value: 'Pawnbroker',
    label: 'Pawnbroker'
  },
  {
    value: 'PE Teacher',
    label: 'PE Teacher'
  },
  {
    value: 'Pedagogue',
    label: 'Pedagogue'
  },
  {
    value: 'Performance Artist',
    label: 'Performance Artist'
  },
  {
    value: 'Personnel Consultant',
    label: 'Personnel Consultant'
  },
  {
    value: 'Pest Controller',
    label: 'Pest Controller'
  },
  {
    value: 'Petrochemist',
    label: 'Petrochemist'
  },
  {
    value: 'Petroleum Chemist',
    label: 'Petroleum Chemist'
  },
  {
    value: 'Petroleum Technician',
    label: 'Petroleum Technician'
  },
  {
    value: 'Pharmaceutical Technologist',
    label: 'Pharmaceutical Technologist'
  },
  {
    value: 'Pharmacologist',
    label: 'Pharmacologist'
  },
  {
    value: 'Philologist',
    label: 'Philologist'
  },
  {
    value: 'Philosopher',
    label: 'Philosopher'
  },
  {
    value: 'Photo and Multimedia Clerk',
    label: 'Photo and Multimedia Clerk'
  },
  {
    value: 'Photographer (Art)',
    label: 'Photographer (Art)'
  },
  {
    value: 'Physicist',
    label: 'Physicist'
  },
  {
    value: 'Physics Laboratory Assistant',
    label: 'Physics Laboratory Assistant'
  },
  {
    value: 'Physics laboratory assistant',
    label: 'Physics laboratory assistant'
  },
  {
    value: 'Pilates Trainer',
    label: 'Pilates Trainer'
  },
  {
    value: 'Pilot',
    label: 'Pilot'
  },
  {
    value: 'Placarder',
    label: 'Placarder'
  },
  {
    value: 'Planner',
    label: 'Planner'
  },
  {
    value: 'Plant Engineering Technician',
    label: 'Plant Engineering Technician'
  },
  {
    value: 'Plastics Technician',
    label: 'Plastics Technician'
  },
  {
    value: 'PLC Technician',
    label: 'PLC Technician'
  },
  {
    value: 'Plumber',
    label: 'Plumber'
  },
  {
    value: 'Podiatrist',
    label: 'Podiatrist'
  },
  {
    value: 'Police Officer',
    label: 'Police Officer'
  },
  {
    value: 'Political Scientist',
    label: 'Political Scientist'
  },
  {
    value: 'Politician',
    label: 'Politician'
  },
  {
    value: 'Polymer Chemist',
    label: 'Polymer Chemist'
  },
  {
    value: 'Portfolio Manager',
    label: 'Portfolio Manager'
  },
  {
    value: 'Postal Worker',
    label: 'Postal Worker'
  },
  {
    value: 'Postman',
    label: 'Postman'
  },
  {
    value: 'PR Consultant',
    label: 'PR Consultant'
  },
  {
    value: 'Precision Technician',
    label: 'Precision Technician'
  },
  {
    value: 'Press Photographer',
    label: 'Press Photographer'
  },
  {
    value: 'Primary School Teacher',
    label: 'Primary School Teacher'
  },
  {
    value: 'Printing Technician',
    label: 'Printing Technician'
  },
  {
    value: 'Private Client Advisor',
    label: 'Private Client Advisor'
  },
  {
    value: 'Privateer',
    label: 'Privateer'
  },
  {
    value: 'Probation Officer',
    label: 'Probation Officer'
  },
  {
    value: 'Process Engineer',
    label: 'Process Engineer'
  },
  {
    value: 'Process Manager',
    label: 'Process Manager'
  },
  {
    value: 'Process Planner',
    label: 'Process Planner'
  },
  {
    value: 'Process Technician',
    label: 'Process Technician'
  },
  {
    value: 'Producer',
    label: 'Producer'
  },
  {
    value: 'Product Developer',
    label: 'Product Developer'
  },
  {
    value: 'Production Designer',
    label: 'Production Designer'
  },
  {
    value: 'Production Manager',
    label: 'Production Manager'
  },
  {
    value: 'Production Technician',
    label: 'Production Technician'
  },
  {
    value: 'Professor',
    label: 'Professor'
  },
  {
    value: 'Promotional Graphic Designer',
    label: 'Promotional Graphic Designer'
  },
  {
    value: 'Property Manager',
    label: 'Property Manager'
  },
  {
    value: 'Psychologist',
    label: 'Psychologist'
  },
  {
    value: 'Psychotherapist',
    label: 'Psychotherapist'
  },
  {
    value: 'Public Official',
    label: 'Public Official'
  },
  {
    value: 'Public Prosecutor',
    label: 'Public Prosecutor'
  },
  {
    value: 'Publicist',
    label: 'Publicist'
  },
  {
    value: 'Publisher',
    label: 'Publisher'
  },
  {
    value: 'Pulp Technician',
    label: 'Pulp Technician'
  },
  {
    value: 'Puppeteer',
    label: 'Puppeteer'
  },
  {
    value: 'Pyrotechnician',
    label: 'Pyrotechnician'
  },
  {
    value: 'Quality Assurance Technician',
    label: 'Quality Assurance Technician'
  },
  {
    value: 'Quantum Physicist',
    label: 'Quantum Physicist'
  },
  {
    value: 'Rabbi',
    label: 'Rabbi'
  },
  {
    value: 'Radio and Television Editor',
    label: 'Radio and Television Editor'
  },
  {
    value: 'Radio Operator',
    label: 'Radio Operator'
  },
  {
    value: 'Radio-Host',
    label: 'Radio-Host'
  },
  {
    value: 'Railway Technician',
    label: 'Railway Technician'
  },
  {
    value: 'Rating Analyst',
    label: 'Rating Analyst'
  },
  {
    value: 'Real Estate Agent',
    label: 'Real Estate Agent'
  },
  {
    value: 'Receptionist',
    label: 'Receptionist'
  },
  {
    value: 'Recruiter',
    label: 'Recruiter'
  },
  {
    value: 'Recycling Technician',
    label: 'Recycling Technician'
  },
  {
    value: 'Refugee Counsellor',
    label: 'Refugee Counsellor'
  },
  {
    value: 'Reiki Practitioner',
    label: 'Reiki Practitioner'
  },
  {
    value: 'Religious Scholar',
    label: 'Religious Scholar'
  },
  {
    value: 'Rescue Driver',
    label: 'Rescue Driver'
  },
  {
    value: 'Research & Development Technician',
    label: 'Research & Development Technician'
  },
  {
    value: 'Researcher',
    label: 'Researcher'
  },
  {
    value: 'Resilience Trainer',
    label: 'Resilience Trainer'
  },
  {
    value: 'Restorer',
    label: 'Restorer'
  },
  {
    value: 'Retail - Electrical',
    label: 'Retail - Electrical'
  },
  {
    value: 'Retail - Food',
    label: 'Retail - Food'
  },
  {
    value: 'Retail - Footwear',
    label: 'Retail - Footwear'
  },
  {
    value: 'Retail - Sporting Goods',
    label: 'Retail - Sporting Goods'
  },
  {
    value: 'Retail - Telecommunications',
    label: 'Retail - Telecommunications'
  },
  {
    value: 'Retail - Textile trade',
    label: 'Retail - Textile trade'
  },
  {
    value: 'Retiree',
    label: 'Retiree'
  },
  {
    value: 'Risk Manager',
    label: 'Risk Manager'
  },
  {
    value: 'Road Construction Worker',
    label: 'Road Construction Worker'
  },
  {
    value: 'Robotics Technician',
    label: 'Robotics Technician'
  },
  {
    value: 'Roofer',
    label: 'Roofer'
  },
  {
    value: 'Room Keeper',
    label: 'Room Keeper'
  },
  {
    value: 'Runner',
    label: 'Runner'
  },
  {
    value: 'Safety Specialist',
    label: 'Safety Specialist'
  },
  {
    value: 'Sailing Instructor',
    label: 'Sailing Instructor'
  },
  {
    value: 'Sailor',
    label: 'Sailor'
  },
  {
    value: 'Sales Manager',
    label: 'Sales Manager'
  },
  {
    value: 'Sales Technician',
    label: 'Sales Technician'
  },
  {
    value: 'Salesman',
    label: 'Salesman'
  },
  {
    value: 'Sattler and Riemer',
    label: 'Sattler and Riemer'
  },
  {
    value: 'Scaffolder',
    label: 'Scaffolder'
  },
  {
    value: 'School Psychologist',
    label: 'School Psychologist'
  },
  {
    value: 'School Social Worker',
    label: 'School Social Worker'
  },
  {
    value: 'Scrap Dealer',
    label: 'Scrap Dealer'
  },
  {
    value: 'Screen Designer',
    label: 'Screen Designer'
  },
  {
    value: 'Script Consultant',
    label: 'Script Consultant'
  },
  {
    value: 'Scriptwriter',
    label: 'Scriptwriter'
  },
  {
    value: 'Sculptor',
    label: 'Sculptor'
  },
  {
    value: 'Secretary',
    label: 'Secretary'
  },
  {
    value: 'Secretary of the Embassy',
    label: 'Secretary of the Embassy'
  },
  {
    value: 'Securities Analyst',
    label: 'Securities Analyst'
  },
  {
    value: 'Securities Dealer',
    label: 'Securities Dealer'
  },
  {
    value: 'Seed Cultivation Technician',
    label: 'Seed Cultivation Technician'
  },
  {
    value: 'Sensor Technician',
    label: 'Sensor Technician'
  },
  {
    value: 'Service Technician',
    label: 'Service Technician'
  },
  {
    value: 'Set Designer',
    label: 'Set Designer'
  },
  {
    value: 'Sex Educator',
    label: 'Sex Educator'
  },
  {
    value: 'Shaper',
    label: 'Shaper'
  },
  {
    value: 'Shepherd',
    label: 'Shepherd'
  },
  {
    value: 'Shipbuilder',
    label: 'Shipbuilder'
  },
  {
    value: 'Sign Language Interpreter',
    label: 'Sign Language Interpreter'
  },
  {
    value: 'Signalling Technician',
    label: 'Signalling Technician'
  },
  {
    value: 'Singer',
    label: 'Singer'
  },
  {
    value: 'Site Manager',
    label: 'Site Manager'
  },
  {
    value: 'Ski Construction Technician',
    label: 'Ski Construction Technician'
  },
  {
    value: 'Ski Instructor',
    label: 'Ski Instructor'
  },
  {
    value: 'Social and Economic Statistician',
    label: 'Social and Economic Statistician'
  },
  {
    value: 'Social Economist',
    label: 'Social Economist'
  },
  {
    value: 'Social Manager',
    label: 'Social Manager'
  },
  {
    value: 'Social Media Expert',
    label: 'Social Media Expert'
  },
  {
    value: 'Social Pedagogue',
    label: 'Social Pedagogue'
  },
  {
    value: 'Social Worker',
    label: 'Social Worker'
  },
  {
    value: 'Sociologist',
    label: 'Sociologist'
  },
  {
    value: 'Software Architect',
    label: 'Software Architect'
  },
  {
    value: 'Software Developer',
    label: 'Software Developer'
  },
  {
    value: 'Software Programmer',
    label: 'Software Programmer'
  },
  {
    value: 'Software Supervisor',
    label: 'Software Supervisor'
  },
  {
    value: 'Software Tester',
    label: 'Software Tester'
  },
  {
    value: 'Solar Technician',
    label: 'Solar Technician'
  },
  {
    value: 'Soldier',
    label: 'Soldier'
  },
  {
    value: 'Sommelier',
    label: 'Sommelier'
  },
  {
    value: 'Songwriter',
    label: 'Songwriter'
  },
  {
    value: 'Sound Designer',
    label: 'Sound Designer'
  },
  {
    value: 'Sound Engineer',
    label: 'Sound Engineer'
  },
  {
    value: 'Spatial Planner',
    label: 'Spatial Planner'
  },
  {
    value: 'Speaker',
    label: 'Speaker'
  },
  {
    value: 'Speech Therapist',
    label: 'Speech Therapist'
  },
  {
    value: 'Spokesperson',
    label: 'Spokesperson'
  },
  {
    value: 'Sports Administration',
    label: 'Sports Administration'
  },
  {
    value: 'Sports Equipment Manufacturer',
    label: 'Sports Equipment Manufacturer'
  },
  {
    value: 'Sports Manager',
    label: 'Sports Manager'
  },
  {
    value: 'Sports Scientist',
    label: 'Sports Scientist'
  },
  {
    value: 'Sports Technologist',
    label: 'Sports Technologist'
  },
  {
    value: 'Sports Therapist',
    label: 'Sports Therapist'
  },
  {
    value: 'Stage Painter',
    label: 'Stage Painter'
  },
  {
    value: 'Stage Technician',
    label: 'Stage Technician'
  },
  {
    value: 'Stagehand',
    label: 'Stagehand'
  },
  {
    value: 'Statistician',
    label: 'Statistician'
  },
  {
    value: 'Stewardess',
    label: 'Stewardess'
  },
  {
    value: 'Stonemason',
    label: 'Stonemason'
  },
  {
    value: 'Street Cleaner',
    label: 'Street Cleaner'
  },
  {
    value: 'Street Worker',
    label: 'Street Worker'
  },
  {
    value: 'Structural Engineer',
    label: 'Structural Engineer'
  },
  {
    value: 'Student',
    label: 'Student'
  },
  {
    value: 'Studio Technician',
    label: 'Studio Technician'
  },
  {
    value: 'Stuntman',
    label: 'Stuntman'
  },
  {
    value: 'Stylist',
    label: 'Stylist'
  },
  {
    value: 'Subway Driver',
    label: 'Subway Driver'
  },
  {
    value: 'Supply Chain Manager',
    label: 'Supply Chain Manager'
  },
  {
    value: 'Surf Instructor',
    label: 'Surf Instructor'
  },
  {
    value: 'Surveying Technician',
    label: 'Surveying Technician'
  },
  {
    value: 'Sustainability Manager',
    label: 'Sustainability Manager'
  },
  {
    value: 'Swimming Instructor',
    label: 'Swimming Instructor'
  },
  {
    value: 'System Administrator',
    label: 'System Administrator'
  },
  {
    value: 'Systems Analyst',
    label: 'Systems Analyst'
  },
  {
    value: 'Systems Scientist',
    label: 'Systems Scientist'
  },
  {
    value: 'Tanner',
    label: 'Tanner'
  },
  {
    value: 'Tattoo Artist and Piercer',
    label: 'Tattoo Artist and Piercer'
  },
  {
    value: 'Tax Advisor',
    label: 'Tax Advisor'
  },
  {
    value: 'Tax Officer',
    label: 'Tax Officer'
  },
  {
    value: 'Taxidermist',
    label: 'Taxidermist'
  },
  {
    value: 'Teacher',
    label: 'Teacher'
  },
  {
    value: 'Teacher at General Compulsory Schools',
    label: 'Teacher at General Compulsory Schools'
  },
  {
    value: 'Teacher at General Secondary Schools',
    label: 'Teacher at General Secondary Schools'
  },
  {
    value: 'Teacher at Vocational Middle and Higher Schools (BMHS)',
    label: 'Teacher at Vocational Middle and Higher Schools (BMHS)'
  },
  {
    value: 'Teacher of Religion',
    label: 'Teacher of Religion'
  },
  {
    value: 'Technician',
    label: 'Technician'
  },
  {
    value: 'Telematics Technician',
    label: 'Telematics Technician'
  },
  {
    value: 'Television Announcer',
    label: 'Television Announcer'
  },
  {
    value: 'Tennis Instructor',
    label: 'Tennis Instructor'
  },
  {
    value: 'Textile Chemist',
    label: 'Textile Chemist'
  },
  {
    value: 'Textile Cleaner',
    label: 'Textile Cleaner'
  },
  {
    value: 'Textile Designer',
    label: 'Textile Designer'
  },
  {
    value: 'Textile Merchant',
    label: 'Textile Merchant'
  },
  {
    value: 'Textile Technician',
    label: 'Textile Technician'
  },
  {
    value: 'Theologian',
    label: 'Theologian'
  },
  {
    value: 'Timber Construction Technician',
    label: 'Timber Construction Technician'
  },
  {
    value: 'Timber Merchant',
    label: 'Timber Merchant'
  },
  {
    value: 'Tissue Engineer',
    label: 'Tissue Engineer'
  },
  {
    value: 'Tool Designer',
    label: 'Tool Designer'
  },
  {
    value: 'Tour Guide',
    label: 'Tour Guide'
  },
  {
    value: 'Touring Guide',
    label: 'Touring Guide'
  },
  {
    value: 'Tourism Consultant',
    label: 'Tourism Consultant'
  },
  {
    value: 'Tourism Manager',
    label: 'Tourism Manager'
  },
  {
    value: 'Toxicologist',
    label: 'Toxicologist'
  },
  {
    value: 'Trade Fair and Exhibition Designer',
    label: 'Trade Fair and Exhibition Designer'
  },
  {
    value: 'Traffic Psychologist',
    label: 'Traffic Psychologist'
  },
  {
    value: 'Traffic-Clerk',
    label: 'Traffic-Clerk'
  },
  {
    value: 'Train Attendant',
    label: 'Train Attendant'
  },
  {
    value: 'Tram Driver',
    label: 'Tram Driver'
  },
  {
    value: 'Translator',
    label: 'Translator'
  },
  {
    value: 'Transport Economist',
    label: 'Transport Economist'
  },
  {
    value: 'Travel Designer',
    label: 'Travel Designer'
  },
  {
    value: 'Treasury Manager',
    label: 'Treasury Manager'
  },
  {
    value: 'Trend Scout',
    label: 'Trend Scout'
  },
  {
    value: 'Truck Driver',
    label: 'Truck Driver'
  },
  {
    value: 'Trustee',
    label: 'Trustee'
  },
  {
    value: 'Tunnelling Technician',
    label: 'Tunnelling Technician'
  },
  {
    value: 'TV-Host',
    label: 'TV-Host'
  },
  {
    value: 'University Assistant',
    label: 'University Assistant'
  },
  {
    value: 'University Lecturer',
    label: 'University Lecturer'
  },
  {
    value: 'University Professor',
    label: 'University Professor'
  },
  {
    value: 'Upholsterer',
    label: 'Upholsterer'
  },
  {
    value: 'Usability Engineer',
    label: 'Usability Engineer'
  },
  {
    value: 'Vehicle Electronics Technician',
    label: 'Vehicle Electronics Technician'
  },
  {
    value: 'Veterinarian',
    label: 'Veterinarian'
  },
  {
    value: 'Vintner',
    label: 'Vintner'
  },
  {
    value: 'Vitality Trainer',
    label: 'Vitality Trainer'
  },
  {
    value: 'Viticulture Technician',
    label: 'Viticulture Technician'
  },
  {
    value: 'Vocal Coach',
    label: 'Vocal Coach'
  },
  {
    value: 'Vocational Teacher',
    label: 'Vocational Teacher'
  },
  {
    value: 'Voice Actress',
    label: 'Voice Actress'
  },
  {
    value: 'Wagon-Maker',
    label: 'Wagon-Maker'
  },
  {
    value: 'Waiter',
    label: 'Waiter'
  },
  {
    value: 'Warehouse Worker',
    label: 'Warehouse Worker'
  },
  {
    value: 'Watchmaker',
    label: 'Watchmaker'
  },
  {
    value: 'Web Designer',
    label: 'Web Designer'
  },
  {
    value: 'Weber',
    label: 'Weber'
  },
  {
    value: 'Web-Master',
    label: 'Web-Master'
  },
  {
    value: 'Well Builder',
    label: 'Well Builder'
  },
  {
    value: 'Wholesale Merchant',
    label: 'Wholesale Merchant'
  },
  {
    value: 'Window Cleaner',
    label: 'Window Cleaner'
  },
  {
    value: 'Window Dresser',
    label: 'Window Dresser'
  },
  {
    value: 'Wood Farmer',
    label: 'Wood Farmer'
  },
  {
    value: 'Wood Technician',
    label: 'Wood Technician'
  },
  {
    value: 'Workshop Manager',
    label: 'Workshop Manager'
  },
  {
    value: 'Writer',
    label: 'Writer'
  },
  {
    value: 'Yoga Teacher',
    label: 'Yoga Teacher'
  },
  {
    value: 'Youth Worker',
    label: 'Youth Worker'
  },
  {
    value: 'Youtuber',
    label: 'Youtuber'
  }
]
