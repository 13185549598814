import React, { ReactElement, useEffect } from 'react'
import {
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  useForm
} from 'react-hook-form'
import dayjs from 'dayjs'
import { countries } from '../../../../data/countries'
import VerificationStepEntity from '../../../../dto/VerificationStepEntity.interface'
import configStore from '../../../../stores/config.store'
import { onPromise } from '../../../../util/formHelper'
import DateField from '../../../inputs/DateField'
import CountryDropdownSelected from '../../../inputs/Dropdowns/CountryDropdownSelected'
import TextInput from '../../../inputs/TextInput'
import ButtonRow from '../../../button/ButtonRow'
import { Content } from '../../../content/Content'
import H3 from '../../../Header/H3'
import { observer } from 'mobx-react'
import InputOptionType from '../../../../enums/InputOptionType'

interface Props {
  personalInfo: any
  save: (personalInfo: any) => void
  isSent?: boolean
  navigateBack: () => void
  additionalFields?: JSX.Element
}

const PersonalInformationForm = ({
  personalInfo,
  save,
  isSent = false,
  navigateBack,
  additionalFields
}: Props): ReactElement => {
  const methods: UseFormReturn<VerificationStepEntity, any> =
    useForm<VerificationStepEntity>({ mode: 'onSubmit' })

  const onSubmit: SubmitHandler<VerificationStepEntity> = async (data: any) => {
    try {
      const { personalInfo } = data
      if (personalInfo.nationality) {
        personalInfo.nationality = personalInfo.nationality.value
      }
      if (
        personalInfo &&
        personalInfo.address &&
        personalInfo.address.country
      ) {
        personalInfo.address.country = personalInfo.address.country.value
      }

      if (personalInfo && personalInfo.dateOfBirth) {
        personalInfo.dateOfBirth = dayjs(
          personalInfo.dateOfBirth,
          'DD.MM.YYYY'
        ).toDate()
      }

      save(personalInfo)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    methods.setValue('personalInfo', personalInfo)

    if (personalInfo && personalInfo.nationality) {
      for (const country of countries) {
        if (country.value === personalInfo.nationality) {
          methods.setValue('personalInfo.nationality', country)
        }
      }
    }

    if (personalInfo && personalInfo.address && personalInfo.address.country) {
      for (const country of countries) {
        if (country.value === personalInfo.address.country) {
          methods.setValue('personalInfo.address.country', country)
        }
      }
    }

    if (personalInfo && personalInfo.dateOfBirth) {
      methods.setValue(
        'personalInfo.dateOfBirth',
        dayjs(personalInfo.dateOfBirth).format('DD.MM.YYYY')
      )
    }
  }, [personalInfo])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onPromise(methods.handleSubmit(onSubmit))}
        className="h-full flex flex-col lg:justify-between"
      >
        <Content>
          <div>
            <div className="sm:grid sm:gap-x-2 sm:grid-cols-3">
              <TextInput
                variable="personalInfo.firstName"
                title="First Name"
                optionType={InputOptionType.TEXT}
                className="w-full"
                disabled={isSent}
              />
              <TextInput
                variable="personalInfo.middleName"
                title="Middle Name"
                optionType={InputOptionType.NOT_REQUIRED_TEXT}
                className="w-full"
                disabled={isSent}
              />
              <TextInput
                variable="personalInfo.lastName"
                title="Last Name"
                optionType={InputOptionType.TEXT}
                className="w-full"
                disabled={isSent}
              />
            </div>
            <DateField
              control={methods.control}
              variable="personalInfo.dateOfBirth"
              required
              title="Date of Birth"
              className="w-full"
              disabled={isSent}
            />
            <CountryDropdownSelected
              variable="personalInfo.nationality"
              title="Nationality"
              options={countries}
              required={true}
              disabled={isSent}
              restricted={configStore.config?.countryRestriction?.nationalities}
            />
          </div>
          <H3 className="mb-xs mt-xl">Address</H3>
          <TextInput
            variable="personalInfo.address.street"
            title="Street & Nr."
            className="w-full"
            optionType={InputOptionType.TEXT}
            disabled={isSent}
          />
          <div className="sm:grid sm:gap-x-2 sm:grid-cols-2">
            <TextInput
              variable="personalInfo.address.zip"
              title="Zip"
              optionType={InputOptionType.NUMBER}
              className="w-full"
              disabled={isSent}
            />
            <TextInput
              variable="personalInfo.address.city"
              title="City"
              optionType={InputOptionType.TEXT}
              className="w-full"
              disabled={isSent}
            />
          </div>
          <CountryDropdownSelected
            variable="personalInfo.address.country"
            title="Country of Residence"
            options={countries}
            required={true}
            disabled={isSent}
            restricted={configStore.config?.countryRestriction?.residences}
          />
          {additionalFields && additionalFields}
        </Content>
        <ButtonRow
          secondary={{ onClick: navigateBack }}
          primary={{
            title: 'Save',
            type: 'submit',
            hide: isSent
          }}
        />
      </form>
    </FormProvider>
  )
}

export default observer(PersonalInformationForm)
