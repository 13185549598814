export enum VerificationStepType {
    PERSONAL_INFO = 'personalInfo',
    ID_AND_LIVENESS = 'idAndLiveness',
    PROOF_OF_RESIDENCY = 'proofOfResidency',
    QUESTIONNAIRE = 'questionnaire',
    WALLET = 'wallet',
    COMPANY_INFORMATION = 'companyInfo',
    DIRECTOR_APPROVAL = 'directorApproval',
    LEGAL_DOCUMENTS = 'legalDocuments',
    BENEFICIAL_OWNER = 'beneficialOwner',
}

export default VerificationStepType
