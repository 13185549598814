import React, { ReactElement, useEffect } from 'react'
import IdAndLiveness from '../../../components/verification-steps/steps/idAndLiveness/IdAndLiveness'
import verificationStore from '../../../stores/verificaton.store'
import configStore from '../../../stores/config.store'
import { observer } from 'mobx-react'

const UserIdAndLiveness: React.FC = (): ReactElement => {
  useEffect(() => {
    configStore.loadConfigFromServer().catch((err) => console.log(err))
  }, [])

  return (
    <IdAndLiveness
      verificationStore={verificationStore}
      verificationStepsConfig={configStore.config?.verificationStepsConfig}
    />
  )
}

export default observer(UserIdAndLiveness)
