import React, { ReactElement } from 'react'
import { VerificationStepStatus } from '../../enums/VerificationStepStatus'
import VerificationStepType from '../../enums/VerificationStepType'
import {
  getActiveVerificationSteps,
  getVerificationStepEntityName
} from '../../util/stepHelper'
import VerificationProcessCard from '../card/VerificationProcessCard'
import User from '../../dto/User.interface'

interface Props {
  config: any
  user?: User
  canEdit?: boolean
  className?: string
  customStepName?: (step: VerificationStepType) => string | undefined
  customNavigate?: (step: VerificationStepType) => void
}

const VerificationProcessSteps = ({
  config,
  user,
  canEdit = true,
  className = '',
  customStepName,
  customNavigate
}: Props): ReactElement => {
  let counter = 0
  return (
    <div className={className}>
      {getActiveVerificationSteps(config).map(
        (stepType: VerificationStepType, index: number) => {
          const stepName = getVerificationStepEntityName(stepType)

          if (!stepName) {
            return <div key={index}></div>
          }

          const step = config[stepName]

          let status = VerificationStepStatus.NOT_STARTED
          if (user && (user as any)[stepName]) {
            status = (user as any)[stepName].status
          }

          return (
            <VerificationProcessCard
              className="mb-5"
              stepNr={counter++}
              key={index}
              customName={customStepName && customStepName(stepType)}
              customNavigate={customNavigate}
              disabled={
                (!canEdit && status === VerificationStepStatus.NOT_STARTED) ||
                (!canEdit && stepName === VerificationStepType.ID_AND_LIVENESS)
              }
              verificationStep={{
                name: stepName,
                status,
                ...step
              }}
            />
          )
        }
      )}
    </div>
  )
}

export default VerificationProcessSteps
