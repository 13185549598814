import React, { ReactElement } from 'react'
import configStore from '../../stores/config.store'
import { observer } from 'mobx-react'
import H1 from '../Header/H1'
import SmallText from '../text/SmallText'

interface Props {
  heading: string
  className?: string
}

const InformativeHeader: React.FC<Props> = ({
  heading,
  className = ''
}: Props): ReactElement => {
  if (!configStore.config) {
    return <></>
  }

  return (
    <div className={className}>
      <SmallText style="secondary">
        {/* <LockClosedIcon className='w-3 h-3 leading-5 mr-1' /> */}
        {configStore.config.name}
      </SmallText>
      <H1>{heading}</H1>
    </div>
  )
}

export default observer(InformativeHeader)
