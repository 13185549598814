import { observer } from 'mobx-react'
import React, { ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import VerificationStep from '../../dto/VerificationStep.interface'
import VerificationStepType from '../../enums/VerificationStepType'

import StatusBadge from '../status/StatusBadge'
import H5 from '../Header/H5'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { getStepIcon, getStepSlug, getStepTitle } from '../../util/stepHelper'

interface Props {
  verificationStep: VerificationStep
  stepNr: number
  className?: string
  disabled?: boolean
  customName?: string
  customNavigate?: (name: VerificationStepType) => void
}

const VerificationProcessCard: React.FC<Props> = ({
  verificationStep,
  className = '',
  customName,
  disabled = false,
  customNavigate
}): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()

  let parentSlug = ''
  if (location.pathname.includes('officer-verification')) {
    parentSlug = '/officer-verification'
  }

  const navigateToCorrectPage = (name?: VerificationStepType): void => {
    if (!name) {
      return
    }

    if (customNavigate) {
      customNavigate(name)
      return
    }

    navigate(`${parentSlug}/${getStepSlug(name)}`)
  }

  const renderIcon = (name?: VerificationStepType): ReactElement => {
    const icon = getStepIcon(name)
    if (icon) {
      return <icon.type className="text-primary" />
    }

    return <></>
  }

  return (
    <div
      id={verificationStep.name}
      className={`bg-backgroundLight flex flex-row border border-gray-300 rounded-lg w-full p-2 justify-center items-center ${className} ${
        disabled
          ? 'bg-gray-100 text-grayText'
          : 'cursor-pointer hover:border-primary'
      }`}
      onClick={() => {
        if (!disabled) {
          navigateToCorrectPage(verificationStep.name)
        }
      }}
    >
      <div className="bg-red rounded-lg w-10 h-10 flex p-1.5 mr-3 bg-backgroundPrimary">
        {renderIcon(verificationStep.name)}
      </div>

      <div className="flex flex-row grow w-full justify-between items-center mr-2">
        <H5>{customName ?? getStepTitle(verificationStep.name)}</H5>
        <div className="flex items-center">
          <StatusBadge status={verificationStep.status} />
          <ChevronRightIcon className="ml-2 md:ml-10 text-primary h-5" />
        </div>
      </div>
    </div>
  )
}

export default observer(VerificationProcessCard)
