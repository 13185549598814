import { AcademicCapIcon, ReceiptPercentIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { LegalDocumentType } from '../../enums/LegalDocumentType'
import React from 'react'

const generateKeyOfLegalDocumentType = (docType: LegalDocumentType): string => {
  switch (docType) {
    case LegalDocumentType.CERTIFICATE_OF_INCORPORATION:
      return 'certificateOfIncorporation'
    case LegalDocumentType.REGISTER_OF_DIRECTORS:
      return 'registerOfDirectors'
    case LegalDocumentType.REGISTER_OF_MEMBERS:
      return 'registerOfMembers'
    default:
      return ''
  }
}

const generateLabelOfLegalDocumentType = (docType: string): string => {
  switch (docType) {
    case LegalDocumentType.CERTIFICATE_OF_INCORPORATION:
      return 'Certification Of Incorperation'
    case LegalDocumentType.REGISTER_OF_DIRECTORS:
      return 'Register Of Directors'
    case LegalDocumentType.REGISTER_OF_MEMBERS:
      return 'Register Of Members'
    default:
      return ''
  }
}

const generateIconOfLegalDocumentType = (
  docType: string,
  className?: string
): JSX.Element => {
  if (className === undefined) className = 'w-4 m-1'

  switch (docType) {
    case LegalDocumentType.CERTIFICATE_OF_INCORPORATION:
      return <ReceiptPercentIcon className={className} />
    case LegalDocumentType.REGISTER_OF_DIRECTORS:
      return <AcademicCapIcon className={className} />
    case LegalDocumentType.REGISTER_OF_MEMBERS:
      return <UserGroupIcon className={className} />
    default:
      return <></>
  }
}

export {
  generateKeyOfLegalDocumentType,
  generateLabelOfLegalDocumentType,
  generateIconOfLegalDocumentType
}
