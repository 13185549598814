import React, { ReactElement } from 'react'
import { components } from 'react-select'

const CustomDefaultControl = (props: any): ReactElement => {
  return (
    <components.Control {...props} className="flex flex-row items-center text">
      {props.children}
    </components.Control>
  )
}

export default CustomDefaultControl
