import React, { ReactElement } from 'react'
import TextInput from '../../../components/inputs/TextInput'
import kybStore from '../../../stores/kyb.store'
import { getParentNode, sumPercentages } from './helper/percentageHelper'
import InputOptionType from '../../../enums/InputOptionType'

interface Props {
  parentVariable: string
  disabled?: boolean
  value?: number
}

const BeneficialOwnerPercentageInput = ({
  parentVariable,
  disabled = false,
  value
}: Props): ReactElement => {
  let percentageAvailable = 100
  if (kybStore.currentNode && kybStore.treeRoot) {
    const parent = getParentNode(kybStore.currentNode, kybStore.treeRoot)
    if (parent) {
      let sum = sumPercentages(parent)
      if (value) {
        sum = sum - value
      }
      if (sum >= 100) {
        percentageAvailable = 0
      } else if (sum > 0) {
        percentageAvailable = 100 - sum
      }
    }
  }

  return (
    <>
      <h3 className="text-base font-semibold leading-6 text-gray-900 mt-4 mb-2">
        Ownership Percentage
      </h3>
      <TextInput
        variable={`${parentVariable}.ownerPercentage`}
        title="Owner Percentage"
        unit="%"
        hideTitle={true}
        type="number"
        optionType={InputOptionType.NUMBER}
        max={percentageAvailable}
        disabled={disabled}
        step={0.01}
        className="w-full"
      />
    </>
  )
}

export default BeneficialOwnerPercentageInput
