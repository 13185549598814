import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import FilePreviewExtended from './FilePreviewExtended'
import { useWatch } from 'react-hook-form'
import VerificationStepConfigEntity from '../../../../dto/VerificationStepConfigEntity.interface'
import Checkbox from '../../../checkbox/Checkbox'
import ButtonRow, { ButtonProps } from '../../../button/ButtonRow'
import { Content } from '../../../content/Content'

interface Props {
  methods: any
  back: any
  done: any
  verificationStepsConfig: VerificationStepConfigEntity
}

const AcceptDocument = ({
  methods,
  back,
  done,
  verificationStepsConfig
}: Props): ReactElement => {
  const [documents, setDocuments] = useState<any>()
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [documentStep, setDocumentStep] = useState(0)

  const data = useWatch({
    control: methods.control,
    name: 'data'
  })

  useEffect(() => {
    if (verificationStepsConfig.finish) {
      const { documents: finishDocuments } = verificationStepsConfig.finish

      for (let i = 0; i < finishDocuments.length; i++) {
        methods.setValue(`data.documentChecked-${i}`, false)
      }

      setDocuments(finishDocuments)
    }
  }, [])

  if (!documents) {
    return <div />
  }

  const getPrimaryButton = (): ButtonProps => {
    const disabled = !data[`documentChecked-${documentStep}`]
    if (documentStep < documents.length - 1) {
      return {
        disabled,
        onClick: () => {
          setDocumentStep(documentStep + 1)
          setScrolledToBottom(false)
        }
      }
    }

    return {
      disabled,
      onClick: () => {
        done()
      }
    }
  }

  return (
    <>
      <Content>
        <FilePreviewExtended
          file={documents[documentStep]}
          scrolledToBottom={() => {
            if (!scrolledToBottom) {
              setScrolledToBottom(true)
            }
          }}
          scrolledToTop={() => {
            if (scrolledToBottom) {
              setScrolledToBottom(false)
            }
          }}
        />
        <div className="relative">
          <Checkbox
            className="mt-5"
            variable={`data.documentChecked-${documentStep}`}
            text="I herewith acknowledge that I have read and agree to be bound by this document."
          />
        </div>
      </Content>
      <ButtonRow
        secondary={{
          onClick: () => {
            if (documentStep > 0) {
              setDocumentStep(documentStep - 1)
            }
            setScrolledToBottom(false)
          }
        }}
        primary={getPrimaryButton()}
      />
    </>
  )
}

export default observer(AcceptDocument)
