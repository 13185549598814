import React, { ReactElement, useEffect, useState } from 'react'
import { LegalDocumentType } from '../../../../enums/LegalDocumentType'
import kybStore from '../../../../stores/kyb.store'
import LegalDocumentTypes from '../../legalDocumentVerification/LegalDocumentTypes'
import ButtonRow from '../../../../components/button/ButtonRow'
import { Content } from '../../../../components/content/Content'
import { generateKeyOfLegalDocumentType } from '../../../../stores/helper/legalDocument.helper'
import { observer } from 'mobx-react'

interface KYBLegalDocumentsProps {
  back: () => void
  next: (value: LegalDocumentType) => void
  disabled?: boolean
}

const docTypes = [
  LegalDocumentType.CERTIFICATE_OF_INCORPORATION,
  LegalDocumentType.REGISTER_OF_DIRECTORS,
  LegalDocumentType.REGISTER_OF_MEMBERS
]

const KYBLegalDocumentsTypes = ({
  back,
  next,
  disabled = false
}: KYBLegalDocumentsProps): ReactElement => {
  const [legalDocuments, setLegalDocuments] = useState<any>(undefined)
  const [canComplete, setCanComplete] = useState<boolean>(false)

  useEffect(() => {
    if (kybStore.currentNode) {
      const { user } = kybStore.currentNode
      if (user && user.legalDocuments) {
        console.log(user.legalDocuments);
        setLegalDocuments(user.legalDocuments)

        let canCom = true
        Object.values(docTypes).forEach((docType) => {
          const key = generateKeyOfLegalDocumentType(docType)
          if (!user.legalDocuments[key]) {
            canCom = false
          }
        })
        setCanComplete(canCom)
      }
    }
  }, [kybStore.currentNode])

  return (
    <>
      <Content>
        <LegalDocumentTypes
          legalDocuments={legalDocuments}
          docTypes={docTypes}
          onNext={next}
          disabled={disabled}
        />
      </Content>
      <ButtonRow
        secondary={{ onClick: back }}
        primary={{
          onClick: () => {
            if (kybStore.currentNode) {
              kybStore
                .completeLegalDocuments(kybStore.currentNode.id)
                .catch((err) => {
                  console.error(err)
                })
            }
            back()
          },
          title: 'Complete',
          disabled: !canComplete,
          hide: disabled
        }}
      />
    </>
  )
}

export default observer(KYBLegalDocumentsTypes)
