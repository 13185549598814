import { observer } from 'mobx-react'
import { QRCodeSVG } from 'qrcode.react'
import React, { ReactElement, useEffect, useState } from 'react'
import kybStore from '../../../stores/kyb.store'
import VerificationStepType from '../../../enums/VerificationStepType'
import Loader from '../../../components/loader/Loader'
import SmallText from '../../../components/text/SmallText'

interface BeneficialOwnerQrProps {
  stepType: VerificationStepType
}

const BeneficialOwnerQr = ({
  stepType
}: BeneficialOwnerQrProps): ReactElement => {
  const [qrCodeUrl, setQrCodeUrl] = useState('')

  useEffect(() => {
    kybStore.generateQr(stepType).catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (kybStore.currentQrUrl) {
      setQrCodeUrl(kybStore.currentQrUrl)
    }
  }, [kybStore.currentQrUrl])

  return (
    <>
      <div className="bg-white p-md border border-primary rounded-md flex justify-center items-center w-40 aspect-square">
        {qrCodeUrl !== '' && <QRCodeSVG value={qrCodeUrl} />}
        {qrCodeUrl === '' && <Loader color="var(--color-primary)" />}
      </div>
      <SmallText
        className="mt-sm cursor-pointer underline"
        onClick={() => {
          setQrCodeUrl('')
          kybStore.generateQr(stepType).catch((err) => console.log(err))
        }}
      >
        Generate new QR-Code
      </SmallText>
    </>
  )
}

export default observer(BeneficialOwnerQr)
