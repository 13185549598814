/* eslint-disable multiline-ternary */
import React, { ReactElement, ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import TextInputSkeleton from './TextInputSkeleton'
import 'react-tooltip/dist/react-tooltip.css'
import SmallText from '../text/SmallText'
import { getInputOptions } from '../../util/inputOptionsHelper'
import InputOptionType from '../../enums/InputOptionType'

interface Props {
  variable: string
  optionType?: InputOptionType
  title: string
  type?: string
  className?: string
  loading?: boolean
  disabled?: boolean
  unit?: string
  min?: number
  max?: number
  step?: number
  hideTitle?: boolean
  hidePlaceholder?: boolean
  hideError?: boolean
}

const TextInput: React.FC<Props> = ({
  variable,
  optionType,
  title,
  type = 'text',
  className = '',
  disabled = false,
  loading = false,
  unit,
  min,
  max,
  step,
  hideTitle = false,
  hidePlaceholder = false,
  hideError = false
}: Props): ReactElement => {
  const {
    register,
    clearErrors,
    formState: { errors }
  } = useFormContext()

  let error: any = errors
  variable.split('.').forEach((v) => {
    if (error && error[v]) {
      error = error[v]
    } else {
      error = null
    }
  })

  const options = getInputOptions(optionType)

  if (type === 'number') {
    if (max) {
      options.max = max

      if (error && error.type === 'max') {
        error.message = `Value must be less than or equal to ${max}.`
      }
    }
    if (min) {
      options.min = min

      if (error && error.type === 'max') {
        error.message = `Value must be greater than or equal to ${min}.`
      }
    }
  }

  const loadingStyle = loading ? { marginTop: '-3px' } : {}

  return (
    <div className="relative" style={loadingStyle}>
      {!loading && (
        <div className="relative overflow-hidden">
          {!hideTitle && (
            <SmallText style="secondary" className="mb-1">
              {title}
            </SmallText>
          )}
          <input
            className={`border rounded-md bg-backgroundLight overflow-hidden flex px-2.5  ${
              disabled ? 'opacity-80' : ''
            } focus:border-primary outline-0 h-10 ${
              error != null ? 'border-error focus:border-error' : ''
            } ${className}`}
            disabled={disabled}
            {...register(variable, options)}
            placeholder={!hidePlaceholder ? title : undefined}
            step={type === 'number' && step ? step : undefined}
            type={type}
            autoComplete="none"
            onChange={() => clearErrors(variable)}
            onInvalid={(e) => {
              console.log(e)
            }}
          />
          {unit && (
            <div className="absolute right-0 top-0 rounded-r-md border border-transparent h-full">
              <div className="h-full w-8 bg-gray-200 rounded-r-md flex justify-center items-center font-semibold text-black">
                {unit}
              </div>
            </div>
          )}
        </div>
      )}
      {loading && <TextInputSkeleton />}
      {!hideError &&
        (error != null && error?.type !== 'required' && error.message ? (
          <div className="text-error">{error.message as ReactNode}</div>
        ) : (
          <div className="h-3"></div>
        ))}
    </div>
  )
}

export default TextInput
