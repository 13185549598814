import React, { ReactElement, useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { Controller } from 'react-hook-form'
import ButtonRow, { ButtonProps } from '../../../button/ButtonRow'
import { Content } from '../../../content/Content'
import Text from '../../../text/Text'

interface Props {
  methods: any
  back: any
}

const VerifyUserDetails = ({ methods, back }: Props): ReactElement => {
  const [nextDisabled, setNextDisabled] = useState(true)

  useEffect(() => {
    setNextDisabled(
      !methods.watch('data.code') || methods.watch('data.code').length < 6
    )
  }, [methods.watch('data.code')])

  const renderPrimaryButton = (): ButtonProps => {
    return {
      disabled: nextDisabled,
      title: 'Finish Registration',
      type: 'submit'
    }
  }

  return (
    <Controller
      name={'data.code'}
      control={methods.control}
      rules={{ required: false }}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <>
            <Content>
              <div className="flex flex-col items-center">
                <div className="my-xl">
                  <ReactCodeInput
                    name="code-input"
                    inputMode="latin"
                    value={methods.watch('data.code')}
                    onChange={(value: string) => {
                      onChange(value)
                    }}
                    fields={6}
                  />
                </div>
                <Text className="max-w-md text-center" style="secondary">
                  To complete your registration, please enter the code we just
                  sent you by email.
                </Text>
              </div>
            </Content>
            <ButtonRow
              secondary={{ onClick: back }}
              primary={renderPrimaryButton()}
            />
          </>
        )
      }}
    />
  )
}

export default VerifyUserDetails
