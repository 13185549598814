import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { ReactElement } from 'react'
import H2 from '../../../../components/Header/H2'
import Text from '../../../../components/text/Text'

interface Props {
  title: string
  description?: string
  onClose: () => void
  className?: string
}

const ModalHeader = ({
  title,
  description = '',
  onClose,
  className = ''
}: Props): ReactElement => {
  return (
    <div className={`p-l md:p-xl ${className}`}>
      <header className="w-full flex mr-md">
        <div className="flex-grow">
          <H2>{title}</H2>
          {description && (
            <Text style="secondary" className="mt-1">
              {description}
            </Text>
          )}
        </div>
        <button
          type="button"
          className="rounded-md bg-white h-min first-line border p-1 hover:border-primary"
          onClick={onClose}
        >
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </header>
    </div>
  )
}

export default ModalHeader
