import { AxiosResponse } from 'axios'
import axios from '../services/Axios'

const loadConfig = (
  flowType?: string
): Promise<AxiosResponse<any, any>> | undefined => {
  try {
    if (flowType) {
      return axios.get(`project-config?flowType=${flowType}`)
    }
    return axios.get('project-config')
  } catch (error) {
    console.error(error)
  }
}

export { loadConfig }
