import { observer } from 'mobx-react'
import queryString from 'query-string'
import React, { ReactElement, useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import FilePreview from '../../../../components/filepreview/FilePreview'
import FileUpload from '../../../../components/inputs/FileUpload'
import VerificationStepEntity from '../../../../dto/VerificationStepEntity.interface'
import { DocType } from '../../../../enums/DocType'

import { VerificationStepStatus } from '../../../../enums/VerificationStepStatus'
import CardScrollable from '../../../../components/CardScrollable/CardScrollable'
import userStore from '../../../../stores/user.store'
import { VerificationStore } from '../../../../stores/verificaton.store'
import { checkRedoNavigate } from '../../../../util/navigateHelper'
import User from '../../../../dto/User.interface'
import { uploadRequirementsUtilityBill } from '../../../../data/uploadRequirements'
import { onPromise } from '../../../../util/formHelper'

interface Props {
  verificationStore: VerificationStore
  isDirector?: boolean
}

const ProofOfResidencyDocument = ({
  verificationStore,
  isDirector
}: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)

  const methods: UseFormReturn<any, any> = useForm<VerificationStepEntity>({
    mode: 'onSubmit'
  })
  const navigate = useNavigate()

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true)
      await userStore.getMe().catch((err) => console.log(err))
      await verificationStore
        .loadProofOfResidencyInformation()
        .catch((err) => console.log(err))
      setLoading(false)
    }

    load().catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (
      verificationStore.proofOfResidency &&
      verificationStore.proofOfResidency.status !== VerificationStepStatus.REDO
    ) {
      methods.setValue('proofOfResidency', verificationStore.proofOfResidency)
    }
  }, [verificationStore.proofOfResidency])

  useEffect(() => {
    const values = queryString.parse(location.search)
    methods.setValue('proofOfResidency.documentType', values.docType)
  }, [])

  const uploadInfoText = 'Drag and drop your PDF, JPG images here.'

  const generateLabel = (): string => {
    const values = queryString.parse(location.search)
    const docType = values.docType
    if (!docType) {
      return ''
    }
    switch (docType) {
      case DocType.BANK_STATEMENT:
        return 'Bank Statement'
      case DocType.LANDLINE_BILL:
        return 'Landline Bill'
      case DocType.GAS_BILL:
        return 'Gas Bill'
      case DocType.WATER_BILL:
        return 'Water Bill'
      case DocType.ELECTRICITY_BILL:
        return 'Electricity Bill'
      default:
        return ''
    }
  }

  const onSubmit = async (data: any): Promise<void> => {
    setLoading(true)
    await verificationStore.saveProofOfResidencyInformation(
      data.proofOfResidency
    )
    setLoading(false)
    navigateBack()
  }

  const navigateBack = (): void => {
    checkRedoNavigate(navigate, -2)
  }

  const isAlreadySent = (): boolean => {
    let user: User | undefined
    if (isDirector) {
      if (!userStore.isUserDirector()) {
        return true
      }
      user = userStore.user?.directorApproval.director
    } else {
      user = userStore.user
    }
    if (
      (user?.status &&
        (user.status === VerificationStepStatus.REJECTED ||
          user.status === VerificationStepStatus.COMPLETED)) ??
      user?.verifiedData
    ) {
      return true
    }

    return false
  }
  const isSent = isAlreadySent()
  const imageAlreadyUploaded: string | File = methods.watch(
    'proofOfResidency.documentURL'
  )

  const deleteCurrentProofOfResidence = async (): Promise<void> => {
    setLoading(true)
    await verificationStore
      .delteProofOfResidencyInformation()
      .catch((err) => console.log(err))
    setLoading(false)
    checkRedoNavigate(navigate, -1)
  }

  return (
    <CardScrollable
      title={`Upload ${generateLabel()}`}
      loading={loading}
      secondaryBtn={{
        onClick: () => {
          if (!imageAlreadyUploaded) {
            checkRedoNavigate(navigate, -1)
          } else {
            navigateBack()
          }
        }
      }}
      primaryBtn={{
        title: 'Save',
        hide:
          !imageAlreadyUploaded ||
          typeof imageAlreadyUploaded === 'string' ||
          isSent,
        type: 'submit'
      }}
      formMethods={methods}
      formOnSubmit={onPromise(methods.handleSubmit(onSubmit))}
    >
      <div className="flex flex-col">
        {!imageAlreadyUploaded && (
          <FileUpload
            control={methods.control}
            name="proofOfResidency.documentURL"
            infoText={uploadInfoText}
            requirementsIn={uploadRequirementsUtilityBill}
          />
        )}
        {imageAlreadyUploaded && (
          <FilePreview
            requirementsIn={uploadRequirementsUtilityBill}
            onDelete={
              !isSent
                ? () => {
                    deleteCurrentProofOfResidence().catch((err) =>
                      console.log(err)
                    )
                  }
                : undefined
            }
            file={methods.getValues('proofOfResidency.documentURL')}
          />
        )}
      </div>
    </CardScrollable>
  )
}

export default observer(ProofOfResidencyDocument)
