import React, { ReactElement } from 'react'

const CustomDefaultOption = ({
  data,
  innerProps,
  children
}: any): ReactElement => {
  return (
    <div
      {...innerProps}
      className="items-center p-2 text border-b border-l border-r cursor-pointer hover:text-primary"
    >
      <p className="lg:text-base text-sm">{data.label}</p>
      <p className="lg:text-sm text-xs">{data.description}</p>
    </div>
  )
}

export default CustomDefaultOption
