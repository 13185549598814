import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import VerificationStepEntity from '../../../../dto/VerificationStepEntity.interface'
import { VerificationStepStatus } from '../../../../enums/VerificationStepStatus'
import userStore from '../../../../stores/user.store'
import { VerificationStore } from '../../../../stores/verificaton.store'
import { onPromise } from '../../../../util/formHelper'
import { checkRedoNavigate } from '../../../../util/navigateHelper'
import CardScrollable from '../../../CardScrollable/CardScrollable'
import TextInput from '../../../inputs/TextInput'
import InputOptionType from '../../../../enums/InputOptionType'

interface Props {
  verificationStore: VerificationStore
}

const Wallet = ({ verificationStore }: Props): ReactElement => {
  const [initialLoad, setInitialLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const methods: UseFormReturn<VerificationStepEntity, any> =
    useForm<VerificationStepEntity>({ mode: 'onChange' })
  const navigate = useNavigate()

  useEffect(() => {
    const load = async (): Promise<void> => {
      setInitialLoad(true)
      await userStore.getMe().catch((err) => console.log(err))
      await verificationStore.loadWallet().catch((err) => console.log(err))
      setInitialLoad(false)
    }

    load().catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (verificationStore.wallet) {
      methods.setValue('wallet', verificationStore.wallet)
    }
  }, [verificationStore.wallet])

  const onSubmit: SubmitHandler<VerificationStepEntity> = async (data) => {
    setLoading(true)
    await verificationStore.saveWallet(data)
    setLoading(false)
    navigateBack()
  }
  const navigateBack = (): void => {
    checkRedoNavigate(navigate, -1)
  }

  const isAlreadySent = (): boolean => {
    const currentUser = userStore.user
    if (
      currentUser?.status &&
      (currentUser.status === VerificationStepStatus.REJECTED ||
        currentUser.status === VerificationStepStatus.COMPLETED)
    ) {
      return true
    }

    return false
  }

  const isSent = isAlreadySent()

  return (
    <CardScrollable
      title="Wallet Check"
      loading={loading}
      formMethods={methods}
      formOnSubmit={onPromise(methods.handleSubmit(onSubmit))}
      secondaryBtn={{
        onClick: navigateBack
      }}
      primaryBtn={{
        type: 'submit',
        title: 'Save',
        hide: isSent
      }}
      information="Please enter the wallet address from which the funds will be sent below."
    >
      <TextInput
        variable="wallet.walletAddress"
        title="Wallet Address"
        optionType={InputOptionType.TEXT_AND_NUMBER}
        className="w-full"
        disabled={isSent}
        loading={initialLoad}
      />
    </CardScrollable>
  )
}

export default observer(Wallet)
