import React, { ReactElement } from 'react'

interface Props {
  className?: string
  children?: React.ReactNode
}

const H2 = ({ children, className = '' }: Props): ReactElement => {
  return (
    <h2 className={`text-xl md:text-2xl font-semibold ${className}`}>
      {children}
    </h2>
  )
}

export default H2
