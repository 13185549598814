import React, { ReactElement } from 'react'
import './Container.css'

interface Props {
    children: ReactElement
    className: string
}

const Container: React.FC<Props> = ({ children, className }: Props): ReactElement => {
    return (
        <div className={`container ${className}`}>{children}</div>
    )
}

export default Container
