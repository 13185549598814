import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import VerificationStepType from '../../../../enums/VerificationStepType'

import kybStore from '../../../../stores/kyb.store'

import { Node, UpdateNode } from '../dto/Organigram'
import CompanyInfo from './CompanyInfo'

import KYBLegalDocuments from './KYBLegalDocumentsOverview'
import CompleteNodeBtns from '../nodes/CompleteNodeBtns'
import { Content } from '../../../../components/content/Content'
import ModalHeader from '../Modal/ModalHeader'
import VerificationProcessSteps from '../../../../components/verification-steps/VerificationProcessSteps'
import { canUserComplete } from '../../../../util/userHelper'
import VerificationProcessBar from '../../../../components/verification-steps/VerificationProcessBar'
import { getActiveVerificationSteps } from '../../../../util/stepHelper'
import Loader from '../../../../components/loader/Loader'

interface AddCompanyFlowProps {
  addNode: (node: Node | UpdateNode) => void
  close: () => void
  readonly?: boolean
  initialStep?: VerificationStepType
}

const AddCompanyFlow = ({
  addNode,
  close,
  readonly = false
}: AddCompanyFlowProps): ReactElement => {
  const [currentStep, setCurrentStep] = useState<any>()
  const [canComplete, setCanComplete] = useState<any>()

  useEffect(() => {
    const load = async (): Promise<void> => {
      await kybStore.loadCompanyConfigFromServer()
    }
    load().catch((err) => console.log(err))
  }, [kybStore.treeRoot])

  useEffect(() => {
    setCanComplete(
      canUserComplete({
        user: kybStore.currentNode?.user,
        config: kybStore.configCompany?.verificationStepsConfig
      })
    )
  }, [kybStore.currentNode?.user, kybStore.configCompany])

  const isSent = kybStore.currentNode?.user?.verifiedData

  if (kybStore.nodeLoading) {
    return (
      <div className="flex-grow flex justify-center items-center">
        <Loader color="var(--color-primary)" />
      </div>
    )
  }

  return (
    <>
      <ModalHeader
        title="Corparate"
        description="Add a new beneficial owner that owns shares that might be a sub-coperate or an individual"
        onClose={close}
        className={currentStep ? 'border-b' : ''}
      />
      <div className="h-full flex flex-col justify-between overflow-auto">
        {!currentStep && (
          <>
            <VerificationProcessBar
              activeSteps={getActiveVerificationSteps(
                kybStore.configCompany?.verificationStepsConfig
              )}
              user={kybStore.currentNode?.user}
            />
            <Content>
              <VerificationProcessSteps
                user={kybStore.currentNode?.user}
                config={kybStore.configCompany?.verificationStepsConfig}
                customNavigate={setCurrentStep}
              />
              {!readonly && (
                <CompleteNodeBtns
                  type="company"
                  canComplete={canComplete}
                  node={kybStore.currentNode}
                  isSent={isSent}
                  close={close}
                />
              )}
            </Content>
          </>
        )}
        {currentStep === VerificationStepType.COMPANY_INFORMATION && (
          <CompanyInfo
            isSent={isSent ?? readonly}
            addNode={(node: Node | UpdateNode) => {
              addNode(node)
            }}
            back={() => {
              setCurrentStep(undefined)
            }}
          />
        )}

        {currentStep === VerificationStepType.LEGAL_DOCUMENTS && (
          <KYBLegalDocuments
            isSent={isSent ?? readonly}
            addNode={(node: Node | UpdateNode) => {
              addNode(node)
            }}
            back={(step: VerificationStepType | undefined) => {
              setCurrentStep(step)
            }}
          />
        )}
      </div>
    </>
  )
}

export default observer(AddCompanyFlow)
