import React, { ReactElement, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import VerificationStepEntity from '../../../../../../dto/VerificationStepEntity.interface'
import { occupation } from '../../../../../../data/occupation'
// import DropdownSelected from '../../../../../inputs/Dropdowns/DropdownSelected'
import TextInput from '../../../../../inputs/TextInput'
import InputOptionType from '../../../../../../enums/InputOptionType'
import DropdownSelected from '../../../../../inputs/Dropdowns/DropdownSelected'

interface Props {
  disabled?: boolean
  methods: UseFormReturn<VerificationStepEntity, any>
}

const Occupation = ({ methods, disabled }: Props): ReactElement => {
  const [isOther, setIsOther] = useState(false)

  useEffect(() => {
    const occupation: any = methods.getValues('questionnaire.occupation')
    if (occupation && occupation.value.includes('Other')) {
      setIsOther(true)
    } else {
      setIsOther(false)
    }
  }, [methods.watch('questionnaire.occupation')])

  return (
    <>
      <DropdownSelected
        disabled={disabled}
        variable="questionnaire.occupation"
        options={occupation}
        title="Occupation"
        required={true}
      />
      {isOther && (
        <TextInput
          variable="questionnaire.occupationOther"
          title="Other Occupation"
          optionType={InputOptionType.TEXT}
          className="w-full"
          disabled={disabled}
        />
      )}
    </>
  )
}

export default Occupation
