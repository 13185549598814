import React, { ReactElement } from 'react'
import CustomCountryOption from './Options/CustomCountryOption'
import DropdownSelected, { DropdownProps } from './DropdownSelected'
import CustomCountryControl from './Controls/CustomCountryControl'

interface CountryProps {
  restricted?: string[]
}

type Props = DropdownProps & CountryProps

const CountryDropdownSelected = (props: Props): ReactElement => {
  const CustomOption = ({ data, innerProps, children }: any): ReactElement => {
    return (
      <CustomCountryOption
        data={data}
        innerProps={innerProps}
        restricted={props.restricted}
      >
        {children}
      </CustomCountryOption>
    )
  }

  return (
    <DropdownSelected
      {...props}
      customControl={CustomCountryControl}
      customOption={CustomOption}
    />
  )
}

export default CountryDropdownSelected
