export const totalWealth = [
  { value: '0 - 50\'000', label: '0 - 50\'000' },
  { value: '50\'000 - 100\'000', label: '50\'000 - 100\'000' },
  { value: '100\'000 - 250\'000', label: '100\'000 - 250\'000' },
  { value: '250\'000 - 500\'000', label: '250\'000 - 500\'000' },
  { value: '500\'000 - 1\'000\'000', label: '500\'000 - 1\'000\'000' },
  { value: '1\'000\'000 - 2\'500\'000', label: '1\'000\'000 - 2\'500\'000' },
  { value: '2\'500\'000 - 5\'000\'000', label: '2\'500\'000 - 5\'000\'000' },
  { value: '5\'000\'000 - 10\'000\'000', label: '5\'000\'000 - 10\'000\'000' },
  { value: '10\'000\'000 - 25\'000\'000', label: '10\'000\'000 - 25\'000\'000' },
  { value: '25\'000\'000 - 50\'000\'000', label: '25\'000\'000 - 50\'000\'000' },
  { value: '50\'000\'000 or more', label: '50\'000\'000 or more' }
]
