import { PencilIcon } from '@heroicons/react/24/solid'
import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import Text from '../../../../../text/Text'

interface Props {
  title: string
  path: string
  readonly?: boolean
}

const UserCompletedSectionHeader = ({
  title,
  path,
  readonly = false
}: Props): ReactElement => {
  const navigate = useNavigate()

  return (
    <div className="flex justify-between items-center mt-xl pt-lg border-t mb-md">
      <h3 className="text-l font-semibold text-gray-900">{title}</h3>
      {!readonly && (
        <div
          className="cursor-pointer flex items-center px-3 py-0.5 rounded-full border hover:border-primary text-grayText hover:text-primary"
          onClick={() => {
            navigate(path)
          }}
        >
          <PencilIcon className="w-4 h-4 mr-xs" />
          <Text>Edit</Text>
        </div>
      )}
    </div>
  )
}

export default UserCompletedSectionHeader
