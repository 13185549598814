export const countries = [
  {
    value: 'AF',
    iso3: 'AFG',
    label: 'Afghanistan'
  },
  {
    value: 'AX',
    iso3: 'ALA',
    label: '\u00c5land Islands'
  },
  {
    value: 'AL',
    iso3: 'ALB',
    label: 'Albania'
  },
  {
    value: 'DZ',
    iso3: 'DZA',
    label: 'Algeria'
  },
  {
    value: 'AS',
    iso3: 'ASM',
    label: 'American Samoa'
  },
  {
    value: 'AD',
    iso3: 'AND',
    label: 'Andorra'
  },
  {
    value: 'AO',
    iso3: 'AGO',
    label: 'Angola'
  },
  {
    value: 'AI',
    iso3: 'AIA',
    label: 'Anguilla'
  },
  {
    value: 'AQ',
    iso3: 'ATA',
    label: 'Antarctica'
  },
  {
    value: 'AG',
    iso3: 'ATG',
    label: 'Antigua and Barbuda'
  },
  {
    value: 'AR',
    iso3: 'ARG',
    label: 'Argentina'
  },
  {
    value: 'AM',
    iso3: 'ARM',
    label: 'Armenia'
  },
  {
    value: 'AW',
    iso3: 'ABW',
    label: 'Aruba'
  },
  {
    value: 'AU',
    iso3: 'AUS',
    label: 'Australia'
  },
  {
    value: 'AT',
    iso3: 'AUT',
    label: 'Austria'
  },
  {
    value: 'AZ',
    iso3: 'AZE',
    label: 'Azerbaijan'
  },
  {
    value: 'BS',
    iso3: 'BHS',
    label: 'Bahamas'
  },
  {
    value: 'BH',
    iso3: 'BHS',
    label: 'Bahrain'
  },
  {
    value: 'BD',
    iso3: 'BGD',
    label: 'Bangladesh'
  },
  {
    value: 'BB',
    iso3: 'BRB',
    label: 'Barbados'
  },
  {
    value: 'BY',
    iso3: 'BLR',
    label: 'Belarus'
  },
  {
    value: 'BE',
    iso3: 'BEL',
    label: 'Belgium'
  },
  {
    value: 'BZ',
    iso3: 'BLZ',
    label: 'Belize'
  },
  {
    value: 'BJ',
    iso3: 'BEN',
    label: 'Benin'
  },
  {
    value: 'BM',
    iso3: 'BMU',
    label: 'Bermuda'
  },
  {
    value: 'BT',
    iso3: 'BTN',
    label: 'Bhutan'
  },
  {
    value: 'BO',
    iso3: 'BOL',
    label: 'Bolivia, Plurinational State of'
  },
  {
    value: 'BQ',
    iso3: 'BES',
    label: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    value: 'BA',
    iso3: 'BIH',
    label: 'Bosnia and Herzegovina'
  },
  {
    value: 'BW',
    iso3: 'BWA',
    label: 'Botswana'
  },
  {
    value: 'BV',
    iso3: 'BVT',
    label: 'Bouvet Island'
  },
  {
    value: 'BR',
    iso3: 'BRA',
    label: 'Brazil'
  },
  {
    value: 'IO',
    iso3: 'IOT',
    label: 'British Indian Ocean Territory'
  },
  {
    value: 'BN',
    iso3: 'BRN',
    label: 'Brunei Darussalam'
  },
  {
    value: 'BG',
    iso3: 'BGR',
    label: 'Bulgaria'
  },
  {
    value: 'BF',
    iso3: 'BFA',
    label: 'Burkina Faso'
  },
  {
    value: 'BI',
    iso3: 'BDI',
    label: 'Burundi'
  },
  {
    value: 'KH',
    iso3: 'KHM',
    label: 'Cambodia'
  },
  {
    value: 'CM',
    iso3: 'CMR',
    label: 'Cameroon'
  },
  {
    value: 'CA',
    iso3: 'CAN',
    label: 'Canada'
  },
  {
    value: 'CV',
    iso3: 'CPV',
    label: 'Cape Verde'
  },
  {
    value: 'KY',
    iso3: 'CYM',
    label: 'Cayman Islands'
  },
  {
    value: 'CF',
    iso3: 'CAF',
    label: 'Central African Republic'
  },
  {
    value: 'TD',
    iso3: 'TCD',
    label: 'Chad'
  },
  {
    value: 'CL',
    iso3: 'CHL',
    label: 'Chile'
  },
  {
    value: 'CN',
    iso3: 'CHN',
    label: 'China'
  },
  {
    value: 'CX',
    iso3: 'CXR',
    label: 'Christmas Island'
  },
  {
    value: 'CC',
    iso3: 'CCK',
    label: 'Cocos (Keeling) Islands'
  },
  {
    value: 'CO',
    iso3: 'COL',
    label: 'Colombia'
  },
  {
    value: 'KM',
    iso3: 'COM',
    label: 'Comoros'
  },
  {
    value: 'CG',
    iso3: 'COG',
    label: 'Congo'
  },
  {
    value: 'CD',
    iso3: 'COD',
    label: 'Congo, the Democratic Republic of the'
  },
  {
    value: 'CK',
    iso3: 'COK',
    label: 'Cook Islands'
  },
  {
    value: 'CR',
    iso3: 'CRI',
    label: 'Costa Rica'
  },
  {
    value: 'CI',
    iso3: 'CIV',
    label: "C\u00f4te d'Ivoire"
  },
  {
    value: 'HR',
    iso3: 'HRC',
    label: 'Croatia'
  },
  {
    value: 'CU',
    iso3: 'CUB',
    label: 'Cuba'
  },
  {
    value: 'CW',
    iso3: 'CUW',
    label: 'Cura\u00e7ao'
  },
  {
    value: 'CY',
    iso3: 'CYO',
    label: 'Cyprus'
  },
  {
    value: 'CZ',
    iso3: 'CZE',
    label: 'Czech Republic'
  },
  {
    value: 'DK',
    iso3: 'DNK',
    label: 'Denmark'
  },
  {
    value: 'DJ',
    iso3: 'DJI',
    label: 'Djibouti'
  },
  {
    value: 'DM',
    iso3: 'DMA',
    label: 'Dominica'
  },
  {
    value: 'DO',
    iso3: 'DOM',
    label: 'Dominican Republic'
  },
  {
    value: 'EC',
    iso3: 'DOM',
    label: 'Ecuador'
  },
  {
    value: 'EG',
    iso3: 'EGY',
    label: 'Egypt'
  },
  {
    value: 'SV',
    iso3: 'SLV',
    label: 'El Salvador'
  },
  {
    value: 'GQ',
    iso3: 'GNQ',
    label: 'Equatorial Guinea'
  },
  {
    value: 'ER',
    iso3: 'ERI',
    label: 'Eritrea'
  },
  {
    value: 'EE',
    iso3: 'EST',
    label: 'Estonia'
  },
  {
    value: 'ET',
    iso3: 'ETH',
    label: 'Ethiopia'
  },
  {
    value: 'FK',
    iso3: 'FLK',
    label: 'Falkland Islands (Malvinas)'
  },
  {
    value: 'FO',
    iso3: 'FRO',
    label: 'Faroe Islands'
  },
  {
    value: 'FJ',
    iso3: 'FJI',
    label: 'Fiji'
  },
  {
    value: 'FI',
    iso3: 'FIN',
    label: 'Finland'
  },
  {
    value: 'FR',
    iso3: 'FRA',
    label: 'France'
  },
  {
    value: 'GF',
    iso3: 'GUF',
    label: 'French Guiana'
  },
  {
    value: 'PF',
    iso3: 'PYF',
    label: 'French Polynesia'
  },
  {
    value: 'TF',
    iso3: 'ATF',
    label: 'French Southern Territories'
  },
  {
    value: 'GA',
    iso3: 'GAB',
    label: 'Gabon'
  },
  {
    value: 'GM',
    iso3: 'GMB',
    label: 'Gambia'
  },
  {
    value: 'GE',
    iso3: 'GEO',
    label: 'Georgia'
  },
  {
    value: 'DE',
    iso3: 'DEU',
    label: 'Germany'
  },
  {
    value: 'GH',
    iso3: 'GHA',
    label: 'Ghana'
  },
  {
    value: 'GI',
    iso3: 'GIB',
    label: 'Gibraltar'
  },
  {
    value: 'GR',
    iso3: 'GRC',
    label: 'Greece'
  },
  {
    value: 'GL',
    iso3: 'GRL',
    label: 'Greenland'
  },
  {
    value: 'GD',
    iso3: 'GRD',
    label: 'Grenada'
  },
  {
    value: 'GP',
    iso3: 'GLP',
    label: 'Guadeloupe'
  },
  {
    value: 'GU',
    iso3: 'GUM',
    label: 'Guam'
  },
  {
    value: 'GT',
    iso3: 'GTM',
    label: 'Guatemala'
  },
  {
    value: 'GG',
    iso3: 'GGY',
    label: 'Guernsey'
  },
  {
    value: 'GN',
    iso3: 'GIN',
    label: 'Guinea'
  },
  {
    value: 'GW',
    iso3: 'GNB',
    label: 'Guinea-Bissau'
  },
  {
    value: 'GY',
    iso3: 'GUY',
    label: 'Guyana'
  },
  {
    value: 'HT',
    iso3: 'HTI',
    label: 'Haiti'
  },
  {
    value: 'HM',
    iso3: 'HMD',
    label: 'Heard Island and McDonald Islands'
  },
  {
    value: 'VA',
    iso3: 'VAT',
    label: 'Holy See (Vatican City State)'
  },
  {
    value: 'HN',
    iso3: 'HND',
    label: 'Honduras'
  },
  {
    value: 'HK',
    iso3: 'HKG',
    label: 'Hong Kong'
  },
  {
    value: 'HU',
    iso3: 'HUN',
    label: 'Hungary'
  },
  {
    value: 'IS',
    iso3: 'ISL',
    label: 'Iceland'
  },
  {
    value: 'IN',
    iso3: 'IND',
    label: 'India'
  },
  {
    value: 'ID',
    iso3: 'IDN',
    label: 'Indonesia'
  },
  {
    value: 'IR',
    iso3: 'IRN',
    label: 'Iran, Islamic Republic of'
  },
  {
    value: 'IQ',
    iso3: 'IRQ',
    label: 'Iraq'
  },
  {
    value: 'IE',
    iso3: 'IRL',
    label: 'Ireland'
  },
  {
    value: 'IM',
    iso3: 'IMN',
    label: 'Isle of Man'
  },
  {
    value: 'IL',
    iso3: 'ISR',
    label: 'Israel'
  },
  {
    value: 'IT',
    iso3: 'ITA',
    label: 'Italy'
  },
  {
    value: 'JM',
    iso3: 'JAM',
    label: 'Jamaica'
  },
  {
    value: 'JP',
    iso3: 'JPN',
    label: 'Japan'
  },
  {
    value: 'JE',
    iso3: 'JEY',
    label: 'Jersey'
  },
  {
    value: 'JO',
    iso3: 'JOR',
    label: 'Jordan'
  },
  {
    value: 'KZ',
    iso3: 'KAZ',
    label: 'Kazakhstan'
  },
  {
    value: 'KE',
    iso3: 'KEN',
    label: 'Kenya'
  },
  {
    value: 'KI',
    iso3: 'KIR',
    label: 'Kiribati'
  },
  {
    value: 'KP',
    iso3: 'PRK',
    label: "Korea, Democratic People's Republic of"
  },
  {
    value: 'KR',
    iso3: 'KOR',
    label: 'Korea, Republic of'
  },
  {
    value: 'KW',
    iso3: 'KWT',
    label: 'Kuwait'
  },
  {
    value: 'KG',
    iso3: 'KWT',
    label: 'Kyrgyzstan'
  },
  {
    value: 'LA',
    iso3: 'LAO',
    label: "Lao People's Democratic Republic"
  },
  {
    value: 'LV',
    iso3: 'LVA',
    label: 'Latvia'
  },
  {
    value: 'LB',
    iso3: 'LBN',
    label: 'Lebanon'
  },
  {
    value: 'LS',
    iso3: 'LSO',
    label: 'Lesotho'
  },
  {
    value: 'LR',
    iso3: 'LBR',
    label: 'Liberia'
  },
  {
    value: 'LY',
    iso3: 'LBY',
    label: 'Libya'
  },
  {
    value: 'LI',
    iso3: 'LIE',
    label: 'Liechtenstein'
  },
  {
    value: 'LT',
    iso3: 'LTU',
    label: 'Lithuania'
  },
  {
    value: 'LU',
    iso3: 'LUX',
    label: 'Luxembourg'
  },
  {
    value: 'MO',
    iso3: 'MAC',
    label: 'Macao'
  },
  {
    value: 'MG',
    iso3: 'MDG',
    label: 'Madagascar'
  },
  {
    value: 'MW',
    iso3: 'MWI',
    label: 'Malawi'
  },
  {
    value: 'MY',
    iso3: 'MYS',
    label: 'Malaysia'
  },
  {
    value: 'MV',
    iso3: 'MDV',
    label: 'Maldives'
  },
  {
    value: 'ML',
    iso3: 'MLI',
    label: 'Mali'
  },
  {
    value: 'MT',
    iso3: 'MLT',
    label: 'Malta'
  },
  {
    value: 'MH',
    iso3: 'MHL',
    label: 'Marshall Islands'
  },
  {
    value: 'MQ',
    iso3: 'MTQ',
    label: 'Martinique'
  },
  {
    value: 'MR',
    iso3: 'MRT',
    label: 'Mauritania'
  },
  {
    value: 'MU',
    iso3: 'MUS',
    label: 'Mauritius'
  },
  {
    value: 'YT',
    iso3: 'MYT',
    label: 'Mayotte'
  },
  {
    value: 'MX',
    iso3: 'MYT',
    label: 'Mexico'
  },
  {
    value: 'FM',
    iso3: 'FSM',
    label: 'Micronesia, Federated States of'
  },
  {
    value: 'MD',
    iso3: 'MDA',
    label: 'Moldova, Republic of'
  },
  {
    value: 'MC',
    iso3: 'MCO',
    label: 'Monaco'
  },
  {
    value: 'MN',
    iso3: 'MNG',
    label: 'Mongolia'
  },
  {
    value: 'ME',
    iso3: 'MNE',
    label: 'Montenegro'
  },
  {
    value: 'MS',
    iso3: 'MSR',
    label: 'Montserrat'
  },
  {
    value: 'MA',
    iso3: 'MAR',
    label: 'Morocco'
  },
  {
    value: 'MZ',
    iso3: 'MOZ',
    label: 'Mozambique'
  },
  {
    value: 'MM',
    iso3: 'MMR',
    label: 'Myanmar'
  },
  {
    value: 'NA',
    iso3: 'NAM',
    label: 'Namibia'
  },
  {
    value: 'NR',
    iso3: 'NRU',
    label: 'Nauru'
  },
  {
    value: 'NP',
    iso3: 'NPL',
    label: 'Nepal'
  },
  {
    value: 'NL',
    iso3: 'NLD',
    label: 'Netherlands'
  },
  {
    value: 'NC',
    iso3: 'NCL',
    label: 'New Caledonia'
  },
  {
    value: 'NZ',
    iso3: 'NZL',
    label: 'New Zealand'
  },
  {
    value: 'NI',
    iso3: 'NIC',
    label: 'Nicaragua'
  },
  {
    value: 'NE',
    iso3: 'NER',
    label: 'Niger'
  },
  {
    value: 'NG',
    iso3: 'NGA',
    label: 'Nigeria'
  },
  {
    value: 'NU',
    iso3: 'NIU',
    label: 'Niue'
  },
  {
    value: 'NF',
    iso3: 'NFK',
    label: 'Norfolk Island'
  },
  {
    value: 'MP',
    iso3: 'MNP',
    label: 'Northern Mariana Islands'
  },
  {
    value: 'NO',
    iso3: 'NOR',
    label: 'Norway'
  },
  {
    value: 'OM',
    iso3: 'OMN',
    label: 'Oman'
  },
  {
    value: 'PK',
    iso3: 'PAK',
    label: 'Pakistan'
  },
  {
    value: 'PW',
    iso3: 'PLW',
    label: 'Palau'
  },
  {
    value: 'PS',
    iso3: 'PSE',
    label: 'Palestine, State of'
  },
  {
    value: 'PA',
    iso3: 'PAN',
    label: 'Panama'
  },
  {
    value: 'PG',
    iso3: 'PNG',
    label: 'Papua New Guinea'
  },
  {
    value: 'PY',
    iso3: 'PRY',
    label: 'Paraguay'
  },
  {
    value: 'PE',
    iso3: 'PER',
    label: 'Peru'
  },
  {
    value: 'PH',
    iso3: 'PHL',
    label: 'Philippines'
  },
  {
    value: 'PN',
    iso3: 'PCN',
    label: 'Pitcairn'
  },
  {
    value: 'PL',
    iso3: 'POL',
    label: 'Poland'
  },
  {
    value: 'PT',
    iso3: 'PRT',
    label: 'Portugal'
  },
  {
    value: 'PR',
    iso3: 'PRI',
    label: 'Puerto Rico'
  },
  {
    value: 'QA',
    iso3: 'QAT',
    label: 'Qatar'
  },
  {
    value: 'RE',
    iso3: 'REU',
    label: 'R\u00e9union'
  },
  {
    value: 'RO',
    iso3: 'ROU',
    label: 'Romania'
  },
  {
    value: 'RU',
    iso3: 'RUS',
    label: 'Russian Federation'
  },
  {
    value: 'RW',
    iso3: 'RWA',
    label: 'Rwanda'
  },
  {
    value: 'BL',
    iso3: 'BLM',
    label: 'Saint Barth\u00e9lemy'
  },
  {
    value: 'SH',
    iso3: 'SHN',
    label: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    value: 'KN',
    iso3: 'KNA',
    label: 'Saint Kitts and Nevis'
  },
  {
    value: 'LC',
    iso3: 'LCA',
    label: 'Saint Lucia'
  },
  {
    value: 'MF',
    iso3: 'MAF',
    label: 'Saint Martin (French part)'
  },
  {
    value: 'PM',
    iso3: 'SPM',
    label: 'Saint Pierre and Miquelon'
  },
  {
    value: 'VC',
    iso3: 'VCT',
    label: 'Saint Vincent and the Grenadines'
  },
  {
    value: 'WS',
    iso3: 'WSM',
    label: 'Samoa'
  },
  {
    value: 'SM',
    iso3: 'SMR',
    label: 'San Marino'
  },
  {
    value: 'ST',
    iso3: 'STP',
    label: 'Sao Tome and Principe'
  },
  {
    value: 'SA',
    iso3: 'SAU',
    label: 'Saudi Arabia'
  },
  {
    value: 'SN',
    iso3: 'SEN',
    label: 'Senegal'
  },
  {
    value: 'RS',
    iso3: 'SRB',
    label: 'Serbia'
  },
  {
    value: 'SC',
    iso3: 'SYC',
    label: 'Seychelles'
  },
  {
    value: 'SL',
    iso3: 'SLE',
    label: 'Sierra Leone'
  },
  {
    value: 'SG',
    iso3: 'SGP',
    label: 'Singapore'
  },
  {
    value: 'SX',
    iso3: 'SXM',
    label: 'Sint Maarten (Dutch part)'
  },
  {
    value: 'SK',
    iso3: 'SVK',
    label: 'Slovakia'
  },
  {
    value: 'SI',
    iso3: 'SVN',
    label: 'Slovenia'
  },
  {
    value: 'SB',
    iso3: 'SLB',
    label: 'Solomon Islands'
  },
  {
    value: 'SO',
    iso3: 'SOM',
    label: 'Somalia'
  },
  {
    value: 'ZA',
    iso3: 'ZAF',
    label: 'South Africa'
  },
  {
    value: 'GS',
    iso3: 'SGS',
    label: 'South Georgia and the South Sandwich Islands'
  },
  {
    value: 'SS',
    iso3: 'SSD',
    label: 'South Sudan'
  },
  {
    value: 'ES',
    iso3: 'ESP',
    label: 'Spain'
  },
  {
    value: 'LK',
    iso3: 'LKA',
    label: 'Sri Lanka'
  },
  {
    value: 'SD',
    iso3: 'SDN',
    label: 'Sudan'
  },
  {
    value: 'SR',
    iso3: 'SUR',
    label: 'Surilabel'
  },
  {
    value: 'SJ',
    iso3: 'SJM',
    label: 'Svalbard and Jan Mayen'
  },
  {
    value: 'SE',
    iso3: 'SWE',
    label: 'Sweden'
  },
  {
    value: 'CH',
    iso3: 'CHE',
    label: 'Switzerland'
  },
  {
    value: 'SY',
    iso3: 'SYR',
    label: 'Syrian Arab Republic'
  },
  {
    value: 'TW',
    iso3: 'TWN',
    label: 'Taiwan, Province of China'
  },
  {
    value: 'TJ',
    iso3: 'TJK',
    label: 'Tajikistan'
  },
  {
    value: 'TZ',
    iso3: 'TZA',
    label: 'Tanzania, United Republic of'
  },
  {
    value: 'TH',
    iso3: 'THA',
    label: 'Thailand'
  },
  {
    value: 'TL',
    iso3: 'TLS',
    label: 'Timor-Leste'
  },
  {
    value: 'TG',
    iso3: 'TGO',
    label: 'Togo'
  },
  {
    value: 'TK',
    iso3: 'TKL',
    label: 'Tokelau'
  },
  {
    value: 'TO',
    iso3: 'TON',
    label: 'Tonga'
  },
  {
    value: 'TT',
    iso3: 'TTO',
    label: 'Trinidad and Tobago'
  },
  {
    value: 'TN',
    iso3: 'TUN',
    label: 'Tunisia'
  },
  {
    value: 'TR',
    iso3: 'TUR',
    label: 'Turkey'
  },
  {
    value: 'TM',
    iso3: 'TKM',
    label: 'Turkmenistan'
  },
  {
    value: 'TC',
    iso3: 'TCA',
    label: 'Turks and Caicos Islands'
  },
  {
    value: 'TV',
    iso3: 'TUV',
    label: 'Tuvalu'
  },
  {
    value: 'UG',
    iso3: 'UGA',
    label: 'Uganda'
  },
  {
    value: 'UA',
    iso3: 'UKR',
    label: 'Ukraine'
  },
  {
    value: 'AE',
    iso3: 'ARE',
    label: 'United Arab Emirates'
  },
  {
    value: 'GB',
    iso3: 'GBR',
    label: 'United Kingdom'
  },
  {
    value: 'US',
    iso3: 'USA',
    label: 'United States'
  },
  {
    value: 'UM',
    iso3: 'UMI',
    label: 'United States Minor Outlying Islands'
  },
  {
    value: 'UY',
    iso3: 'URY',
    label: 'Uruguay'
  },
  {
    value: 'UZ',
    iso3: 'UZB',
    label: 'Uzbekistan'
  },
  {
    value: 'VU',
    iso3: 'VUT',
    label: 'Vanuatu'
  },
  {
    value: 'VE',
    iso3: 'VEN',
    label: 'Venezuela, Bolivarian Republic of'
  },
  {
    value: 'VN',
    iso3: 'VNM',
    label: 'Viet Nam'
  },
  {
    value: 'VG',
    iso3: 'VGB',
    label: 'Virgin Islands, British'
  },
  {
    value: 'VI',
    iso3: 'VIR',
    label: 'Virgin Islands, U.S.'
  },
  {
    value: 'WF',
    iso3: 'WLF',
    label: 'Wallis and Futuna'
  },
  {
    value: 'EH',
    iso3: 'ESH',
    label: 'Western Sahara'
  },
  {
    value: 'YE',
    iso3: 'YEM',
    label: 'Yemen'
  },
  {
    value: 'ZM',
    iso3: 'ZMB',
    label: 'Zambia'
  },
  {
    value: 'ZW',
    iso3: 'ZWE',
    label: 'Zimbabwe'
  }
]
