/* eslint-disable multiline-ternary */
import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import VerificationStepType from '../../../../enums/VerificationStepType'

import kybStore from '../../../../stores/kyb.store'

import { Node, UpdateNode } from '../dto/Organigram'
import KYBIDAndLiveness from './sub-flows/KYBIDAndLiveness'
import PersonalInfo from './sub-flows/PersonalInfo'
import CompleteNodeBtns from '../nodes/CompleteNodeBtns'
import VerificationProcessSteps from '../../../../components/verification-steps/VerificationProcessSteps'
import ModalHeader from '../Modal/ModalHeader'
import { canUserComplete } from '../../../../util/userHelper'
import { Content } from '../../../../components/content/Content'
import VerificationProcessBar from '../../../../components/verification-steps/VerificationProcessBar'
import { getActiveVerificationSteps } from '../../../../util/stepHelper'
import Loader from '../../../../components/loader/Loader'

interface AddPersonFlowProps {
  addNode: (node: Node | UpdateNode) => void
  close: () => void
  readonly?: boolean
  initialStep?: VerificationStepType
}

const AddPersonFlow = ({
  addNode,
  close,
  readonly = false,
  initialStep
}: AddPersonFlowProps): ReactElement => {
  console.log(initialStep)
  if (
    initialStep &&
    initialStep !== VerificationStepType.PERSONAL_INFO &&
    initialStep !== VerificationStepType.ID_AND_LIVENESS
  ) {
    initialStep = undefined
  }

  const [currentStep, setCurrentStep] = useState<any>(initialStep)
  const [canComplete, setCanComplete] = useState<any>()

  useEffect(() => {
    const load = async (): Promise<void> => {
      await kybStore.loadConfigFromServer()
    }
    load().catch((err) => console.log(err))
  }, [kybStore.treeRoot])

  useEffect(() => {
    setCanComplete(
      canUserComplete({
        user: kybStore.currentNode?.user,
        config: kybStore.configUser?.verificationStepsConfig
      })
    )
  }, [kybStore.currentNode?.user, kybStore.configUser])

  const isSent = kybStore.currentNode?.user?.verifiedData

  if (kybStore.nodeLoading) {
    return (
      <div className="flex-grow flex justify-center items-center">
        <Loader color="var(--color-primary)" />
      </div>
    )
  }

  return (
    <>
      <ModalHeader
        title="Natural Person"
        description="Add a new beneficial owner that owns shares that might be a sub-coperate or an individual"
        onClose={close}
        className={currentStep ? 'border-b' : ''}
      />
      <div className="h-full flex flex-col justify-between overflow-auto">
        {!currentStep && (
          <>
            <VerificationProcessBar
              activeSteps={getActiveVerificationSteps(
                kybStore.configUser?.verificationStepsConfig
              )}
              user={kybStore.currentNode?.user}
            />
            <Content>
              <VerificationProcessSteps
                user={kybStore.currentNode?.user}
                config={kybStore.configUser?.verificationStepsConfig}
                customNavigate={setCurrentStep}
                customStepName={(step) => {
                  if (step === VerificationStepType.ID_AND_LIVENESS) {
                    return 'ID Verification'
                  }
                }}
              />
              {!readonly && (
                <CompleteNodeBtns
                  type="person"
                  canComplete={canComplete}
                  node={kybStore.currentNode}
                  isSent={isSent}
                  close={close}
                />
              )}
            </Content>
          </>
        )}
        {currentStep === VerificationStepType.PERSONAL_INFO && (
          <PersonalInfo
            addNode={(node) => {
              addNode(node)
            }}
            isSent={isSent ?? readonly}
            back={() => {
              setCurrentStep(undefined)
            }}
          />
        )}
        {currentStep === VerificationStepType.ID_AND_LIVENESS && (
          <KYBIDAndLiveness
            addNode={(node) => {
              addNode(node)
            }}
            back={() => {
              setCurrentStep(undefined)
            }}
          />
        )}
      </div>
    </>
  )
}

export default observer(AddPersonFlow)
