/* eslint-disable multiline-ternary */
import { observer } from 'mobx-react'
import React, { ReactElement } from 'react'
import configStore from '../../stores/config.store'
import CardHeader from '../card-header/CardHeader'
import Card from '../card/Card'
import Loader from '../loader/Loader'
import Information from '../information/Information'
import ButtonRow, { ButtonProps } from '../button/ButtonRow'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { Content } from '../content/Content'
import VerificationProcessBar, {
  VerificationProcessProps
} from '../verification-steps/VerificationProcessBar'

interface Props {
  title: string
  description?: string
  information?: string
  children: React.ReactNode
  loading?: boolean
  logoutVisible?: boolean
  containerClassName?: string
  secondaryBtn?: ButtonProps
  primaryBtn?: ButtonProps
  formMethods?: UseFormReturn<any, any>
  formOnSubmit?: (data: any) => void
  verificationProcess?: VerificationProcessProps
}

const CardScrollable = ({
  title,
  description,
  children,
  loading,
  information,
  logoutVisible,
  containerClassName = '',
  primaryBtn,
  secondaryBtn,
  formMethods,
  formOnSubmit,
  verificationProcess
}: Props): ReactElement => {
  if (loading) {
    return (
      <Card className="h-full">
        <div className="h-full flex justify-center items-center">
          <Loader color={configStore.config?.primaryColor} />
        </div>
      </Card>
    )
  }

  const renderContent = (): ReactElement => {
    const hasBtnRow = primaryBtn ?? secondaryBtn

    return (
      <div className="flex-grow flex flex-col">
        {verificationProcess && (
          <VerificationProcessBar {...verificationProcess} />
        )}
        {information && (
          <Information
            className="mx-l md:mx-xl mt-md md:mt-lg"
            text={information}
          />
        )}
        <div className={`flex-grow flex flex-col overflow-y-auto ${containerClassName}`}>
          {hasBtnRow ? <Content>{children}</Content> : children}
        </div>
        {hasBtnRow && (
          <ButtonRow primary={primaryBtn} secondary={secondaryBtn} />
        )}
      </div>
    )
  }

  return (
    <Card className="relative">
      <CardHeader
        logoutVisible={logoutVisible}
        heading={title}
        description={description}
        hideBorder={!!verificationProcess}
      />
      <div className="overflow-x-auto h-full flex flex-col">
        {formMethods ? (
          <FormProvider {...formMethods}>
            <form className="flex-grow flex flex-col" onSubmit={formOnSubmit}>
              {renderContent()}
            </form>
          </FormProvider>
        ) : (
          renderContent()
        )}
      </div>
    </Card>
  )
}

export default observer(CardScrollable)
