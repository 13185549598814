import React, { ReactElement } from 'react'

interface Props {
  className?: string
  children?: React.ReactNode
}

const H3 = ({ children, className = '' }: Props): ReactElement => {
  return (
    <h3 className={`text-base md:text-l font-semibold ${className}`}>{children}</h3>
  )
}

export default H3
