/* eslint-disable @typescript-eslint/member-delimiter-style */
import User from '../dto/User.interface'
import { VerificationStepStatus } from '../enums/VerificationStepStatus'

const canUserComplete = (args: { user?: User; config?: any }): boolean => {
  let completed = true

  if (!args.user || !args.config) {
    return false
  }

  for (const stepKey of Object.keys(args.config)) {
    const step = args.config[stepKey]
    if (
      step &&
      typeof step === 'object' &&
      step.isEnabled &&
      stepKey !== 'finish'
    ) {
      if ((args.user as any)[stepKey]) {
        if (
          (args.user as any)[stepKey].status ===
            VerificationStepStatus.STARTED ||
          (args.user as any)[stepKey].status ===
            VerificationStepStatus.NOT_STARTED ||
          (args.user as any)[stepKey].status === VerificationStepStatus.REDO
        ) {
          completed = false
        }
      } else {
        completed = false
      }
    }
  }

  return completed
}

export { canUserComplete }
