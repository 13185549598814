import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 40000,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*'
  }
})

axios.interceptors.response.use(function (response) {
  // when status 2xx
  return response;
}, async function (error) {
  // error handling when status 2xx
  const config = error.config;

  // when 401 Unauthorized
  if (error.response.status === 401 && config.url === '/auth/refresh-access-token') {
    return await Promise.reject(error);
  }
  if (error.response !== undefined && error.response.status === 401 && config.retry === undefined) {
    config.retry = true;
    const refreshToken = await localStorage.getItem('crypto_refresh_token');

    if (refreshToken !== undefined) {
      const body = { refreshToken };

      try {
        const response = await instance.post(
          '/auth/refresh-access-token',
          body
        );

        if (response.status === 201) {
          const token = response.data.accessToken;
          await localStorage.setItem('crypto_token', token);

          if (token !== undefined && instance.defaults !== undefined && instance.defaults.headers !== undefined) {
            instance.defaults.headers.common.Authorization =
              `Bearer ${String(token)}`
          }

          return await instance(config);
        }
      } catch (error) {
        await localStorage.removeItem('crypto_token');
        await localStorage.removeItem('crypto_refresh_token');
        return await Promise.reject(error);
      }
    } else {
      window.location.replace('/');
    }
  }
  return await Promise.reject(error);
}
);

instance.interceptors.request.use(function (config: any) {
  // before request is sent
  const jwtAuth = localStorage.getItem('crypto_token')
  if (jwtAuth) {
    config.headers.Authorization = `Bearer ${jwtAuth}`;
  }
  return config
}, async function (error) {
  // request-error handling
  return await Promise.reject(error)
});

export default instance;
