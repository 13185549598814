import CreateNodeDto from '../dto/CreateNode.dto'
import VerificationStepType from '../enums/VerificationStepType'
import {
  Node,
  UpdateNode
} from '../pages/verificationSteps/beneficalOwnerVerification/dto/Organigram'
import axios from '../services/Axios'
import { generateKeyOfLegalDocumentType } from '../stores/helper/legalDocument.helper'
import { notifyError } from '../util/notifications'
import { getStepSlug } from '../util/stepHelper'

const loadTree = (): Promise<any> | undefined => {
  try {
    return axios.get('company-beneficial-owners/tree')
  } catch (error) {
    console.error(error)
  }
}

const generateBeneficialOwnerCode = (): Promise<any> | undefined => {
  try {
    return axios.post('company-beneficial-owners/code/generate')
  } catch (error) {
    console.error(error)
  }
}

const confirmBeneficialOwnerCode = (body: any): Promise<any> | undefined => {
  return axios.post('company-beneficial-owners/code/confirm', body)
}

const createNode = (createNodeDto: CreateNodeDto): Promise<any> | undefined => {
  try {
    return axios.post('company-beneficial-owners/nodes', createNodeDto)
  } catch (error) {
    console.error(error)
  }
}

const completeBeneficialOwner = (): Promise<any> | undefined => {
  try {
    return axios.put('company-beneficial-owners/complete')
  } catch (error) {
    console.error(error)
  }
}

const verifyNode = (id: string): Promise<any> | undefined => {
  if (id) {
    try {
      return axios.put(`company-beneficial-owners/nodes/verify/${String(id)}`)
    } catch (error) {
      console.error(error)
    }
  }
}

const deleteNode = (id: string): Promise<any> | undefined => {
  if (id) {
    try {
      return axios.delete(`company-beneficial-owners/nodes/${String(id)}`)
    } catch (error) {
      console.error(error)
    }
  }
}

const completeLegalDocuments = (id: string): Promise<any> | undefined => {
  try {
    return axios.put(
      `company-beneficial-owners/nodes/${String(id)}/legal-documents/complete`
    )
  } catch (error) {
    console.error(error)
  }
}

const updateNode = (updateNode: UpdateNode): Promise<any> | undefined => {
  const id = updateNode.id
  const { user } = updateNode
  if (id) {
    if (user && user.legalDocuments) {
      const data = user.legalDocuments
      try {
        const headerConfing = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
        const type = generateKeyOfLegalDocumentType(data.documentType)
        const formData = new FormData()
        formData.append('file', data[type])
        formData.append('documentType', data.documentType)
        if (data.id) {
          formData.append('id', data.id)
        }

        try {
          return axios.post(
            `company-beneficial-owners/nodes/${String(id)}/legal-document`,
            formData,
            headerConfing
          )
        } catch (error) {
          console.error(error)
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        return axios.put(
          `company-beneficial-owners/nodes/${String(id)}`,
          updateNode
        )
      } catch (error) {
        console.error(error)
      }
    }
  } else {
    notifyError('Something went wrong. Please try again.')
  }
}

const generateBeneficialOwnerQrCode = (args: {
  node: Node | undefined
  step: VerificationStepType
}): Promise<any> | undefined => {
  const id = args.node?.id

  const step = getStepSlug(args.step)

  if (id && step) {
    try {
      return axios.get(
        `company-beneficial-owners/nodes/${id}/${step}/qr-code`
      )
    } catch (error) {
      console.error(error)
    }
  }
}

export {
  loadTree,
  generateBeneficialOwnerCode,
  confirmBeneficialOwnerCode,
  completeLegalDocuments,
  createNode,
  completeBeneficialOwner,
  updateNode,
  deleteNode,
  verifyNode,
  generateBeneficialOwnerQrCode
}
