import React, { ReactElement, useEffect, useState } from 'react'
import CardScrollable from '../../../components/CardScrollable/CardScrollable'
import BeneficialOwnerTree from './BeneficalOwnerTree'
import { checkRedoNavigate } from '../../../util/navigateHelper'
import { useNavigate } from 'react-router-dom'
import verificationStore from '../../../stores/verificaton.store'
import userStore from '../../../stores/user.store'
import { observer } from 'mobx-react'
import kybStore from '../../../stores/kyb.store'
import { ButtonProps } from '../../../components/button/ButtonRow'
import FinishBeneficialOwner from './FinishBeneficialOwner'
import {
  confirmBeneficialOwnerCode,
  generateBeneficialOwnerCode
} from '../../../api/benificialOwnerApi'
import { notifyError } from '../../../util/notifications'
import Checkbox from '../../../components/checkbox/Checkbox'
import { UseFormReturn, useForm } from 'react-hook-form'
import { onPromise } from '../../../util/formHelper'

const BeneficalOwnerVerification = (): ReactElement => {
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isDataVerified, setIsDataVerified] = useState(false)
  const [isCodeValid, setCodeValid] = useState(false)
  const navigate = useNavigate()

  const methods: UseFormReturn<any, any> = useForm<any>({ mode: 'onSubmit' })

  useEffect(() => {
    setCodeValid(
      methods.watch('data.code') && methods.watch('data.code').length === 6
    )
  }, [methods.watch('data.code')])

  useEffect(() => {
    setIsDataVerified(!!methods.watch('data.verifiedData'))
  }, [methods.watch('data.verifiedData')])

  const loadInitialData = async (): Promise<void> => {
    setLoading(true)
    await userStore.getMe().catch((err) => console.log(err))

    await verificationStore.loadBeneficialOwner().catch((err) => {
      console.log(err)
    })

    await kybStore.getTree().catch((err) => {
      console.log(err)
    })

    if (!userStore.isUserDirector()) {
      checkRedoNavigate(navigate, -1)
    }

    if (verificationStore.beneficialOwner.verifiedData) {
      navigateFinish()
    }

    setLoading(false)
  }

  const onFormSubmit = async (formData: any): Promise<void> => {
    const { data } = formData
    if (step === 0) {
      if (isDataVerified) {
        await generateCode()
      }
    } else if (step === 1) {
      if (userStore.isUserDirector() && isCodeValid) {
        await confirmCode(data.code)
      }
    }
  }

  const generateCode = async (): Promise<void> => {
    setLoading(true)
    await generateBeneficialOwnerCode()
    setStep(1)
    setLoading(false)
  }

  const confirmCode = async (code: any): Promise<void> => {
    setLoading(true)
    try {
      await confirmBeneficialOwnerCode({ code, verifiedData: true })
      navigateFinish()
    } catch (err) {
      notifyError('Wrong Code')
    }
    setLoading(false)
  }

  useEffect(() => {
    loadInitialData().catch((err) => console.error(err))
  }, [])

  const navigateFinish = (): void => {
    navigate('/finish')
  }

  const getPrimaryButton = (): ButtonProps | undefined => {
    if (step === 0) {
      return {
        disabled: !isDataVerified,
        type: 'submit',
        title: 'Send Verify Code'
      }
    } else if (step === 1) {
      return {
        disabled: !isCodeValid,
        type: 'submit',
        title: 'Confirm'
      }
    }

    return undefined
  }

  const getSecondaryButton = (): ButtonProps | undefined => {
    if (step === 0) {
      return {
        onClick: () => checkRedoNavigate(navigate, -1)
      }
    } else if (step === 1) {
      return {
        onClick: () => setStep(0)
      }
    }

    return undefined
  }

  return (
    <CardScrollable
      title="Verify Beneficial Owner"
      loading={loading}
      secondaryBtn={getSecondaryButton()}
      primaryBtn={getPrimaryButton()}
      // formClassName="h-full flex flex-col"
      // containerClassName='flex-1 flex flex-col'
      formMethods={methods}
      formOnSubmit={onPromise(methods.handleSubmit(onFormSubmit))}
    >
      {step === 0 && (
        <>
          <BeneficialOwnerTree isFinish organization={kybStore.treeRoot} />
          <Checkbox
            className="mt-5"
            variable="data.verifiedData"
            text="I herewith confirm that the ownership structure shown above is accurate and up to date."
          />
        </>
      )}
      {step === 1 && userStore.isUserDirector() && (
        <FinishBeneficialOwner methods={methods} />
      )}
    </CardScrollable>
  )
}

export default observer(BeneficalOwnerVerification)
