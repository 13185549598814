import React, { ReactElement } from 'react'
import VerificationStepType from '../enums/VerificationStepType'
import {
  AcademicCapIcon,
  BuildingOffice2Icon,
  CameraIcon,
  ChartPieIcon,
  DocumentTextIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  WalletIcon
} from '@heroicons/react/24/solid'

const getActiveVerificationSteps = (config?: any): VerificationStepType[] => {
  const activeSteps: VerificationStepType[] = []
  if (!config) return []
  Object.keys(config).forEach((key: any) => {
    if (Object.values(VerificationStepType).includes(key)) {
      const stepType = key as VerificationStepType
      const stepKey = getVerificationStepEntityName(stepType)
      if (stepKey) {
        const step = config[stepKey]

        if (step && Object.keys(step).length > 0 && step.isEnabled) {
          activeSteps.push(stepType)
        }
      }
    }
  })
  return activeSteps
}

const getVerificationStepEntityName = (
  step: VerificationStepType
): string | undefined => {
  switch (step) {
    case VerificationStepType.PERSONAL_INFO:
    case VerificationStepType.ID_AND_LIVENESS:
    case VerificationStepType.PROOF_OF_RESIDENCY:
    case VerificationStepType.QUESTIONNAIRE:
    case VerificationStepType.WALLET:
    case VerificationStepType.COMPANY_INFORMATION:
    case VerificationStepType.DIRECTOR_APPROVAL:
    case VerificationStepType.LEGAL_DOCUMENTS:
    case VerificationStepType.BENEFICIAL_OWNER:
      return step as string
  }
}

const getStepTitle = (step: VerificationStepType | undefined): string => {
  switch (step) {
    case VerificationStepType.PERSONAL_INFO:
      return 'Personal Information'
    case VerificationStepType.ID_AND_LIVENESS:
      return 'ID & Liveness Verification'
    case VerificationStepType.PROOF_OF_RESIDENCY:
      return 'Proof of Address'
    case VerificationStepType.QUESTIONNAIRE:
      return 'Questionnaire'
    case VerificationStepType.WALLET:
      return 'Wallet'
    case VerificationStepType.COMPANY_INFORMATION:
      return 'Company Information'
    case VerificationStepType.DIRECTOR_APPROVAL:
      return 'Director Verification'
    case VerificationStepType.LEGAL_DOCUMENTS:
      return 'Corporate Document Verification'
    case VerificationStepType.BENEFICIAL_OWNER:
      return 'Beneficial Owner Verification'
    default:
      return ''
  }
}

const getStepSlug = (step: VerificationStepType): string => {
  switch (step) {
    case VerificationStepType.PERSONAL_INFO:
      return 'personal-info'
    case VerificationStepType.ID_AND_LIVENESS:
      return 'id-and-liveness'
    case VerificationStepType.PROOF_OF_RESIDENCY:
      return 'proof-of-residency'
    case VerificationStepType.QUESTIONNAIRE:
      return 'questionnaire'
    case VerificationStepType.WALLET:
      return 'wallet'
    case VerificationStepType.COMPANY_INFORMATION:
      return 'company-info'
    case VerificationStepType.DIRECTOR_APPROVAL:
      return 'officer-verification'
    case VerificationStepType.LEGAL_DOCUMENTS:
      return 'legal-documents'
    case VerificationStepType.BENEFICIAL_OWNER:
      return 'beneficial-owner'
    default:
      return ''
  }
}

const getVerificationStepTypeBySlug = (
  step: string
): VerificationStepType | undefined => {
  switch (step) {
    case 'personal-info':
      return VerificationStepType.PERSONAL_INFO
    case 'id-and-liveness':
      return VerificationStepType.ID_AND_LIVENESS
    case 'proof-of-residency':
      return VerificationStepType.PROOF_OF_RESIDENCY
    case 'questionnaire':
      return VerificationStepType.QUESTIONNAIRE
    case 'wallet':
      return VerificationStepType.WALLET
    case 'company-info':
      return VerificationStepType.COMPANY_INFORMATION
    case 'officer-verification':
      return VerificationStepType.DIRECTOR_APPROVAL
    case 'legal-documents':
      return VerificationStepType.LEGAL_DOCUMENTS
    case 'beneficial-owner':
      return VerificationStepType.BENEFICIAL_OWNER
  }
}

const getStepIcon = (
  verificationStepType?: VerificationStepType
): ReactElement => {
  switch (verificationStepType) {
    case VerificationStepType.PERSONAL_INFO:
      return <UserIcon />
    case VerificationStepType.ID_AND_LIVENESS:
      return <CameraIcon />
    case VerificationStepType.PROOF_OF_RESIDENCY:
      return <MapPinIcon />
    case VerificationStepType.QUESTIONNAIRE:
      return <QuestionMarkCircleIcon />
    case VerificationStepType.WALLET:
      return <WalletIcon />
    case VerificationStepType.COMPANY_INFORMATION:
      return <BuildingOffice2Icon />
    case VerificationStepType.LEGAL_DOCUMENTS:
      return <DocumentTextIcon />
    case VerificationStepType.DIRECTOR_APPROVAL:
      return <AcademicCapIcon />
    case VerificationStepType.BENEFICIAL_OWNER:
      return <ChartPieIcon />
    default:
      return <></>
  }
}

export {
  getActiveVerificationSteps,
  getVerificationStepEntityName,
  getVerificationStepTypeBySlug,
  getStepTitle,
  getStepSlug,
  getStepIcon
}
