import React, { ReactElement } from 'react'
import { UseFormReturn } from 'react-hook-form'
import VerificationStepEntity from '../../../../../dto/VerificationStepEntity.interface'
import { InvestmentAmount } from '../../../../../enums/InvestmentAmount'
import TextInput from '../../../../inputs/TextInput'
import AnnualIncomeAndTotalWealth from './components/AnnualIncomeAndTotalWealth'
import SourceOfFundsAndWealth from './components/SourceOfFundsAndWealth'
import Branche from './components/Branche'
import Occupation from './components/Occupation'
import InputOptionType from '../../../../../enums/InputOptionType'
interface Props {
  disabled?: boolean
  methods: UseFormReturn<VerificationStepEntity, any>
}

const KycFinancialInformation = ({
  methods,
  disabled
}: Props): ReactElement => {
  const intentedIvestmentAmount = methods.getValues(
    'questionnaire.intendedInvestmentAmount'
  ) as any

  const showAnnualIncome = (): boolean => {
    switch (intentedIvestmentAmount.value) {
      case InvestmentAmount.TILL100000:
      case InvestmentAmount.OVER100000:
        return true
      default:
        return false
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex-grow">
        {methods.getValues('questionnaire.intendedInvestmentAmount') && (
          <SourceOfFundsAndWealth disabled={disabled} methods={methods} />
        )}

        <Occupation methods={methods} disabled={disabled} />
        <Branche methods={methods} disabled={disabled} />

        <TextInput
          variable="questionnaire.nameOfEmployer"
          title="Name of employer"
          optionType={InputOptionType.TEXT}
          className="w-full"
          disabled={disabled}
        />

        {showAnnualIncome() && (
          <AnnualIncomeAndTotalWealth methods={methods} disabled={disabled} />
        )}
      </div>
    </div>
  )
}

export default KycFinancialInformation
