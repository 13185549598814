import React, { ReactElement } from 'react'
import verificationStore from '../../../stores/verificaton.store'
import { observer } from 'mobx-react'
import PersonalInformation from '../../../components/verification-steps/steps/personal-information/PersonalInformation'

const UserPersonalInformation: React.FC = (): ReactElement => {
  return <PersonalInformation verificationStore={verificationStore} />
}

export default observer(UserPersonalInformation)
