import React, { ReactElement } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TextInputSkeleton = (): ReactElement => {
    return (
        <Skeleton borderRadius={'8px'} count={1} className='border rounded-lg text-black bg-white focus:border-primary outline-0 h-10 w-full overflow-hidden' />
    )
}

export default TextInputSkeleton
