import React, { ReactElement, useEffect } from 'react'
import IdAndLiveness from '../../../components/verification-steps/steps/idAndLiveness/IdAndLiveness'
import directorVerificationStore from '../../../stores/director-verification.store'
import kybDirectorConfigStore from '../../../stores/kybDirectorConfig.store'
import { observer } from 'mobx-react'

const DirectorIdAndLiveness: React.FC = (): ReactElement => {
  useEffect(() => {
    kybDirectorConfigStore
      .loadConfigFromServer()
      .catch((err) => console.log(err))
  }, [])

  return (
    <IdAndLiveness
      verificationStore={directorVerificationStore}
      verificationStepsConfig={kybDirectorConfigStore.config?.verificationStepsConfig}
    />
  )
}

export default observer(DirectorIdAndLiveness)
