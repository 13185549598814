import { Node } from '../dto/Organigram'

const sumPercentages = (node: Node): number => {
  let sum = 0

  if (!node || !node.children) {
    return sum
  }

  for (const child of node.children) {
    if (child.user && child.user.companyInfo) {
      sum += parseFloat(child.user?.companyInfo.ownerPercentage)
    }

    if (child.user && child.user.personalInfo) {
      sum += parseFloat(child.user?.personalInfo.ownerPercentage)
    }
  }

  return sum
}

const getParentNode = (node: Node, organization: Node): Node | null => {
  if (organization && organization.children) {
    if (
      organization.children.findIndex((child: Node) => child.id === node.id) > -1
    ) {
      return organization
    }

    for (const child of organization.children) {
      const parent = getParentNode(node, child)

      if (parent) {
        return parent
      }
    }
  }

  return null
}

export { sumPercentages, getParentNode }
