export const annualIncome = [
  { value: '0 - 15\'000', label: '0 - 15\'000' },
  { value: '15\'000 - 30\'000', label: '15\'000 - 30\'000' },
  { value: '30\'000 - 60\'000', label: '30\'000 - 60\'000' },
  { value: '60\'000 - 80\'000', label: '60\'000 - 80\'000' },
  { value: '80\'000 - 100\'000', label: '80\'000 - 100\'000' },
  { value: '100\'000 - 150\'000', label: '100\'000 - 150\'000' },
  { value: '150\'000 - 250\'000', label: '150\'000 - 250\'000' },
  { value: '250\'000 - 500\'000', label: '250\'000 - 500\'000' },
  { value: '500\'000 - 1\'000\'000', label: '500\'000 - 1\'000\'000' },
  { value: '1\'000\'000 - 2\'500\'000', label: '1\'000\'000 - 2\'500\'000' },
  { value: '2\'500\'000 - 5\'000\'000', label: '2\'500\'000 - 5\'000\'000' },
  { value: '5\'000\'000 or more', label: '5\'000\'000 or more' }
]
