import { EnvelopeIcon } from '@heroicons/react/24/solid'
import React, { ReactElement } from 'react'
import CardScrollable from '../../components/CardScrollable/CardScrollable'
import { Content } from '../../components/content/Content'
import H2 from '../../components/Header/H2'
import Text from '../../components/text/Text'

const UserFinishedPage = (): ReactElement => {
  return (
    <CardScrollable title="Login or Register">
      <Content className="justify-center items-center">
        <div className="flex-grow flex flex-col justify-center items-center">
          <div className="bg-primary rounded-full p-5 mb-md">
            <EnvelopeIcon className="w-14 h-14" color="white" />
          </div>
          <H2 className="mb-sm">Your data has been submitted</H2>
          <Text className="max-w-md text-center" style="secondary">
            Your data has been successfully submitted. You will receive an email
            once your application has been validated or if any further action is
            required.
          </Text>
        </div>
      </Content>
    </CardScrollable>
  )
}

export default UserFinishedPage
