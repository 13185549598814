import { observer } from 'mobx-react'
import React, { ReactElement } from 'react'
import configStore from '../stores/config.store'

function CSSInjector (): ReactElement {
  let primaryColor = '0 133 77'
  // let secondaryColor = '13 13 13'
  let backgroundColor = '237 242 239'
  if (configStore && configStore.config) {
    primaryColor = configStore.config.primaryColor
    // secondaryColor = configStore.config.secondaryColor
    backgroundColor = configStore.config.backgroundColor
  }

  const css = `
    @layer base {
        :root {
          /* For rgb(255 115 179 / <alpha-value>) */
          --color-primary: ${primaryColor};
          --color-background: ${backgroundColor};
          --color-background-primary: ${primaryColor}26;
          --color-background-dark: #F3F3F3;
          --color-background-light: #FAFAFA;
          --color-gray-text: #6E6E6E;
        }
      }
    `
  // --color-secondary: ${secondaryColor};

  return (
    <>
      <style>{css}</style>
    </>
  )
}

export default observer(CSSInjector)
