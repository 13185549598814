/* eslint-disable multiline-ternary */
import React, { ReactElement, useState } from 'react'
import { FlowType } from '../../../../dto/User.interface'
import kybStore from '../../../../stores/kyb.store'
import { Node, UpdateNode } from '../dto/Organigram'
import CompanyInformationForm from '../../../../components/verification-steps/steps/company-information/CompanyInformationForm'
import Loader from '../../../../components/loader/Loader'
import configStore from '../../../../stores/config.store'
import BeneficialOwnerPercentageInput from '../BeneficialOwnerPercentageInput'

interface CompanyInfoProps {
  addNode: (node: Node | UpdateNode) => void
  back: () => void
  isSent?: boolean
}

const CompanyInfo = ({
  addNode,
  back,
  isSent = false
}: CompanyInfoProps): ReactElement => {
  const [loading, setLoading] = useState(false)

  const onSave = async (companyInfo: any): Promise<void> => {
    try {
      setLoading(true)
      const id = kybStore.currentNode ? kybStore.currentNode.id : ''
      const userId = kybStore.currentNode ? kybStore.currentNode.user?.id : ''

      addNode({
        id,
        user: {
          id: userId,
          flowType: FlowType.KYB_COMPANY,
          isTermsAndConditionsAccepted: true,
          companyInfo
        }
      })
      setLoading(false)
      back()
    } catch (error) {
      console.error(error)
    }
  }

  return loading ? (
    <div className="mx-auto mt-40">
      <Loader color={configStore.config?.primaryColor} />
    </div>
  ) : (
    <div>
      <CompanyInformationForm
        companyInfo={kybStore.currentNode?.user?.companyInfo}
        isSent={isSent}
        navigateBack={back}
        save={(companyInfo: any) => {
          onSave(companyInfo).catch((err) => console.error(err))
        }}
        additionalFields={
          <BeneficialOwnerPercentageInput
            parentVariable="companyInfo"
            disabled={isSent}
            value={kybStore.currentNode?.user?.companyInfo?.ownerPercentage}
          />
        }
      />
    </div>
  )
}

export default CompanyInfo
