import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CardScrollable from '../../../components/CardScrollable/CardScrollable'
import verificationStore from '../../../stores/verificaton.store'
import { checkRedoNavigate } from '../../../util/navigateHelper'
import CompanyInformationForm from '../../../components/verification-steps/steps/company-information/CompanyInformationForm'

const CompanyInformation = (): ReactElement => {
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState<boolean>(false)

  const isSent = false

  const onSave = async (companyInfo: any): Promise<void> => {
    try {
      setLoading(true)
      await verificationStore.updateCompanyInformation(companyInfo)
      setLoading(false)
      navigate('/verification-process')
    } catch (error) {
      console.error(error)
    }
  }

  const navigateBack = (): void => {
    checkRedoNavigate(navigate, -1)
  }

  useEffect(() => {
    const loadIntialy = async (): Promise<void> => {
      setLoading(true)
      await verificationStore
        .loadCompanyInformation()
        .catch((err) => console.error(err))
      setLoading(false)
    }

    loadIntialy().catch((err) => console.error(err))
  }, [])

  return (
    <CardScrollable title="Company Information" loading={isLoading}>
      <CompanyInformationForm
        companyInfo={verificationStore.companyInfo}
        isSent={isSent}
        navigateBack={navigateBack}
        save={(personalInfo: any) => {
          onSave(personalInfo).catch((err) => console.error(err))
        }}
      />
    </CardScrollable>
  )
}

export default observer(CompanyInformation)
