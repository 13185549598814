import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CardScrollable from '../../components/CardScrollable/CardScrollable'
import Finish from '../../components/verification-steps/steps/finish/Finish'
import kybDirectorConfigStore from '../../stores/kybDirectorConfig.store'
import userStore from '../../stores/user.store'
import verificationStore from '../../stores/verificaton.store'

const DirectorFinishPage: React.FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const loadIntialy = async (): Promise<void> => {
      setIsLoading(true)
      await verificationStore
        .loadDirectorApproval()
        .catch((err) => console.error(err))
      await kybDirectorConfigStore
        .loadConfigFromServer()
        .catch((err) => console.error(err))
      setIsLoading(false)
    }

    loadIntialy().catch((err) => console.error(err))
  }, [])

  if (!kybDirectorConfigStore.config?.verificationStepsConfig) {
    return <></>
  }

  if (!verificationStore.directorApproval?.director) {
    return <></>
  }

  return (
    <CardScrollable title="Finish Officer Registration" loading={isLoading}>
      <Finish
        verificationStepsConfig={
          kybDirectorConfigStore.config.verificationStepsConfig
        }
        isDirector
        parentUrl="/officer-verification"
        user={verificationStore.directorApproval?.director}
        onSubmit={async (data: any) => {
          setIsLoading(true)
          const id = verificationStore.directorApproval?.director.finish?.id
          await userStore
            .finish({
              data: {
                id,
                ...data
              },
              isDirector: true
            })
            .catch((err) => {
              console.error(err)
            })
          navigate('/verification-process')

          setIsLoading(false)
        }}
      />
    </CardScrollable>
  )
}

export default observer(DirectorFinishPage)
