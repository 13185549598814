import React, { ReactElement, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import VerificationStepEntity from '../../../../../../dto/VerificationStepEntity.interface'
import { branches } from '../../../../../../data/branches'
import TextInput from '../../../../../inputs/TextInput'
import InputOptionType from '../../../../../../enums/InputOptionType'
import DropdownSelected from '../../../../../inputs/Dropdowns/DropdownSelected'

interface Props {
  disabled?: boolean
  methods: UseFormReturn<VerificationStepEntity, any>
  title?: string
}

const Branche = ({
  methods,
  disabled,
  title = 'Industry'
}: Props): ReactElement => {
  const [isOther, setIsOther] = useState(false)

  useEffect(() => {
    const branche: any = methods.getValues('questionnaire.branche')
    if (branche && branche.value.includes('Other')) {
      setIsOther(true)
    } else {
      setIsOther(false)
    }
  }, [methods.watch('questionnaire.branche')])

  return (
    <>
      <DropdownSelected
        disabled={disabled}
        variable="questionnaire.branche"
        options={branches}
        title={title}
        required={true}
      />
      {isOther && (
        <TextInput
          variable="questionnaire.brancheOther"
          title="Other branche"
          optionType={InputOptionType.TEXT}
          className="w-full"
          disabled={disabled}
        />
      )}
    </>
  )
}

export default Branche
