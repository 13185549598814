import UserEntity from '../dto/UserEntity.interface'
import UserInformation from '../dto/UserInformation.interface'
import { VerificationStepType } from '../enums/VerificationStepType'
import axios from '../services/Axios'

const saveInformation = (
  data: any,
  userId: string,
  step?: VerificationStepType
): Promise<any> | undefined => {
  try {
    if (step) {
      return axios.put(`/users/${userId}`, { step, ...data })
    } else {
      return axios.put(`/users/${userId}`, data)
    }
  } catch (error) {
    console.error(error)
  }
}

const getInformation = (userId: string): Promise<any> | undefined => {
  try {
    return axios.get(`/users/${userId}`)
  } catch (error) {
    console.error(error)
  }
}

const saveDocument = (file: File): Promise<any> | undefined => {
  const headerConfing = { headers: { 'Content-Type': 'multipart/form-data' } }
  const formData = new FormData()
  formData.append('file', file)
  try {
    return axios.post('/files/upload', formData, headerConfing)
  } catch (error) {
    console.error(error)
  }
}

const saveSteps = (
  userId: string,
  user: UserInformation
): Promise<any> | undefined => {
  try {
    return axios.put(`/users/${userId}`, user)
  } catch (error) {
    console.error(error)
  }
}

const createNewVerificationStep = (
  userId: string,
  user: UserEntity
): Promise<any> | undefined => {
  try {
    return axios.put(`/users/${userId}`, user)
  } catch (error) {
    console.error(error)
  }
}

const finishUser = (args: {
  data: any
  isDirector?: boolean
}): Promise<any> | undefined => {
  const config: any = {}
  if (args?.isDirector) {
    config.headers = { director: true }
  }
  try {
    return axios.put('/users/finish', args.data, config)
  } catch (error) {
    console.error(error)
  }
}

const generateFinishCode = (args?: {
  isDirector?: boolean
}): Promise<any> | undefined => {
  const config: any = {}
  if (args?.isDirector) {
    config.headers = { director: true }
  }
  try {
    return axios.get('/users/generate-finish-code', config)
  } catch (error: any) {
    console.log(error)
  }
}

export {
  saveInformation,
  getInformation,
  saveSteps,
  saveDocument,
  createNewVerificationStep,
  generateFinishCode,
  finishUser
}
