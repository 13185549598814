import React, { ReactElement, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  file: string
  scrolledToBottom?: () => void
  scrolledToTop?: () => void
}

const FilePreviewExtended: React.FC<Props> = ({
  file,
  scrolledToBottom,
  scrolledToTop
}: Props): ReactElement => {
  const [imgError, setImgError] = useState(false)
  const [numPages, setNumPages] = useState(0)

  const handleScroll = (e: any): void => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      if (scrolledToBottom) {
        scrolledToBottom()
      }
    } else {
      if (scrolledToTop) {
        scrolledToTop()
      }
    }
  }
  return (
    <div
      className="bg-gray-100 rounded-lg relative p-2 flex-grow overflow-auto w-full"
      onScroll={handleScroll}
    >
      {imgError && (
        <Document
          file={file}
          onLoadError={(err) => {
            console.log(err)
            console.log('ERROR')
          }}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages)
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              className="w-full"
            />
          ))}
        </Document>
      )}
      {!imgError && (
        <div className="h-60 overflow-auto bg-red-500">
          <img
            alt="file-preview"
            src={file}
            className="rounded-lg border-0 w-full object-contain"
            onError={() => {
              setImgError(true)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default FilePreviewExtended
