/* eslint-disable multiline-ternary */
import React, { ReactElement, useEffect, useState } from 'react'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { resendDirectorVerification } from '../../../api/verificationStepAPI'
import CardScrollable from '../../../components/CardScrollable/CardScrollable'
import Checkbox from '../../../components/checkbox/Checkbox'
import TextInput from '../../../components/inputs/TextInput'
import VerificationProcess from '../../../components/verification-steps/VerificationProcess'
import kybDirectorConfigStore from '../../../stores/kybDirectorConfig.store'
import userStore from '../../../stores/user.store'
import verificationStore from '../../../stores/verificaton.store'
import { onPromise } from '../../../util/formHelper'
import { checkRedoNavigate } from '../../../util/navigateHelper'
import { notifySuccess } from '../../../util/notifications'
import { ButtonProps } from '../../../components/button/ButtonRow'
import InputOptionType from '../../../enums/InputOptionType'

const CoperateOfficerVerification = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true)
  const [directorApproval, setDirectorApproval] = useState<any>(null)
  const [isDirector, setIsDirector] = useState<boolean>(false)
  const methods: UseFormReturn<any, any> = useForm<any>({ mode: 'onSubmit' })
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<any> = async (subData) => {
    const { data } = subData
    if (data.isDirector) {
      if (userStore.user?.email) {
        data.email = userStore.user?.email
      } else {
        return
      }
    }

    const updatedDirectorApproval: any =
      await verificationStore.updateDirectorApproval({
        email: data.email
      })

    setDirectorApproval({
      ...updatedDirectorApproval,
      director: { email: updatedDirectorApproval.email }
    })
  }

  const navBack = (): void => {
    checkRedoNavigate(navigate, -1)
  }

  useEffect(() => {
    const loadIntialy = async (): Promise<void> => {
      setLoading(true)

      await userStore.getMe().catch((err) => console.error(err))

      await verificationStore
        .loadDirectorApproval()
        .catch((err) => console.error(err))

      await kybDirectorConfigStore
        .loadConfigFromServer()
        .catch((err) => console.error(err))

      setLoading(false)

      setDirectorApproval(verificationStore.directorApproval)

      if (!isDirector && userStore.isUserDirector()) {
        setIsDirector(true)
      }
    }

    loadIntialy().catch((err) => console.error(err))
  }, [])

  useEffect(() => {
    if (directorApproval?.director?.email) {
      methods.setValue('data.email', directorApproval.director.email)

      if (!isDirector && userStore.isUserDirector(directorApproval)) {
        setIsDirector(true)
      }
    }
  }, [directorApproval])

  const alradySent = (): boolean => {
    return !!directorApproval?.director?.email
  }

  const getButtons = (): any => {
    if (!alradySent() && !isDirector) {
      const secondaryBtn: ButtonProps = {
        onClick: navBack
      }

      const primaryBtn: ButtonProps = {
        type: 'submit',
        title: methods.getValues('data.isDirector') ? 'Save' : 'Send'
      }

      return { secondaryBtn, primaryBtn }
    }
  }

  const renderContent = (): ReactElement => {
    return (
      <>
        {!alradySent() && !isDirector && (
          <Checkbox
            variable="data.isDirector"
            className="flex-none mb-lg mx-sm"
            text="I am a Director / Authorized Representative of the company."
            required={false}
          />
        )}
        <div
          className={
            alradySent() && !isDirector ? 'p-l md:p-xl pb-0 md:pb-0' : ''
          }
        >
          {!methods.watch('data.isDirector') && !isDirector && (
            <TextInput
              className={'w-full'}
              variable="data.email"
              title="Coperate Officer Email address"
              optionType={InputOptionType.EMAIL}
              disabled={alradySent()}
            />
          )}

          {alradySent() && !isDirector && (
            <a
              className="mb-3 text-primary underline w-fit cursor-pointer"
              onClick={() => {
                resendDirectorVerification()
                  .then(() => {
                    notifySuccess('Invite resent successfully')
                  })
                  .catch((err) => console.log(err))
              }}
            >
              Resend Invite
            </a>
          )}
        </div>

        {kybDirectorConfigStore.config?.verificationStepsConfig &&
          alradySent() && (
            <div className="flex-grow flex flex-col">
              <VerificationProcess
                user={directorApproval.director}
                verificationStepsConfig={
                  kybDirectorConfigStore.config?.verificationStepsConfig
                }
                canEdit={isDirector}
                navBack={() => navigate('/verification-process')}
                navFinish={() => navigate('/officer-verification/finish')}
              />
            </div>
          )}
      </>
    )
  }

  return (
    <CardScrollable
      title="Director Verification"
      loading={loading}
      information={
        !alradySent() &&
        'If you are not a director / authorized representative of the company, please enter the email of the appropriate person below. The person will then receive an email with a link with which the verification can be started.'
      }
      formMethods={methods}
      formOnSubmit={onPromise(methods.handleSubmit(onSubmit))}
      {...getButtons()}
    >
      {renderContent()}
    </CardScrollable>
  )
}

export default CoperateOfficerVerification
