import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import configStore from '../../stores/config.store'
import Image from '../image/Image'

interface Props {
  className?: string
}

const GeneralHeader: React.FC<Props> = ({
  className = ''
}: Props): ReactElement => {
  const navigate = useNavigate()
  if (!configStore.config || !configStore.config.logo) {
    return <></>
  }

  return (
    <div
      className={`flex flex-row ${className}`}
      onClick={() => {
        navigate('/verification-process')
      }}
    >
      {configStore.config.logo && (
        <Image
          scr={configStore.config.logo}
          className="rounded-full border w-14 h-14 md:w-16 md:h-16"
          altText="CompanyLogo"
        />
      )}
    </div>
  )
}

export default GeneralHeader
