import { action, makeObservable, observable } from 'mobx'

import { loadConfig } from '../api/configAPI'
import Config from '../dto/Config.interface'

export class ConfigStore {
  @observable config: Config | undefined

  constructor () {
    makeObservable(this)
  }

  @action
  loadConfigFromServer = async (): Promise<any> => {
    const response = await loadConfig()
    this.config = response?.data
    if (!this.config) {
      return
    }

    if (response?.data.name && this.config) {
      this.config.name = response?.data.name
    } else {
      this.config.name = 'Crypto Advisory'
    }

    document.title = this.config.name
  }
}

const configStore = new ConfigStore()
export default configStore
