/* eslint-disable multiline-ternary */
import React, { ReactElement } from 'react'
import { ReviewUserStepProps } from '../ReviewUserData'
import UserCompletedItem from '../components/Item'
import UserCompletedList from '../components/List'
import UserCompletedSectionHeader from '../components/SectionHeader'
import { FlowType } from '../../../../../../dto/User.interface'

const ReviewQuestionnaire = ({
  user,
  parentUrl,
  readonly = false
}: ReviewUserStepProps): ReactElement => {
  if (!user?.questionnaire) return <></>

  const { questionnaire } = user

  const renderIntendedInvestmentAmount = (): ReactElement => {
    return (
      <>
        <UserCompletedItem header="Intended Investment Amount">
          {questionnaire.intendedInvestmentAmount}
        </UserCompletedItem>
        <UserCompletedItem header="Intended Use of Assets">
          {questionnaire.intendedUseOfAssets.includes('Other')
            ? questionnaire.intendedUseOfAssetsOther
            : questionnaire.intendedUseOfAssets}
        </UserCompletedItem>
      </>
    )
  }

  const renderBranche = (): ReactElement => {
    return (
      <UserCompletedItem header="Industry">
        {questionnaire.branche.includes('Other')
          ? questionnaire.brancheOther
          : questionnaire.branche}
      </UserCompletedItem>
    )
  }

  const renderKyc = (): ReactElement => {
    return (
      <>
        {renderIntendedInvestmentAmount()}
        <UserCompletedItem header="Source of Funds" newLine>
          {questionnaire.sourceOfFunds.includes('Other')
            ? questionnaire.sourceOfFundsOther
            : questionnaire.sourceOfFunds}
        </UserCompletedItem>
        <UserCompletedItem header="Detailed Source of Funds">
          {questionnaire.sourceOfFundsDescription}
        </UserCompletedItem>
        <UserCompletedItem header="Source of Wealth">
          {questionnaire.sourceOfWealth.includes('Other')
            ? questionnaire.sourceOfWealthOther
            : questionnaire.sourceOfWealth}
        </UserCompletedItem>
        <UserCompletedItem header="Detailed Source of Wealth">
          {questionnaire.sourceOfWealthDescription}
        </UserCompletedItem>
        <UserCompletedItem header="Occupation">
          {questionnaire.occupation}
        </UserCompletedItem>
        {renderBranche()}
        {questionnaire.nameOfEmployer !== '' ? (
          <UserCompletedItem header="Name of employer">
            {questionnaire.nameOfEmployer}
          </UserCompletedItem>
        ) : (
          <></>
        )}
        {questionnaire.annualIncome !== '' ? (
          <UserCompletedItem header="Annual Income in USD">
            {questionnaire.annualIncome}
          </UserCompletedItem>
        ) : (
          <></>
        )}
        {questionnaire.totalWealth !== '' ? (
          <UserCompletedItem header="Total wealth in USD">
            {questionnaire.totalWealth}
          </UserCompletedItem>
        ) : (
          <></>
        )}
      </>
    )
  }

  const renderKyb = (): ReactElement => {
    return (
      <>
        {renderIntendedInvestmentAmount()}
        <UserCompletedItem header="Buisness Activity">
          {questionnaire.businessActivity}
        </UserCompletedItem>
        {renderBranche()}
        {questionnaire.website && (
          <UserCompletedItem header="Website">
            {questionnaire.website}
          </UserCompletedItem>
        )}
        <UserCompletedItem header="Source of Founds">
          {questionnaire.companySourceOfFunds}
        </UserCompletedItem>
      </>
    )
  }

  return (
    <>
      <UserCompletedSectionHeader
        title="Questionnaire"
        path={`${parentUrl}/questionnaire`}
        readonly={readonly}
      />
      <UserCompletedList>
        {user.flowType === FlowType.KYC ? renderKyc() : renderKyb()}
      </UserCompletedList>
    </>
  )
}

export default ReviewQuestionnaire
