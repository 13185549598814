import React, { ReactElement } from 'react'
import { UseFormReturn } from 'react-hook-form'
import VerificationStepEntity from '../../../../../dto/VerificationStepEntity.interface'
import TextArea from '../../../../inputs/TextArea'
import Branche from './components/Branche'
import TextInput from '../../../../inputs/TextInput'
import InputOptionType from '../../../../../enums/InputOptionType'

interface Props {
  disabled?: boolean
  methods: UseFormReturn<VerificationStepEntity, any>
}

const KybFinancialInformation = ({
  methods,
  disabled
}: Props): ReactElement => {
  return (
    <div className="flex flex-col">
      <div className="flex-grow">
        <TextArea
          variable="questionnaire.businessActivity"
          title="Please provide a detailed description of the business activity of the company."
          optionType={InputOptionType.TEXT}
          className="w-full"
          disabled={disabled}
        />

        <Branche
          methods={methods}
          disabled={disabled}
          title="Please select an industry, in which the company is operating"
        />

        <TextInput
          variable="questionnaire.website"
          title="Company website (Optional)"
          className="w-full"
          disabled={disabled}
        />

        <TextArea
          variable="questionnaire.companySourceOfFunds"
          title="Please provide a detailed description of the origin of the funds contributed (By whom, where and how the assets were generated)."
          optionType={InputOptionType.TEXT}
          className="w-full"
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default KybFinancialInformation
