/* eslint-disable multiline-ternary */
import React, { ReactElement, useState } from 'react'
import { NodeType } from '../../../../../dto/CreateNode.dto'
import { FlowType } from '../../../../../dto/User.interface'
import kybStore from '../../../../../stores/kyb.store'
import { Node, UpdateNode } from '../../dto/Organigram'
import PersonalInformationForm from '../../../../../components/verification-steps/steps/personal-information/PersonalInformationForm'
import Loader from '../../../../../components/loader/Loader'
import configStore from '../../../../../stores/config.store'
import BeneficialOwnerPercentageInput from '../../BeneficialOwnerPercentageInput'

interface PersonalInfoProps {
  addNode: (node: Node | UpdateNode) => void
  back: () => void
  isSent?: boolean
}

const PersonalInfo = ({
  addNode,
  back,
  isSent = false
}: PersonalInfoProps): ReactElement => {
  const [loading, setLoading] = useState(false)

  const onSave = async (personalInfo: any): Promise<void> => {
    try {
      setLoading(true)
      const id = kybStore.currentNode ? kybStore.currentNode.id : ''
      const userId = kybStore.currentNode ? kybStore.currentNode.user?.id : ''

      let flowType = FlowType.KYB_USER
      if (kybStore.currentNode?.type === NodeType.LEGAL_PERSON) {
        flowType = FlowType.KYB_COMPANY
      }
      addNode({
        id,
        user: {
          id: userId,
          flowType,
          isTermsAndConditionsAccepted: true,
          personalInfo
        }
      })
      setLoading(false)
      back()
    } catch (error) {
      console.error(error)
    }
  }

  return loading ? (
    <div className="mx-auto mt-40">
      <Loader color={configStore.config?.primaryColor} />
    </div>
  ) : (
    <PersonalInformationForm
      personalInfo={kybStore.currentNode?.user?.personalInfo}
      isSent={isSent}
      navigateBack={back}
      save={(personalInfo: any) => {
        onSave(personalInfo).catch((err) => console.error(err))
      }}
      additionalFields={
        <BeneficialOwnerPercentageInput
          parentVariable="personalInfo"
          disabled={isSent}
          value={kybStore.currentNode?.user?.personalInfo?.ownerPercentage}
        />
      }
    />
  )
}

export default PersonalInfo
