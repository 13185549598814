import { observer } from 'mobx-react'
import React, { ReactElement } from 'react'
import PersonalInformation from '../../../components/verification-steps/steps/personal-information/PersonalInformation'
import directorVerificationStore from '../../../stores/director-verification.store'

const DirectorPersonalInformation: React.FC = (): ReactElement => {
  return (
    <PersonalInformation
      verificationStore={directorVerificationStore}
      isDirector
    />
  )
}

export default observer(DirectorPersonalInformation)
