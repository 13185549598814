import React, { ReactElement, useEffect, useState } from 'react'
import CardScrollable from '../../../components/CardScrollable/CardScrollable'
import BeneficialOwnerTree from './BeneficalOwnerTree'
import { checkRedoNavigate } from '../../../util/navigateHelper'
import { useNavigate } from 'react-router-dom'
import verificationStore from '../../../stores/verificaton.store'
import userStore from '../../../stores/user.store'
import kybStore from '../../../stores/kyb.store'
import { Node } from './dto/Organigram'
import { VerificationStepStatus } from '../../../enums/VerificationStepStatus'
import { NodeType } from '../../../dto/CreateNode.dto'
import { sumPercentages } from './helper/percentageHelper'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { getVerificationStepTypeBySlug } from '../../../util/stepHelper'

const BeneficalOwnerVerification = (): ReactElement => {
  const [loading, setLoading] = useState(false)
  const [inititalNode, setInitialNode] = useState('')
  const [inititalStep, setInitialStep] = useState('')
  const navigate = useNavigate()

  const loadInitialData = async (): Promise<void> => {
    setLoading(true)
    await userStore.getMe().catch((err) => console.log(err))

    await verificationStore.loadBeneficialOwner().catch((err) => {
      console.log(err)
    })

    await kybStore.getTree().catch((err) => {
      console.log(err)
    })

    const values = queryString.parse(location.search)

    if (values.node) {
      setInitialNode(values.node as string)

      if (values.stepType) {
        setInitialStep(values.stepType as string)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    loadInitialData().catch((err) => console.error(err))
  }, [])

  const complete = async (): Promise<void> => {
    setLoading(true)
    await kybStore.completeBeneficialOwner()
    await userStore?.getMe()
    setLoading(false)
    checkRedoNavigate(navigate, -1)
  }

  const isAlreadySent = (): boolean => {
    if (
      !userStore.user?.beneficialOwner ||
      userStore.user.beneficialOwner.status === VerificationStepStatus.STARTED
    ) {
      return false
    }

    return true
  }

  const canComplete = (node: Node | undefined): boolean => {
    if (
      !node ||
      (node.type === NodeType.LEGAL_PERSON && sumPercentages(node) < 100)
    ) {
      return false
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        if (!child.user?.verifiedData) {
          return false
        }

        if (!canComplete(child)) {
          return false
        }
      }
    }

    return true
  }

  return (
    <CardScrollable
      title="Beneficial Owner Verification"
      loading={loading}
      secondaryBtn={{
        onClick: () => {
          if (userStore.isRedirected) {
            navigate('/verification-process')
          } else {
            checkRedoNavigate(navigate, -1)
          }
        }
      }}
      primaryBtn={{
        onClick: () => {
          complete().catch((err) => console.log(err))
        },
        title: 'Complete',
        hide: isAlreadySent() || !canComplete(kybStore.treeRoot)
      }}
    >
      <BeneficialOwnerTree
        organization={kybStore.treeRoot}
        isFinish={isAlreadySent()}
        initialNode={inititalNode}
        initialStep={getVerificationStepTypeBySlug(inititalStep)}
      />
    </CardScrollable>
  )
}

export default observer(BeneficalOwnerVerification)
