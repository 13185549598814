import React, { ReactElement } from 'react'
import { VerificationStepStatus } from '../../enums/VerificationStepStatus'
import SmallText from '../text/SmallText'
import CheckIcon from '../../assets/check_icon.svg'

interface Props {
  status: string | undefined
}

const StatusBadge: React.FC<Props> = ({ status }: Props): ReactElement => {
  let style = 'text-grayText font-semibold'
  let statusLabel = 'Not Started'
  let icon

  const classNamesBadge =
    'font-semibold border border-primary px-3 py-0.5 rounded-full'
  switch (status) {
    case VerificationStepStatus.STARTED:
      statusLabel = 'Started'
      style = `text-primary ${classNamesBadge}`
      break
    case VerificationStepStatus.WARNING:
    case VerificationStepStatus.COMPLETED:
    case VerificationStepStatus.IN_REVIEW:
    case VerificationStepStatus.REJECTED:
      statusLabel = 'Done'
      style = `text-white bg-primary ${classNamesBadge}`
      icon = <img src={CheckIcon} alt="done" className="mr-xs h-2" />
  }

  return (
    <SmallText className={`flex flex-row items-center ${style}`}>
      {icon}
      {statusLabel}
    </SmallText>
  )
}

export default StatusBadge
