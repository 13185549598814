import React, { ReactElement, useEffect, useState } from 'react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import FilePreview from '../../../components/filepreview/FilePreview'
import FileUpload from '../../../components/inputs/FileUpload'
import queryString from 'query-string'
import verificationStore from '../../../stores/verificaton.store'
import { observer } from 'mobx-react'
import CardScrollable from '../../../components/CardScrollable/CardScrollable'
import { LegalDocumentType } from '../../../enums/LegalDocumentType'
import { generateKeyOfLegalDocumentType } from '../../../stores/helper/legalDocument.helper'
import { checkRedoNavigate } from '../../../util/navigateHelper'
import { uploadRequirementsLegalDocs } from '../../../data/uploadRequirements'

const LegalDocumentUpload: React.FC = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [hasChanged, setHasChanged] = useState<boolean>(false)

  const values = queryString.parse(location.search)
  const docType = values.docType as LegalDocumentType

  const navBack = (): void => {
    checkRedoNavigate(navigate, -1)
  }

  if (!docType) {
    navBack()
  }

  const key = `documents.${generateKeyOfLegalDocumentType(docType)}`

  const methods: UseFormReturn<any, any> = useForm<any>({
    mode: 'onSubmit'
  })
  const navigate = useNavigate()

  useEffect(() => {
    methods.setValue('documents.documentType', docType)

    if (
      verificationStore.legalDocuments &&
      verificationStore.legalDocuments[key]
    ) {
      methods.setValue(key, verificationStore.legalDocuments[key])
    }
  }, [])

  const uploadInfoText = 'Drag and drop your PDF, JPG images here.'

  const generateLabel = (): string => {
    const values = queryString.parse(location.search)
    const docType = values.docType
    if (!docType) {
      return ''
    }
    switch (docType) {
      case LegalDocumentType.CERTIFICATE_OF_INCORPORATION:
        return 'Certificate Of Incorporation'
      case LegalDocumentType.REGISTER_OF_DIRECTORS:
        return 'Register Of Directors'
      case LegalDocumentType.REGISTER_OF_MEMBERS:
        return 'Register Of Members'
    }
    return ''
  }

  const onSubmit = async (): Promise<void> => {
    setLoading(true)
    await verificationStore.saveLegalDocument(methods.getValues().documents)
    setLoading(false)
    checkRedoNavigate(navigate, -1)
  }
  const imageAlreadyUploaded = methods.watch(key)

  const renderContent = (): ReactElement => {
    return (
      <FormProvider {...methods}>
        <>
          {!imageAlreadyUploaded && (
            <FileUpload
              onChange={() => {
                setHasChanged(true)
              }}
              control={methods.control}
              name={key}
              infoText={uploadInfoText}
              requirementsIn={uploadRequirementsLegalDocs}
            />
          )}
          {imageAlreadyUploaded && (
            <FilePreview
              file={methods.getValues(key)}
              onDelete={() => {
                methods.resetField(key)
              }}
              requirementsIn={uploadRequirementsLegalDocs}
            />
          )}
        </>
      </FormProvider>
    )
  }

  return (
    <CardScrollable
      title={`Upload ${generateLabel()}`}
      loading={loading}
      secondaryBtn={{
        onClick: navBack
      }}
      primaryBtn={{
        title: 'Save',
        onClick: () => {
          onSubmit().catch((err) => console.log(err))
        },
        hide: !(imageAlreadyUploaded && hasChanged)
      }}
    >
      {renderContent()}
    </CardScrollable>
  )
}

export default observer(LegalDocumentUpload)
