import {
  BoltIcon,
  CloudIcon,
  FireIcon,
  LinkIcon,
  BuildingLibraryIcon
} from '@heroicons/react/24/solid'
import { observer } from 'mobx-react'
import React, { ReactElement, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DocType } from '../../../../enums/DocType'
import { VerificationStore } from '../../../../stores/verificaton.store'
import { checkRedoNavigate } from '../../../../util/navigateHelper'
import CardScrollable from '../../../CardScrollable/CardScrollable'
import { IconListButton } from '../../../button/IconListButton'
import { VerificationStepStatus } from '../../../../enums/VerificationStepStatus'

const docTypes = [
  {
    label: 'Bank Statement',
    value: DocType.BANK_STATEMENT,
    icon: <BuildingLibraryIcon />
  },
  {
    label: 'Electricity Bill',
    value: DocType.ELECTRICITY_BILL,
    icon: <BoltIcon />
  },
  {
    label: 'Water Bill',
    value: DocType.WATER_BILL,
    icon: <CloudIcon />
  },
  {
    label: 'Gas Bill',
    value: DocType.GAS_BILL,
    icon: <FireIcon />
  },
  {
    label: 'Landline Bill',
    value: DocType.LANDLINE_BILL,
    icon: <LinkIcon />
  }
]

interface Props {
  verificationStore: VerificationStore
}

const ProofOfResidencyType = ({ verificationStore }: Props): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true)
      verificationStore
        .loadProofOfResidencyInformation()
        .catch((err) => console.log(err))
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
    load().catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (
      verificationStore.proofOfResidency &&
      verificationStore.proofOfResidency.status !== VerificationStepStatus.REDO
    ) {
      const type = verificationStore.proofOfResidency.documentType
      if (type) {
        navigate(
          `${
            verificationStore.isDirector ? '/officer-verification' : ''
          }/proof-of-residency-doc?${type ? `docType=${type}` : ''}`
        )
      }
    }
  }, [verificationStore.proofOfResidency])

  return (
    <CardScrollable
      title="Select Document Type"
      loading={isLoading}
      information="For the address verification, you will need to upload a current utility bill which was issued within the last 3 months. Please make sure that you have one of the below listed types of utility bills at hand which shows your full name, domicile address as well as the name of the issuing party:"
      secondaryBtn={{
        onClick: () => checkRedoNavigate(navigate, -1)
      }}
    >
      <div className="w-full">
        {docTypes.map((docType) => (
          <IconListButton
            className="mb-sm"
            title={docType.label}
            icon={docType.icon}
            key={docType.value}
            id={`btn-${docType.label.split(' ').join('')}`}
            onClick={() =>
              navigate(
                `${
                  verificationStore.isDirector ? '/officer-verification' : ''
                }/proof-of-residency-doc?docType=${docType.value}`
              )
            }
          />
        ))}
      </div>
    </CardScrollable>
  )
}

export default observer(ProofOfResidencyType)
