import { HomeIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import React, { ReactElement } from 'react'
import { IconListButton } from '../../../../components/button/IconListButton'
import { Content } from '../../../../components/content/Content'
import ModalHeader from '../Modal/ModalHeader'

interface Props {
  onChooseCompany: () => void
  onChoosePerson: () => void
  onClose: () => void
}

const FlowDecide = ({
  onChooseCompany,
  onChoosePerson,
  onClose
}: Props): ReactElement => {
  return (
    <>
      <ModalHeader
        title="Add Beneficial Owner"
        description="Add a new beneficial owner that owns shares that might be a sub-coperate or an individual"
        onClose={onClose}
        className='pb-0 md:pb-0'
      />
      <Content>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <IconListButton
            title="Add Corparate"
            icon={<HomeIcon />}
            onClick={onChooseCompany}
            style="big"
            type="add"
          />
          <IconListButton
            title="Add Natural Person"
            icon={<UserCircleIcon />}
            onClick={onChoosePerson}
            style="big"
            type="add"
          />
        </div>
      </Content>
    </>
  )
}

export default FlowDecide
