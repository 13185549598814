import React, { ReactElement } from 'react'
import { ReviewUserStepProps } from '../ReviewUserData'
import UserCompletedSectionHeader from '../components/SectionHeader'
import FilePreview from '../../../../../filepreview/FilePreview'

const ReviewProofOfResidency = ({
  user,
  parentUrl,
  readonly = false
}: ReviewUserStepProps): ReactElement => {
  if (!user?.proofOfResidency) return <></>

  const { proofOfResidency } = user

  return (
    <>
      <UserCompletedSectionHeader
        title="Proof of Address"
        path={`${parentUrl}/proof-of-residency`}
        readonly={readonly}
      />
      <FilePreview file={proofOfResidency.documentURL} requirementsIn={[]} />
    </>
  )
}

export default ReviewProofOfResidency
