import React, { ReactElement } from 'react';

interface Props {
  scr: string
  className?: string
  altText?: string
}

const Image: React.FC<Props> = ({ scr, className = '', altText = '' }: Props): ReactElement => {
  return (
      <img src={scr} alt={altText} className={`object-cover ${className}`} />
  );
}

export default Image;
