import React, { ReactElement } from 'react'

interface Props {
  children?: JSX.Element | JSX.Element[]
}

const UserCompletedList = ({ children }: Props): ReactElement => {
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3"
    >
      {children}
    </ul>
  )
}

export default UserCompletedList
