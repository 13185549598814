import React, { useEffect, useState } from 'react'
import User from '../../dto/User.interface'
import VerificationStepConfigEntity from '../../dto/VerificationStepConfigEntity.interface'
import ButtonRow, { ButtonProps } from '../button/ButtonRow'
import { Content } from '../content/Content'
import { canUserComplete } from '../../util/userHelper'
import VerificationProcessSteps from './VerificationProcessSteps'

interface Props {
  user?: User
  verificationStepsConfig: VerificationStepConfigEntity
  canEdit?: boolean
  canFinish?: boolean
  navBack?: () => void
  navFinish: () => void
  sendFinishLink?: () => void
}

const VerificationProcess = ({
  user,
  verificationStepsConfig,
  canEdit = true,
  canFinish = true,
  navBack,
  navFinish,
  sendFinishLink
}: Props): JSX.Element => {
  const [everythingCompleted, setEverythingCompleted] = useState(false)
  const [readoOnly, setReadOnly] = useState(!canEdit)

  useEffect(() => {
    if (user && user.verifiedData) {
      setReadOnly(true)
    }

    setEverythingCompleted(
      canUserComplete({ user, config: verificationStepsConfig })
    )
  }, [user, verificationStepsConfig])

  const getPrimaryBtn = (): ButtonProps | undefined => {
    if (!readoOnly) {
      if (!everythingCompleted) {
        return {
          title: 'Complete Verification',
          disabled: true,
          onClick: () => {}
        }
      } else {
        if (canFinish) {
          return {
            title: 'Complete Verification',
            onClick: navFinish
          }
        } else if (sendFinishLink) {
          return {
            title: 'Send Verify-Link to Director',
            onClick: sendFinishLink
          }
        }
      }
    }
  }

  return (
    <>
      <Content>
        <VerificationProcessSteps
          user={user}
          config={verificationStepsConfig}
          canEdit={canEdit}
        />
      </Content>
      <ButtonRow secondary={{ onClick: navBack }} primary={getPrimaryBtn()} />
    </>
  )
}

export default VerificationProcess
