import React, { ReactElement } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { annualIncome } from '../../../../../../data/annualIncome'
import { totalWealth } from '../../../../../../data/totalWealth'
import VerificationStepEntity from '../../../../../../dto/VerificationStepEntity.interface'
import DropdownSelected from '../../../../../inputs/Dropdowns/DropdownSelected'

interface Props {
  disabled?: boolean
  methods: UseFormReturn<VerificationStepEntity, any>
}

const AnnualIncomeAndTotalWealth: React.FC<Props> = ({
  methods,
  disabled
}: Props): ReactElement => {
  methods.watch()

  return (
    <>
      <DropdownSelected
        isSearchable={false}
        disabled={disabled}
        variable="questionnaire.annualIncome"
        title="Annual income in USD"
        options={annualIncome}
        required={true}
      />
      <DropdownSelected
        isSearchable={false}
        disabled={disabled}
        variable="questionnaire.totalWealth"
        title="Total wealth in USD"
        options={totalWealth}
        required={true}
      />
    </>
  )
}

export default AnnualIncomeAndTotalWealth
