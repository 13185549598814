import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CardScrollable from '../../../components/CardScrollable/CardScrollable'
import { LegalDocumentType } from '../../../enums/LegalDocumentType'
import { generateKeyOfLegalDocumentType } from '../../../stores/helper/legalDocument.helper'
import verificationStore from '../../../stores/verificaton.store'
import userStore from '../../../stores/user.store'
import { VerificationStepStatus } from '../../../enums/VerificationStepStatus'
import LegalDocumentTypes from './LegalDocumentTypes'

const docTypes = [
  LegalDocumentType.CERTIFICATE_OF_INCORPORATION,
  LegalDocumentType.REGISTER_OF_DIRECTORS,
  LegalDocumentType.REGISTER_OF_MEMBERS
]

const LegalDocumentVerification = (): ReactElement => {
  const [legalDocuments, setLegalDocuments] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    const loadInitialData = async (): Promise<void> => {
      await userStore.getMe().catch((err) => console.log(err))
      await verificationStore.loadLegalDocuments()
    }

    loadInitialData().catch((err) => console.error(err))
  }, [])

  useEffect(() => {
    if (verificationStore.legalDocuments) {
      setLegalDocuments(verificationStore.legalDocuments)
    }
  }, [verificationStore.legalDocuments])

  const canComplete = (): boolean => {
    if (!legalDocuments) return false

    if (legalDocuments.status !== VerificationStepStatus.STARTED) return false

    for (const docType of docTypes) {
      const key = generateKeyOfLegalDocumentType(docType)
      if (!legalDocuments[key]) {
        return false
      }
    }

    return true
  }

  const navBack = (): void => {
    navigate('/verification-process')
  }

  return (
    <CardScrollable
      title="Corporate Document Verification"
      loading={loading}
      secondaryBtn={{
        onClick: navBack
      }}
      primaryBtn={{
        title: 'Complete',
        onClick: () => {
          setLoading(true)
          verificationStore
            .completeLegalDocuments()
            .then(() => {
              setLoading(false)
              navBack()
            })
            .catch((err) => {
              setLoading(false)
              console.error(err)
            })
        },
        hide: !canComplete()
      }}
    >
      <LegalDocumentTypes
        docTypes={docTypes}
        onNext={(docType: LegalDocumentType) => {
          navigate(`/legal-documents/upload?docType=${docType as string}`)
        }}
        legalDocuments={legalDocuments}
      />
    </CardScrollable>
  )
}

export default observer(LegalDocumentVerification)
