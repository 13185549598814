import { action, makeObservable, observable } from 'mobx'

import { loadConfig } from '../api/configAPI'
import Config from '../dto/Config.interface'
import FlowType from '../enums/FlowType'

export class ConfigStore {
  @observable config: Config | undefined

  constructor () {
    makeObservable(this)
  }

  @action
  loadConfigFromServer = async (): Promise<any> => {
    const response = await loadConfig(FlowType.KYB_DIRECTOR)
    this.config = response?.data
  }
}

const kybDirectorConfigStore = new ConfigStore()
export default kybDirectorConfigStore
