import React, { ReactElement } from 'react'
import dayjs from 'dayjs'
import UserCompletedAddress from '../components/Address'
import UserCompletedCountry from '../components/Country'
import UserCompletedItem from '../components/Item'
import UserCompletedList from '../components/List'
import UserCompletedSectionHeader from '../components/SectionHeader'
import { ReviewUserStepProps } from '../ReviewUserData'

const ReviewPersonalInfo = ({
  user,
  parentUrl,
  readonly = false
}: ReviewUserStepProps): ReactElement => {
  if (!user?.personalInfo) return <></>

  const { personalInfo } = user
  const { address } = personalInfo

  return (
    <>
      <UserCompletedSectionHeader
        title="User Information"
        path={`${parentUrl}/personal-info`}
        readonly={readonly}
      />
      <UserCompletedList>
        <UserCompletedItem header="First Name">
          {personalInfo?.firstName}
        </UserCompletedItem>
        <UserCompletedItem header="Middle Name">
          {personalInfo?.middleName ? personalInfo?.middleName : '-'}
        </UserCompletedItem>
        <UserCompletedItem header="Last Name">
          {personalInfo?.lastName}
        </UserCompletedItem>
        <UserCompletedItem header="Date of Birth">
          {dayjs(personalInfo?.dateOfBirth).format('DD.MM.YYYY')}
        </UserCompletedItem>
        <UserCompletedCountry
          header="Nationality"
          country={personalInfo?.nationality}
        />
      </UserCompletedList>
      <UserCompletedAddress address={address} />
    </>
  )
}

export default ReviewPersonalInfo
