import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import VerificationStepEntity from '../../../../dto/VerificationStepEntity.interface'
import { VerificationStore } from '../../../../stores/verificaton.store'
import { onPromise } from '../../../../util/formHelper'
import { checkRedoNavigate } from '../../../../util/navigateHelper'
import { notifyInfo } from '../../../../util/notifications'
import CardScrollable from '../../../CardScrollable/CardScrollable'
import FilePreview from '../../../filepreview/FilePreview'
import FileUpload from '../../../inputs/FileUpload'

interface Props {
  verificationStore: VerificationStore
}

const FundSourceEvidence = ({ verificationStore }: Props): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)
  const [hasChanged, setHasChanged] = useState<boolean>(false)

  const navigate = useNavigate()
  const methods: UseFormReturn<VerificationStepEntity, any> =
    useForm<VerificationStepEntity>({ mode: 'onChange' })

  const uploadInfoText = 'Drag and drop your PDF, JPG images here.'

  const navigateBack = (): void => {
    notifyInfo('The information you have already entered has been saved.')
    checkRedoNavigate(navigate, -1)
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsLoading(true)
    await verificationStore.saveQuestionnaireInformation(data)
    setIsLoading(false)
    checkRedoNavigate(navigate, -2)
  }

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true)
      await verificationStore.loadQuestionnaireInformation().catch((err) => {
        console.log(err)
      })
      setIsLoading(false)
    }
    load().catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (verificationStore.questionnaire) {
      methods.setValue(
        'questionnaire.sourceOfFundsEvidence',
        verificationStore.questionnaire.sourceOfFundsEvidence
      )
    }
  }, [verificationStore.questionnaire])

  const imageAlreadyUploaded = methods.watch(
    'questionnaire.sourceOfFundsEvidence'
  )
  return (
    <CardScrollable
      title="Supporting Document"
      loading={isLoading}
      formMethods={methods}
      formOnSubmit={onPromise(methods.handleSubmit(onSubmit))}
      secondaryBtn={{ onClick: navigateBack }}
      primaryBtn={{
        hide: !(imageAlreadyUploaded && hasChanged),
        disabled: isSubmitDisabled,
        title: 'Save',
        type: 'submit'
      }}
      information="In order to verify the economic background of the assets contributed, please upload relevant supporting documents (e.g. income statement, employment contract, sale/purchase agreement, financial statement, tax return)."
    >
      <div>
        {!methods.watch('questionnaire.sourceOfFundsEvidence') && (
          <FileUpload
            requirementsIn={[]}
            control={methods.control}
            name="questionnaire.sourceOfFundsEvidence"
            infoText={uploadInfoText}
            setIsSubmitDisabled={setIsSubmitDisabled}
            isRequired={true}
            onChange={() => {
              setHasChanged(true)
            }}
          />
        )}
        {methods.watch('questionnaire.sourceOfFundsEvidence') && (
          <FilePreview
            onDelete={() =>
              methods.resetField('questionnaire.sourceOfFundsEvidence')
            }
            requirementsIn={[]}
            file={methods.getValues('questionnaire.sourceOfFundsEvidence')}
          />
        )}
      </div>
    </CardScrollable>
  )
}

export default observer(FundSourceEvidence)
