import VerificationStepEntity from '../dto/VerificationStepEntity.interface'
import axios from '../services/Axios'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { generateKeyOfLegalDocumentType } from '../stores/helper/legalDocument.helper'

const readVerificationSteps = async (
  id: string | undefined
): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  if (id) {
    try {
      return await axios.get(`/verifications/${id}`)
    } catch (error) {
      console.error(error)
    }
  }
}

const getQuestionnaire = async (args: {
  id: string | undefined
  isDirector?: boolean
}): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  try {
    if (args.id) {
      return await axios.get(
        `/questionnaires/${args.id}`,
        getDirectorConfig(args.isDirector)
      )
    }
    return await axios.get(
      '/questionnaires',
      getDirectorConfig(args.isDirector)
    )
  } catch (error) {
    console.error(error)
  }
}

const updateQuestionnaire = async (args: {
  data: VerificationStepEntity
  isDirector?: boolean
}): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  try {
    const headerConfing: any = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    if (args.isDirector) {
      headerConfing.headers.director = true
    }
    const formData = new FormData()
    formData.append('file', args.data.questionnaire?.sourceOfFundsEvidence)

    const questionnaire = args.data.questionnaire as any
    for (const key in questionnaire) {
      if (key === 'sourceOfFundsEvidence') {
        continue
      }
      formData.append(key, questionnaire[key])
    }

    try {
      if (args.data.id === undefined) {
        return await axios.put('questionnaires', formData, headerConfing)
      }
      return await axios.put(
        `/questionnaires/${args.data.id}`,
        formData,
        headerConfing
      )
    } catch (error) {
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

const getPersonalInfromation = async (args: {
  id: string | undefined
  isDirector?: boolean
}): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  if (args.id) {
    return await axios.get(
      `/personal-information-verificationsteps/${args.id}`,
      getDirectorConfig(args.isDirector)
    )
  }
  return await axios.get(
    '/personal-information-verificationsteps',
    getDirectorConfig(args.isDirector)
  )
}

const updatePersonalInformation = async (args: {
  data: VerificationStepEntity
  isDirector?: boolean
}): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  if (args.data.id === undefined) {
    return await axios.put(
      '/personal-information-verificationsteps',
      args.data,
      getDirectorConfig(args.isDirector)
    )
  }

  return await axios.put(
    `/personal-information-verificationsteps/${args.data.id}`,
    args.data,
    getDirectorConfig(args.isDirector)
  )
}

const getCompanyInformation = async (args: {
  id: string | undefined
}): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  if (args.id) {
    return await axios.get(`/company-information${args.id}`)
  }
  return await axios.get('/company-information')
}

const updateCompanyInformation = async (
  data: VerificationStepEntity
): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  if (data.id === undefined) {
    return await axios.put('/company-information', data)
  }

  return await axios.put(`/company-information/${data.id}`, data)
}

const getBeneficialOwner = async (args: {
  id: string | undefined
}): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  if (args.id) {
    return await axios.get(`/company-beneficial-owners/${args.id}`)
  }
  return await axios.get('/company-beneficial-owners')
}

const resendDirectorVerification = async (): Promise<any> => {
  return await axios.post('/company-director/resend-verification')
}

const getDirectorApproval = async (
  id: string | undefined
): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  if (id) {
    return await axios.get(`/company-director/${id}`)
  }
  return await axios.get('/company-director')
}

const updateDirectorApproval = async (
  data: VerificationStepEntity
): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  if (data.id === undefined) {
    return await axios.put('/company-director', data)
  }

  return await axios.put(`/company-director/${data.id}`, data)
}

const updateIdAndLiveness = async (args: {
  data: any
  isDirector?: boolean
}): Promise<Promise<AxiosResponse<any, any>> | undefined> => {
  try {
    return await axios.put(
      '/id-and-liveness',
      args.data,
      getDirectorConfig(args.isDirector)
    )
  } catch (error) {
    console.error(error)
  }
}

const getProofOfResidency = (args: {
  id: string | undefined
  isDirector?: boolean
}): any => {
  if (args.id) {
    return axios.get(
      `/proof-of-residencies/${args.id}`,
      getDirectorConfig(args.isDirector)
    )
  }
  return axios.get('/proof-of-residencies', getDirectorConfig(args.isDirector))
}

const getIdAndLiveness = (args: {
  id: string | undefined
  isDirector?: boolean
}): any => {
  if (args.id) {
    return axios.get(
      `/id-and-liveness/${args.id}`,
      getDirectorConfig(args.isDirector)
    )
  }
  return axios.get('/id-and-liveness', getDirectorConfig(args.isDirector))
}

const updateProofOfResidency = async (args: {
  data: any
  isDirector?: boolean
}): Promise<any> => {
  try {
    const headerConfing: any = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    if (args.isDirector) {
      headerConfing.headers.director = true
    }
    const formData = new FormData()
    formData.append('file', args.data.documentURL)
    if (args.data.id) {
      formData.append('id', args.data.id)
    }
    formData.append('documentType', args.data.documentType)
    try {
      return await axios.post('/proof-of-residencies', formData, headerConfing)
    } catch (error) {
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

const deleteProofOfResidency = async (args: {
  id: string
  isDirector?: boolean
}): Promise<any> => {
  return await axios.delete(
    `/proof-of-residencies/${args.id}`,
    getDirectorConfig(args.isDirector)
  )
}

const loadWallet = async (args: {
  id: string | undefined
  isDirector: boolean
}): Promise<any> => {
  try {
    if (args.id) {
      return await axios.get(
        `/wallets/${args.id}`,
        getDirectorConfig(args.isDirector)
      )
    }
    return await axios.get('/wallets', getDirectorConfig(args.isDirector))
  } catch (error) {
    console.error(error)
  }
}

const saveWallet = async (args: {
  data: any
  isDirector?: boolean
}): Promise<any> => {
  try {
    return await axios.put(
      '/wallets',
      args.data,
      getDirectorConfig(args.isDirector)
    )
  } catch (error) {
    console.error(error)
  }
}

const getFinishInformation = async (): Promise<any> => {
  try {
    return await axios.get('/finish')
  } catch (error) {
    console.error(error)
  }
}

const saveFinishInformation = async (data: any): Promise<any> => {
  try {
    return await axios.put('/finish', data)
  } catch (error) {
    console.error(error)
  }
}

const getLegalDocuments = async (): Promise<any> => {
  try {
    return await axios.get('/company-legal-documents')
  } catch (error) {
    console.error(error)
  }
}

const updateLegalDocument = async (data: any): Promise<any> => {
  try {
    const headerConfing = { headers: { 'Content-Type': 'multipart/form-data' } }
    const type = generateKeyOfLegalDocumentType(data.documentType)
    const formData = new FormData()
    formData.append('file', data[type])
    if (data.id) {
      formData.append('id', data.id)
    }
    formData.append('documentType', data.documentType)
    try {
      return await axios.put('company-legal-documents', formData, headerConfing)
    } catch (error) {
      console.error(error)
    }
  } catch (error) {
    console.error(error)
  }
}

const completeLegalDocuments = async (): Promise<any> => {
  try {
    return await axios.put('company-legal-documents/complete')
  } catch (error) {
    console.error(error)
  }
}

const getDirectorConfig = (isDirector?: boolean): AxiosRequestConfig => {
  if (isDirector) {
    return {
      headers: {
        director: true
      }
    }
  }
  return {}
}

const requestIdAndLivenessQRCode = async (args: {
  isDirector?: boolean
}): Promise<any> => {
  return await axios.get(
    '/id-and-liveness/qr-code',
    getDirectorConfig(args.isDirector)
  )
}

export {
  getCompanyInformation,
  updateCompanyInformation,
  updateQuestionnaire,
  updatePersonalInformation,
  getBeneficialOwner,
  getPersonalInfromation,
  updateIdAndLiveness,
  updateProofOfResidency,
  getProofOfResidency,
  readVerificationSteps,
  getIdAndLiveness,
  getQuestionnaire,
  loadWallet,
  saveWallet,
  getFinishInformation,
  saveFinishInformation,
  updateLegalDocument,
  completeLegalDocuments,
  getLegalDocuments,
  deleteProofOfResidency,
  getDirectorApproval,
  updateDirectorApproval,
  resendDirectorVerification,
  requestIdAndLivenessQRCode
}
