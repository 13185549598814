import React, { ReactElement } from 'react'
import ReactCodeInput from 'react-code-input'
import { Controller, UseFormReturn } from 'react-hook-form'
import Text from '../../../components/text/Text'

interface Props {
  methods: UseFormReturn<any, any>
}

const FinishBeneficialOwner = ({ methods }: Props): ReactElement => {
  return (
    <Controller
      name={'data.code'}
      control={methods.control}
      rules={{ required: false }}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <div className="flex flex-col items-center">
            <div className="my-xl">
              <ReactCodeInput
                name="code-input"
                inputMode="latin"
                value={methods.watch('data.code')}
                onChange={(value: string) => {
                  onChange(value)
                }}
                fields={6}
              />
            </div>
            <Text className="max-w-md text-center" style="secondary">
              We kindly request you to verify your account information. To
              complete the verification process, a unique verification code has
              been sent to your registered email address. Please take a moment
              to check your email, including your spam or junk folder, as the
              verification code email may have been filtered there.
            </Text>
          </div>
        )
      }}
    />
  )
}

export default FinishBeneficialOwner
