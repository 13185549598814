import { observer } from 'mobx-react'
import React, { ReactElement, useState } from 'react'
import AcceptDocument from './AcceptDocument'
import VerifyUserDetails from './VerifyUserDetails'
import VerificationStepConfigEntity from '../../../../dto/VerificationStepConfigEntity.interface'
import { UseFormReturn, useForm, FormProvider } from 'react-hook-form'
import User, { FlowType } from '../../../../dto/User.interface'
import { onPromise } from '../../../../util/formHelper'
import userStore from '../../../../stores/user.store'
import { notifyError } from '../../../../util/notifications'
import ReviewUserData from './UserCompleted/ReviewUserData'
import Loader from '../../../loader/Loader'

interface Props {
  user: User
  verificationStepsConfig: VerificationStepConfigEntity
  parentUrl?: string
  onSubmit?: (data: any) => Promise<void>
  flowType?: FlowType
  isDirector?: boolean
}

const Finish = ({
  user,
  parentUrl = '',
  verificationStepsConfig,
  onSubmit,
  isDirector = false
}: Props): ReactElement => {
  const methods: UseFormReturn<any, any> = useForm<any>({ mode: 'onSubmit' })
  const [step, setStep] = useState(0)
  const [loading, setIsLoading] = useState(false)

  const checkIfDocumentsToAccept = (): boolean => {
    if (verificationStepsConfig.finish) {
      const { documents } = verificationStepsConfig.finish
      return documents.length > 0
    }
    return false
  }

  const onFormSubmit = async (formData: any): Promise<void> => {
    setIsLoading(true)
    const { data } = formData
    const result = []
    for (const key in data) {
      if (!data[key]) {
        return
      }

      const split: any = key.split('-')
      if (key.includes('documentChecked') && verificationStepsConfig.finish) {
        const { documents: finishDocuments } = verificationStepsConfig.finish
        result.push({
          url: finishDocuments[split[1]],
          isAccepted: data[key]
        })
      }
    }

    if (typeof data.verifiedUserData === 'string') {
      data.verifiedUserData = data.verifiedUserData.toLowerCase() === 'true'
    }

    data.acceptedDocuments = result

    if (onSubmit) {
      await onSubmit(data).catch((err) => {
        notifyError(err.response.data.message)
      })
    }
    setIsLoading(false)
  }

  if (loading) {
    return (
      <div className="flex-grow flex justify-center items-center">
        <Loader color="var(--color-primary)" />
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onPromise(methods.handleSubmit(onFormSubmit))}
        className="h-full flex flex-col justify-between"
      >
        {step === 0 && (
          <ReviewUserData
            methods={methods}
            user={user}
            parentUrl={parentUrl}
            verificationStepsConfig={verificationStepsConfig}
            done={() => {
              userStore
                .generateFinishCode({ isDirector })
                .then(() => {
                  if (checkIfDocumentsToAccept()) {
                    setStep(1)
                  } else {
                    setStep(2)
                  }
                })
                .catch((err: any) => notifyError(err.response.data.message))
            }}
          />
        )}
        {step === 1 && (
          <AcceptDocument
            verificationStepsConfig={verificationStepsConfig}
            methods={methods}
            back={() => {
              setStep(0)
            }}
            done={() => {
              setStep(2)
            }}
          />
        )}
        {step === 2 && (
          <VerifyUserDetails
            methods={methods}
            back={() => {
              if (checkIfDocumentsToAccept()) {
                setStep(1)
              } else {
                setStep(0)
              }
            }}
          />
        )}
      </form>
    </FormProvider>
  )
}

export default observer(Finish)
