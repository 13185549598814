import React, { ReactElement } from 'react'

interface Props {
    text: string
    listElements?: string[]
    className?: string
}

const Information = ({ text, listElements = [], className = '' }: Props): ReactElement => {
    return (
        <div className={`relative p-3 rounded-md text-left ${className}`}>
            <div className="absolute bg-yellow-300 opacity-20 top-0 left-0 right-0 bottom-0"></div>
            <p className="text-sm text-gray-600">{text}</p>
            {listElements.map((element, index) => {
                return (
                    <p key={index} className="text-sm text-gray-600 flex items-center">
                        <div className='w-1 h-1 rounded-full bg-black mr-2'></div> {element}
                    </p>
                )
            })}
        </div>
    )
}

export default Information
