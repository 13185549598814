import React, { ReactElement, useEffect, useState } from 'react'
import verificationStore from '../../../../../stores/verificaton.store'
import { CreditCardIcon, IdentificationIcon } from '@heroicons/react/24/solid'
import { LIVENESS_STEP } from '../IdAndLiveness'
import VerificationStepConfigEntity from '../../../../../dto/VerificationStepConfigEntity.interface'

const docTypes = [
  {
    key: 'passports',
    label: 'Passport',
    icon: <IdentificationIcon className="w-4 m-1" />
  },
  {
    key: 'idCards',
    label: 'ID Card',
    icon: <CreditCardIcon className="w-4 m-1" />
  }
]

interface Props {
  setCurrentStep: (step: LIVENESS_STEP) => void
  verificationStepsConfig?: VerificationStepConfigEntity
}

const DocumentSelect = ({
  setCurrentStep,
  verificationStepsConfig
}: Props): ReactElement => {
  const [documentRestriction, setDocumentRestriction] = useState<any>()

  useEffect(() => {
    if (verificationStepsConfig?.idAndLiveness) {
      setDocumentRestriction(
        verificationStepsConfig.idAndLiveness.documentRestriction
      )
    }
  }, [verificationStepsConfig])

  const renderDocRestriction = (): ReactElement => {
    if (!documentRestriction) {
      return <></>
    }
    return (
      <div className="w-full">
        {Object.keys(documentRestriction).map((key) => {
          const element = docTypes.find((element: any) => element.key === key)

          if (!element) {
            return <></>
          }

          return (
            <div
              id={`btn-${key}`}
              key={key}
              className="flex flex-row items-center rounded-lg cursor-pointer border hover:border-primary mb-4 shadow"
              onClick={() => {
                const save = async (): Promise<void> => {
                  await verificationStore
                    .saveIdAndLivenessInformation({
                      selectedDocumentType: key
                    })
                    .catch((err) => console.log(err))
                  if (
                    verificationStore.idAndLiveness &&
                    verificationStore.idAndLiveness.documentsUploaded
                  ) {
                    setCurrentStep(LIVENESS_STEP.LIVENESS)
                  } else {
                    setCurrentStep(LIVENESS_STEP.DOCUMENT)
                  }
                }
                save().catch((err) => console.log(err))
              }}
            >
              <div className="m-2 bg-gray-200 rounded">{element.icon}</div>
              <div className="ml-2">{element.label}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      <div className="w-full">{renderDocRestriction()}</div>
    </div>
  )
}

export default DocumentSelect
