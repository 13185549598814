import User from './User.interface'

enum NodeType {
  ADD = 'ADD',
  LEGAL_PERSON = 'LEGAL_PERSON',
  NATURAL_PERSON = 'NATURAL_PERSON'
}

interface CreateNodeDto {
  parent: string
  type: NodeType
  user: User
}

export { NodeType }

export default CreateNodeDto
