import React, { ReactElement } from 'react'
import { LegalDocumentType } from '../../../enums/LegalDocumentType'
import {
  generateIconOfLegalDocumentType,
  generateKeyOfLegalDocumentType,
  generateLabelOfLegalDocumentType
} from '../../../stores/helper/legalDocument.helper'
import Information from '../../../components/information/Information'

interface Props {
  docTypes: LegalDocumentType[]
  onNext: (docType: LegalDocumentType) => void
  legalDocuments?: any
  disabled?: boolean
}

const LegalDocumentTypes = ({
  docTypes,
  onNext,
  legalDocuments,
  disabled = false
}: Props): ReactElement => {
  const renderState = (type: LegalDocumentType): String => {
    if (!legalDocuments) return ''

    return legalDocuments[generateKeyOfLegalDocumentType(type)]
      ? 'Uploaded'
      : ''
  }

  return (
    <>
      <Information
        className="mb-xl"
        text="Please make sure that the provided corporate documents represent the current status of the company and that they have been issued within the last 12 months."
      />
      <div className="grow flex flex-col w-full">
        {docTypes.map((docType) => {
          const label = generateLabelOfLegalDocumentType(docType)
          return (
            <div
              key={docType}
              className={`flex flex-row items-center justify-between rounded-lg ${
                !disabled ? 'cursor-pointer hover:border-primary' : ''
              } border  mb-4 shadow`}
              onClick={() => {
                if (!disabled) {
                  onNext(docType)
                }
              }}
            >
              <div
                className="flex justify-center items-center"
                id={`btn-${label.split(' ').join('')}`}
              >
                <div className="m-2 bg-gray-200 rounded">
                  {generateIconOfLegalDocumentType(docType)}
                </div>
                <div className="ml-2">{label}</div>
              </div>
              <div className="mr-3 text-sm text-green-500">
                {renderState(docType)}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default LegalDocumentTypes
