export enum InputOptionType {
  TEXT = 'TEXT',
  NOT_REQUIRED_TEXT = 'NOT_REQUIRED_TEXT',
  TEXT_AND_NUMBER = 'TEXT_AND_NUMBER',
  NOT_REQUIRED_TEXT_AND_NUMBER = 'NOT_REQUIRED_TEXT_AND_NUMBER',
  NUMBER = 'NUMBER',
  NOT_REQUIRED_NUMBER = 'NOT_REQUIRED_NUMBER',
  EMAIL = 'EMAIL',
  NOT_REQUIRED_EMAIL = 'NOT_REQUIRED_EMAIL'
}

export default InputOptionType
