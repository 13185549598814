import React from 'react'
import Button from '../../../button/Button'
import InformationBox from '../../../information/InformationBox'
import IdAndLivenessQR from './IdAndLivenessQR'
import { VerificationStore } from '../../../../stores/verificaton.store'

interface IdAndLivenessStartProps {
  verificationStore: VerificationStore
  onStartVerification: () => void
  customQr?: JSX.Element
}

const IdAndLivenessStart = ({
  verificationStore,
  onStartVerification,
  customQr
}: IdAndLivenessStartProps): JSX.Element => {
  return (
    <div className="flex-grow grid grid-cols-1 gap-3 md:grid-cols-2">
      <InformationBox
        title="Computer Verification"
        description="For the ID & Liveness Verification you will need a camera. To continue with this device, please start the process by clicking the button below."
      >
        <Button
          style="primary"
          title="Start Verification"
          onClick={onStartVerification}
        />
      </InformationBox>
      <InformationBox
        title="Mobile Verification"
        description="For the ID & Liveness Verification you will need a camera. If you would like to continue on your mobile device, please scan the QR-Code below."
      >
        {customQr ?? (
          <IdAndLivenessQR verificationStore={verificationStore} />
        )}
      </InformationBox>
    </div>
  )
}

export default IdAndLivenessStart
