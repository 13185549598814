import { observer } from 'mobx-react'
import { QRCodeSVG } from 'qrcode.react'
import React, { ReactElement, useEffect, useState } from 'react'
import { VerificationStore } from '../../../../stores/verificaton.store'
import Loader from '../../../loader/Loader'
import SmallText from '../../../text/SmallText'

interface Props {
  verificationStore: VerificationStore
}

const IdAndLivenessQR = ({ verificationStore }: Props): ReactElement => {
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  useEffect(() => {
    verificationStore
      .generateQRForIDAndLiveness()
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (verificationStore.idAndLivenessURL) {
      setQrCodeUrl(verificationStore.idAndLivenessURL)
    }
  }, [verificationStore.idAndLivenessURL])

  return (
    <>
      <div className="bg-white p-md border border-primary rounded-md flex justify-center items-center w-40 aspect-square">
        {qrCodeUrl !== '' && <QRCodeSVG value={qrCodeUrl} />}
        {qrCodeUrl === '' && <Loader color="var(--color-primary)" />}
      </div>
      <SmallText
        className="mt-sm cursor-pointer underline"
        onClick={() => {
          setQrCodeUrl('')
          verificationStore
            .generateQRForIDAndLiveness()
            .catch((err) => console.log(err))
        }}
      >
        Generate new QR-Code
      </SmallText>
    </>
  )
}

export default observer(IdAndLivenessQR)
