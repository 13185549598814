import React, { ReactElement, ReactNode, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { Control, useController, useFormContext } from 'react-hook-form'
import Loader from '../loader/Loader'
import Button from '../button/Button'
import { uploadRequirementsDefault } from '../../data/uploadRequirements'
import { UploadRequirement } from '../../dto/UploadRequirement.interface'
import InformationList from '../information/InformationList'

interface Props {
  control: Control<any, any>
  name: any
  infoText?: string
  isRequired?: boolean
  onChange?: () => void
  setIsSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>
  requirementsIn?: UploadRequirement[]
}

const FileUpload: React.FC<Props> = ({
  control,
  name,
  isRequired = true,
  infoText = '',
  requirementsIn,
  onChange,
  setIsSubmitDisabled
}: Props): ReactElement => {
  const requirements: UploadRequirement[] =
    requirementsIn ?? uploadRequirementsDefault

  const { field } = useController({
    control,
    name,
    rules: { required: isRequired }
  })
  const {
    formState: { errors }
  } = useFormContext()

  const [isLoading, setIsLoading] = useState(false)

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setIsLoading(true)
    // const response = await saveDocument(acceptedFiles[0])
    field.onChange(acceptedFiles[0])
    onChange && onChange()
    setIsSubmitDisabled && setIsSubmitDisabled(false)
    setIsLoading(false)
  }, [])

  // eslint-disable-next-line
  const { getRootProps, getInputProps } = useDropzone({
    // eslint-disable-next-line
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf']
    }
  })

  const renderContent = (): ReactElement => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center">
          <Loader color="0 0 0" size="20" />
          <div className="text-sm font-medium">Uploading</div>
        </div>
      )
    }
    return (
      <div className="flex flex-col items-center">
        <input {...getInputProps()} />
        <CloudArrowUpIcon className="w-7" />
        <p className="text-base font-semibold">DROP FILE HERE</p>
        <p className="lg:text-sm text-xs font-medium text-center">{infoText}</p>
        <div className="relative flex py-7 items-center">
          <div className="flex-grow border-2 border-black rounded-lg lg:w-8 w-5"></div>
          <span className="flex-shrink mx-4">OR</span>
          <div className="flex-grow border-2 border-black rounded-lg lg:w-8 w-5"></div>
        </div>
        <Button type="button" title="Browse File" style="primary" />
      </div>
    )
  }

  return (
    <>
      <div className="w-full bg-white drop-shadow-xl cursor-pointer rounded-lg p-5 border-2 border-dashed hover:border-primary max-h-64">
        <section className="w-full">
          <div {...getRootProps({ className: 'dropzone' })} className="h-auto">
            {renderContent()}
          </div>
        </section>
      </div>
      {requirements.length > 0 && (
        <div className="my-lg">
          <InformationList
            informationList={requirements}
            title="What you need:"
          />
        </div>
      )}
      <div className="h-7">
        {errors[name] != null && (
          <div className="text-red-600">
            {errors[name]?.message as ReactNode}
          </div>
        )}
      </div>
    </>
  )
}

export default FileUpload
