import { HomeIcon, PlusIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'
import React, { ReactElement } from 'react'
import { NodeType } from '../../../../dto/CreateNode.dto'
import { Node as NodeDto } from '../dto/Organigram'
import SmallText from '../../../../components/text/SmallText'
import H4 from '../../../../components/Header/H4'

interface Props {
  node: NodeDto
  onClick?: () => void
}

const Node = ({ node, onClick }: Props): ReactElement => {
  let name
  if (
    node.type === NodeType.LEGAL_PERSON &&
    node.user &&
    node.user.companyInfo
  ) {
    name = node.user?.companyInfo.companyName
  }

  if (
    node.type === NodeType.NATURAL_PERSON &&
    node.user &&
    node.user.personalInfo
  ) {
    name =
      String(node.user?.personalInfo.firstName) +
      ' ' +
      String(node.user?.personalInfo.lastName)
  }

  let percentage = 0

  if (node.user && node.user.companyInfo) {
    percentage = node.user?.companyInfo.ownerPercentage
  }

  if (node.user && node.user.personalInfo) {
    percentage = node.user?.personalInfo.ownerPercentage
  }

  if (node.type === NodeType.ADD) {
    return (
      <div className="flex justify-center">
        <div
          className="p-sm bg-primary rounded cursor-pointer"
          onClick={() => {
            if (onClick) {
              onClick()
            }
          }}
        >
          <PlusIcon className="w-6 h-6 text-white" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-center">
      <div
        className="relative rounded p-xs bg-backgroundLight flex flex-col justify-center items-center border min-h-[80px] min-w-[120px] cursor-pointer"
        onClick={() => {
          if (onClick) {
            onClick()
          }
        }}
      >
        <div className="absolute right-2 top-2">
          <div
            className={`${
              node.user?.verifiedData ? 'bg-primary' : 'bg-white'
            } border rounded-full h-4 w-4 p-[1px]`}
          >
            {node.user?.verifiedData && <CheckIcon className="text-white" />}
          </div>
        </div>
        {node.type === NodeType.LEGAL_PERSON && (
          <HomeIcon className="w-8 h-8 text-primary" />
        )}
        {node.type === NodeType.NATURAL_PERSON && (
          <UserCircleIcon className="w-8 h-8 text-primary" />
        )}
        {name && <H4 className="mt-xs">{name}</H4>}
        {percentage > 0 && (
          <SmallText style="secondary" className="mt-1">
            {percentage} %
          </SmallText>
        )}
      </div>
    </div>
  )
}

export default Node
