import React, { ReactElement, useState } from 'react'
import { Tree, TreeNode } from 'react-organizational-chart'
import Node from './nodes/Node'
import CompanyNode from './nodes/CompanyNode'
import { Node as NodeDto, UpdateNode } from './dto/Organigram'
import { generateAddNode } from './helper/organigramHelper'

import AddNodeModal from './AddNodeModal'
import kybStore from '../../../stores/kyb.store'
import { NodeType } from '../../../dto/CreateNode.dto'
import userStore from '../../../stores/user.store'
import { sumPercentages } from './helper/percentageHelper'
import VerificationStepType from '../../../enums/VerificationStepType'

interface Props {
  isFinish?: boolean
  organization: NodeDto | undefined
  initialNode?: string
  initialStep?: VerificationStepType
}

const BeneficialOwnerTree = ({
  isFinish = false,
  organization,
  initialNode,
  initialStep
}: Props): ReactElement => {
  const [initStep, setInitStep] = useState<VerificationStepType | undefined>(
    initialStep
  )

  const [currentAddButton, setCurrentAddButton] = useState<NodeDto | undefined>(
    undefined
  )

  const [open, setOpen] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const findParentNode = (
    root: NodeDto,
    nodeId: string,
    parent: NodeDto | null = null
  ): NodeDto | null => {
    if (root.id === nodeId) {
      return parent
    }

    if (root.children) {
      for (const child of root.children) {
        const foundNode = findParentNode(child, nodeId, root)
        if (foundNode) {
          return foundNode
        }
      }
    }

    return null
  }

  const appendChild = async (
    addNode: NodeDto | undefined,
    node: NodeDto | UpdateNode
  ): Promise<void> => {
    if (!organization) return

    if (!node.user) {
      return
    }
    if (addNode) {
      const parent = findParentNode(organization, addNode.id)
      if (parent) {
        const createNode = node as NodeDto
        if (!parent.children) parent.children = []
        parent.children.push(createNode)
        await kybStore
          .addNode({
            parent: parent.id,
            type: createNode.type,
            user: node.user
          })
          .catch((err) => console.log(err))
      }
    } else {
      if (node.id && node.user) {
        await kybStore.updateNode({
          id: node.id,
          user: node.user
        })
      }
    }
  }

  const renderRecursive = (node: NodeDto): ReactElement => {
    if (node.type !== NodeType.ADD && !node.id) {
      return <></>
    }

    if (node.id === initialNode && !initialized) {
      kybStore.setCurrentNode(node)
      setOpen(true)
      setInitialized(true)
    }

    if (!node.children) {
      node.children = []
    }

    if (
      !node.children.find((node) => node.type === NodeType.ADD) &&
      node.type === NodeType.LEGAL_PERSON &&
      sumPercentages(node) < 100
    ) {
      node.children.push(generateAddNode())
    }

    return (
      <TreeNode
        key={node.id ?? 0}
        label={
          <Node
            node={node}
            onClick={() => {
              if (node.type === NodeType.ADD) {
                setCurrentAddButton(node)
              } else {
                kybStore.setCurrentNode(node)
              }
              if (initStep) {
                setInitStep(undefined)
              }
              setOpen(true)
            }}
          />
        }
      >
        {node.children &&
          node.children
            .slice()
            .sort((a: NodeDto, b: NodeDto) => {
              if (!a.createdAt || !b.createdAt) {
                return 0
              }

              return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
              )
            })
            .sort((a, b) => {
              if (a.type === NodeType.ADD) return 1 // move 'Mary' to the end of the array
              if (b.type === NodeType.ADD) return -1 // move 'Mary' to the end of the array
              return 0 // leave other elements in their original order
            })
            .map((child: any) => renderRecursive(child))}
      </TreeNode>
    )
  }

  const renderOrganization = (): ReactElement => {
    if (!organization) return <></>

    if (!organization.children) {
      organization.children = []
    }

    if (
      !organization.children.find((element) => element.type === NodeType.ADD) &&
      sumPercentages(organization) < 100
    ) {
      organization.children.push(generateAddNode())
    }

    let name = ''
    if (userStore.user?.companyInfo?.companyName) {
      name = userStore.user.companyInfo.companyName
    }

    return (
      <Tree label={<CompanyNode name={name} />} lineWidth="2px">
        {organization.children &&
          organization.children
            .slice()
            .sort((a: NodeDto, b: NodeDto) => {
              if (!a.createdAt || !b.createdAt) {
                return 0
              }
              return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
              )
            })
            .sort((a, b) => {
              if (a.type === NodeType.ADD) return 1 // move 'Mary' to the end of the array
              if (b.type === NodeType.ADD) return -1 // move 'Mary' to the end of the array
              return 0 // leave other elements in their original order
            })
            .map((child: NodeDto) => renderRecursive(child))}
      </Tree>
    )
  }

  if (!organization) {
    return <></>
  }

  return (
    <>
      <div className="flex-1 p-3">{renderOrganization()}</div>
      <AddNodeModal
        open={open}
        setOpen={setOpen}
        readonly={isFinish}
        initialStep={initStep}
        addNode={(node) => {
          if (!isFinish) {
            appendChild(currentAddButton, node).catch((err) => console.log(err))
            setCurrentAddButton(undefined)
          }
        }}
      />
    </>
  )
}

export default BeneficialOwnerTree
