export const sourceOfFundsAndWealth = [
  {
    value: 'Savings from regular income',
    label: 'Savings from regular income'
  },
  { value: 'Inheritance', label: 'Inheritance' },
  { value: 'Gift', label: 'Gift' },
  { value: 'Business operations', label: 'Business operations' },
  { value: 'Sale of real estate', label: 'Sale of real estate' },
  { value: 'Sale of company', label: 'Sale of company' },
  {
    value: 'Sale of other assets (art, antiques, cars etc.)',
    label: 'Sale of other assets (art, antiques, cars etc.)'
  },
  {
    value: 'Profits from previous investments',
    label: 'Profits from previous investments'
  },
  { value: 'Other (please describe)', label: 'Other (please describe)' }
]
