import React, { ReactElement } from 'react'
import { Provider } from 'mobx-react'
import { stores } from './stores'
import PageLayout from './pages/PageLayout'
import CSSInjector from './theme/CSSInecjtor'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import LoginAndRegistrationPage from './pages/LoginAndRegistrationPage'
import SuccessLoginAndRegistrationPage from './pages/SuccessLoginAndRegistrationPage'
import VerificationProcessPage from './pages/VerificationProcessPage'

import UserPersonalInformation from './pages/verificationSteps/personalInformation/UserPersonalInformation'

import UserFinishedPage from './pages/FinishedPage/UserFinishedPage'
import 'react-tooltip/dist/react-tooltip.css'
import CompanyInformation from './pages/verificationSteps/companyInformation/CompanyInformation'
import CoperateOfficerVerification from './pages/verificationSteps/coperateOfficerVerification/CoperateOfficerVerification'
import LegalDocumentVerification from './pages/verificationSteps/legalDocumentVerification/LegalDocumentVerification'
import LegalDocumentUpload from './pages/verificationSteps/legalDocumentVerification/LegalDocumentUpload'
import DirectorPersonalInformation from './pages/verificationSteps/personalInformation/DirectorPersonalInformation'
import UserProofOfResidencyType from './pages/verificationSteps/proofOfResidency/UserProofOfResidencyType'
import UserProofOfResidencyDocument from './pages/verificationSteps/proofOfResidency/UserProofOfResidencyDocument'
import DirectorProofOfResidencyType from './pages/verificationSteps/proofOfResidency/DirectorProofOfResidencyType'
import DirectorProofOfResidencyDocument from './pages/verificationSteps/proofOfResidency/DirectorProofOfResidencyDocument'
import UserWallet from './pages/verificationSteps/wallet/UserWalletPage'
import UserFinicialInformation from './pages/verificationSteps/questionnaire/UserFinicialInformation'
import UserFundOfEvidence from './pages/verificationSteps/questionnaire/UserFundOfEvidence'
import UserIdAndLiveness from './pages/verificationSteps/idAndLiveness/UserIdAndLiveness'
import DirectorIdAndLiveness from './pages/verificationSteps/idAndLiveness/DirectorIdAndLiveness'
import UserFinishPage from './pages/Finish/UserFinishPage'
import DirectorFinishPage from './pages/Finish/DirectorFinishPage'
import BeneficalOwnerVerification from './pages/verificationSteps/beneficalOwnerVerification/BeneficalOwnerVerification'
import BeneficialOwnerFinish from './pages/verificationSteps/beneficalOwnerVerification/BeneficialOwnerFinish'

const App: React.FC = (): ReactElement => {
  return (
    <>
      <Provider {...stores}>
        <CSSInjector />
        <BrowserRouter>
          <Routes>
            <Route element={<PageLayout />}>
              <Route path="/" element={<LoginAndRegistrationPage />} />
              <Route
                path="verification-process"
                element={<VerificationProcessPage />}
              />
              <Route
                path="verfication-success"
                element={<SuccessLoginAndRegistrationPage />}
              />
              <Route
                path="personal-info"
                element={<UserPersonalInformation />}
              />
              <Route
                path="questionnaire"
                element={<UserFinicialInformation />}
              />
              <Route
                path="fund-source-evidence"
                element={<UserFundOfEvidence />}
              />
              <Route
                path="proof-of-residency"
                element={<UserProofOfResidencyType />}
              />
              <Route
                path="proof-of-residency-doc"
                element={<UserProofOfResidencyDocument />}
              />
              <Route path="id-and-liveness" element={<UserIdAndLiveness />} />
              <Route path="finish" element={<UserFinishPage />} />
              <Route path="finished" element={<UserFinishedPage />} />
              <Route path="wallet" element={<UserWallet />} />
              <Route path="company-info" element={<CompanyInformation />} />
              <Route
                path="officer-verification"
                element={<CoperateOfficerVerification />}
              />
              <Route
                path="officer-verification/personal-info"
                element={<DirectorPersonalInformation />}
              />

              <Route
                path="officer-verification/id-and-liveness"
                element={<DirectorIdAndLiveness />}
              />
              <Route
                path="officer-verification/proof-of-residency"
                element={<DirectorProofOfResidencyType />}
              />
              <Route
                path="officer-verification/proof-of-residency-doc"
                element={<DirectorProofOfResidencyDocument />}
              />
              <Route
                path="officer-verification/finish"
                element={<DirectorFinishPage />}
              />
              <Route
                path="legal-documents"
                element={<LegalDocumentVerification />}
              />
              <Route
                path="legal-documents/upload"
                element={<LegalDocumentUpload />}
              />
              <Route
                path="beneficial-owner"
                element={<BeneficalOwnerVerification />}
              />
              <Route
                path="finish-beneficial-owner"
                element={<BeneficialOwnerFinish />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </Provider>
    </>
  )
}

export default App
