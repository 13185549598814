import React, { ReactElement } from 'react'
import { Grid } from 'react-loader-spinner';

interface Props {
    color?: string
    size?: string
}

const Loader: React.FC<Props> = ({ color, size = '80' }: Props): ReactElement => {
    return (
        <Grid
            height={size}
            width={size}
            color={color ? `${color}` : '#ffffff'}
            ariaLabel='grid-loading'
            radius='12.5'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
        />
    )
}

export default Loader
