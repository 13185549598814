import React, { ReactElement, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Container from '../components/container/Container'
import { Outlet, useNavigate } from 'react-router-dom'
import configStore from '../stores/config.store'
import Loader from '../components/loader/Loader'
import queryString from 'query-string'

const PageLayout: React.FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    const loadConfig = async (): Promise<void> => {
      setIsLoading(true)
      await configStore
        ?.loadConfigFromServer()
        .catch((_err) => console.log(_err))
      setIsLoading(false)
    }

    loadConfig().catch((_err) => console.log(_err))
  }, [])

  useEffect(() => {
    const values = queryString.parse(location.search)
    if (values.token && values.verificationToken) {
      return
    }
    const token = localStorage.getItem('crypto_token')
    if (!token && location.pathname === '/verification-process') {
      window.location.href = '/'
    } else if (token && location.pathname === '/') {
      navigate('/verification-process')
    }
  }, [])

  if (isLoading) {
    return (
      <Container className="h-full flex justify-center items-center">
        <Loader />
      </Container>
    )
  }

  return (
    <Container className="h-full flex justify-center items-center">
      <Outlet />
    </Container>
  )
}

export default observer(PageLayout)
