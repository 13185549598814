import React, { ReactElement, useState } from 'react'
import VerificationStepType from '../../../../enums/VerificationStepType'
import KYBLegalDocumentsTypes from './KYBLegalDocumentsTypes'
import KYBLegalDocumentsVerification from './KYBLegalDocumentsVerification'
import { LegalDocumentType } from '../../../../enums/LegalDocumentType'

interface KYBLegalDocumentsProps {
  addNode: (node: any) => void
  back: (step: VerificationStepType | undefined) => void
  isSent?: boolean
}

const KYBLegalDocuments = ({
  addNode,
  back,
  isSent = false
}: KYBLegalDocumentsProps): ReactElement => {
  const [selectedType, setSelectedType] = useState<
    LegalDocumentType | undefined
  >(undefined)

  return (
    <>
      {!selectedType && (
        <KYBLegalDocumentsTypes
          disabled={isSent}
          back={() => {
            setSelectedType(undefined)
            back(undefined)
          }}
          next={(value: LegalDocumentType) => {
            setSelectedType(value)
          }}
        />
      )}
      {selectedType && (
        <KYBLegalDocumentsVerification
          documentType={selectedType}
          back={() => {
            setSelectedType(undefined)
            back(VerificationStepType.LEGAL_DOCUMENTS)
          }}
          addNode={(node) => {
            addNode(node)
          }}
        />
      )}
    </>
  )
}

export default KYBLegalDocuments
