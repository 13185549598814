import React, { ReactElement } from 'react'
import User from '../../../../../dto/User.interface'
import VerificationStepConfigEntity from '../../../../../dto/VerificationStepConfigEntity.interface'
import Checkbox from '../../../../checkbox/Checkbox'
import ButtonRow from '../../../../button/ButtonRow'
import { useNavigate } from 'react-router-dom'
import { getActiveVerificationSteps } from '../../../../../util/stepHelper'
import VerificationStepType from '../../../../../enums/VerificationStepType'
import ReviewPersonalInfo from './steps/PersonalInfo'
import ReviewIdAndLiveness from './steps/IdAndLiveness'
import ReviewProofOfResidency from './steps/ProofOfResidency'
import ReviewQuestionnaire from './steps/Questionnaire'
import ReviewWallet from './steps/Wallet'
import ReviewCompanyInfo from './steps/CompanyInfo'
import ReviewDirectorApproval from './steps/DirectorApproval'
import ReviewLegalDocuments from './steps/LegalDocuments'
import { Content } from '../../../../content/Content'
import Text from '../../../../text/Text'

export interface ReviewUserStepProps {
  user?: User
  parentUrl: string
  readonly?: boolean
}

interface Props {
  methods: any
  user: User
  verificationStepsConfig: VerificationStepConfigEntity
  parentUrl?: string
  done: () => void
  readonly?: boolean
}

const ReviewUserData = ({
  methods,
  user,
  verificationStepsConfig,
  parentUrl = '',
  done,
  readonly = false
}: Props): ReactElement => {
  if (!user) return <></>
  const navigate = useNavigate()

  const renderSteps = (): ReactElement => {
    const stepProps: ReviewUserStepProps = {
      user,
      parentUrl,
      readonly: false
    }
    return (
      <div className="flex-grow flex flex-col">
        {getActiveVerificationSteps(verificationStepsConfig).map(
          (step, index) => {
            switch (step) {
              case VerificationStepType.PERSONAL_INFO:
                return <ReviewPersonalInfo {...stepProps} />
              case VerificationStepType.ID_AND_LIVENESS:
                return <ReviewIdAndLiveness {...stepProps} readonly={true} />
              case VerificationStepType.PROOF_OF_RESIDENCY:
                return <ReviewProofOfResidency {...stepProps} />
              case VerificationStepType.QUESTIONNAIRE:
                return <ReviewQuestionnaire {...stepProps} />
              case VerificationStepType.WALLET:
                return <ReviewWallet {...stepProps} />
              case VerificationStepType.COMPANY_INFORMATION:
                return <ReviewCompanyInfo {...stepProps} />
              case VerificationStepType.DIRECTOR_APPROVAL:
                return <ReviewDirectorApproval {...stepProps} />
              case VerificationStepType.LEGAL_DOCUMENTS:
                return <ReviewLegalDocuments {...stepProps} />
              default:
                return <div key={index}></div>
            }
          }
        )}
      </div>
    )
  }

  const navBack = (): void => {
    if (!parentUrl) {
      navigate('/verification-process')
    } else {
      navigate(parentUrl)
    }
  }

  return (
    <>
      <Content>
        <Text>
          Please verify your data and make sure, everything is correct.
        </Text>
        {renderSteps()}
        <div className="mt-2xl">
          <Checkbox
            variable="data.verifiedUserData"
            text="I herewith confirm that all information provided is correct."
          />
        </div>
      </Content>
      <ButtonRow
        secondary={{ onClick: navBack }}
        primary={{
          onClick: done,
          disabled: !methods.watch('data.verifiedUserData')
        }}
      />
    </>
  )
}

export default ReviewUserData
