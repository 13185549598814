import React, { ReactElement, useEffect } from 'react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import FilePreview from '../../../../components/filepreview/FilePreview'
import FileUpload from '../../../../components/inputs/FileUpload'
import { FlowType } from '../../../../dto/User.interface'
import { generateKeyOfLegalDocumentType } from '../../../../stores/helper/legalDocument.helper'
import kybStore from '../../../../stores/kyb.store'
import { onPromise } from '../../../../util/formHelper'
import { Node, UpdateNode } from '../dto/Organigram'
import { uploadRequirementsLegalDocs } from '../../../../data/uploadRequirements'
import { LegalDocumentType } from '../../../../enums/LegalDocumentType'
import ButtonRow from '../../../../components/button/ButtonRow'
import { Content } from '../../../../components/content/Content'

interface KYBLegalDocumentsVerificationProps {
  addNode: (node: Node | UpdateNode) => void
  back: () => void
  documentType: LegalDocumentType
}

const KYBLegalDocumentsVerification = ({
  addNode,
  back,
  documentType
}: KYBLegalDocumentsVerificationProps): ReactElement => {
  const key = `documents.${generateKeyOfLegalDocumentType(documentType)}`

  const methods: UseFormReturn<any, any> = useForm<any>({
    mode: 'onSubmit'
  })

  useEffect(() => {
    methods.setValue('documents.documentType', documentType)
  }, [])

  useEffect(() => {
    if (kybStore.currentNode) {
      const { user } = kybStore.currentNode
      if (user && user.legalDocuments) {
        methods.setValue(`documents[${key}]`, user.legalDocuments[key])
      }
    }
  }, [kybStore.currentNode])

  const uploadInfoText = 'Drag and drop your PDF, JPG images here.'
  const onSubmit = async (data: any): Promise<void> => {
    const id = kybStore.currentNode ? kybStore.currentNode.id : ''
    const userId = kybStore.currentNode ? kybStore.currentNode.user?.id : ''
    addNode({
      id: id,
      user: {
        id: userId,
        flowType: FlowType.KYB_COMPANY,
        isTermsAndConditionsAccepted: true,
        legalDocuments: data.documents
      }
    })
    back()
  }

  const imageAlreadyUploaded = methods.watch(key)
  const canSave = true

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={onPromise(methods.handleSubmit(onSubmit))}
          className="flex-grow flex flex-col"
        >
          <Content>
            {!imageAlreadyUploaded && (
              <FileUpload
                control={methods.control}
                name={key}
                requirementsIn={uploadRequirementsLegalDocs}
                infoText={uploadInfoText}
              />
            )}
            {imageAlreadyUploaded && (
              <FilePreview
                file={imageAlreadyUploaded}
                requirementsIn={uploadRequirementsLegalDocs}
              />
            )}
          </Content>
          <ButtonRow
            secondary={{ onClick: back }}
            primary={{
              disabled: !canSave || !imageAlreadyUploaded,
              type: 'submit',
              title: 'Save'
            }}
          />
        </form>
      </FormProvider>
    </>
  )
}

export default KYBLegalDocumentsVerification
