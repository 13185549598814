import React, { ReactElement } from 'react'
import { ReviewUserStepProps } from '../ReviewUserData'
import UserCompletedItem from '../components/Item'
import UserCompletedList from '../components/List'
import UserCompletedSectionHeader from '../components/SectionHeader'

const ReviewWallet = ({
  user,
  parentUrl,
  readonly = false
}: ReviewUserStepProps): ReactElement => {
  if (!user?.wallet) return <></>

  const { wallet } = user
  return (
    <>
      <UserCompletedSectionHeader
        title="Wallet Check"
        path={`${parentUrl}/wallet`}
        readonly={readonly}
      />
      <UserCompletedList>
        <UserCompletedItem header="Address">
          {wallet.walletAddress}
        </UserCompletedItem>
      </UserCompletedList>
    </>
  )
}

export default ReviewWallet
