/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import DocumentCheck from './DocumentCheck/DocumentCheck'
import LivenessCheck from './LivenessCheck/LivenessCheck'
import { observer } from 'mobx-react'
import { VerificationStore } from '../../../../stores/verificaton.store'
import CardScrollable from '../../../CardScrollable/CardScrollable'
import { checkRedoNavigate } from '../../../../util/navigateHelper'
import userStore from '../../../../stores/user.store'
import DocumentSelect from './DocumentCheck/DocumentSelect'
import InformationList from '../../../information/InformationList'
import LivenessDocument from '../../../../dto/LivenessDocument.interface'
import VerificationStepConfigEntity from '../../../../dto/VerificationStepConfigEntity.interface'
import IdAndLivenessStart from './IdAndLivenessStart'

export enum LIVENESS_STEP {
  NONE,
  SELECT_DOCUMENT,
  DOCUMENT,
  LIVENESS
}

interface Props {
  verificationStore: VerificationStore
  verificationStepsConfig?: VerificationStepConfigEntity
}

const IdAndLiveness = ({
  verificationStore,
  verificationStepsConfig
}: Props): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(LIVENESS_STEP.NONE)
  const [documents, setDocuments] = useState<LivenessDocument[]>([])
  const [isDone, setIsDone] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const navigate = useNavigate()

  const navigateBack = (): void => {
    if (currentStep === LIVENESS_STEP.SELECT_DOCUMENT && !isMobile) {
      setCurrentStep(LIVENESS_STEP.NONE)
    } else {
      if (userStore.isRedirected) {
        if (window.location.href.includes('officer-verification')) {
          navigate('/officer-verification')
        } else {
          navigate('/verification-process')
        }
      } else {
        checkRedoNavigate(navigate, -1)
      }
    }
  }

  useEffect(() => {
    if (
      /webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobile(true)
      setCurrentStep(LIVENESS_STEP.SELECT_DOCUMENT)
    }

    verificationStore
      .loadIdAndlivenessInformation()
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (verificationStore.idAndLiveness) {
      if (
        verificationStore.idAndLiveness.livenessUploaded &&
        verificationStore.idAndLiveness.documentsUploaded
      ) {
        setIsDone(true)
      } else if (verificationStore.idAndLiveness.documentsUploaded) {
        setDocuments(verificationStore.idAndLiveness.documentImages ?? [])
      } else {
        setDocuments([])
      }
    } else {
      setIsDone(false)
    }
  }, [verificationStore.idAndLiveness])

  if (currentStep === LIVENESS_STEP.LIVENESS) {
    return (
      <LivenessCheck
        verificationStore={verificationStore}
        next={() => {
          setCurrentStep(LIVENESS_STEP.NONE)
          setIsDone(true)
        }}
        close={() => {
          setCurrentStep(LIVENESS_STEP.DOCUMENT)
        }}
      />
    )
  }

  if (currentStep === LIVENESS_STEP.DOCUMENT) {
    return (
      <DocumentCheck
        enableCard={true}
        navSelected={() => {
          setCurrentStep(LIVENESS_STEP.SELECT_DOCUMENT)
        }}
        customBack={() => {
          setCurrentStep(LIVENESS_STEP.NONE)
        }}
        initDocuments={documents}
        verificationStore={verificationStore}
        close={() => {
          if (
            verificationStore.idAndLiveness &&
            verificationStore.idAndLiveness.documentsUploaded
          ) {
            setCurrentStep(LIVENESS_STEP.LIVENESS)
          } else {
            setCurrentStep(LIVENESS_STEP.NONE)
          }
        }}
      />
    )
  }

  return (
    <CardScrollable
      title="ID & Liveness Verification"
      secondaryBtn={{
        onClick: navigateBack
      }}
    >
      <div className="flex flex-col">
        {isDone && (
          <div className="flex flex-col items-center justify-start text-center pt-3 flex-grow">
            <CheckCircleIcon className="text-green-500 w-16 h-16" />
            <p>You have finished the ID & Liveness check</p>
          </div>
        )}
        {!isDone && (
          <>
            <InformationList
              title="Allowed Identification Documents:"
              className="mb-lg"
              informationList={['Passport', 'National Identity Card']}
            />
            {currentStep === LIVENESS_STEP.SELECT_DOCUMENT ? (
              <DocumentSelect
                verificationStepsConfig={verificationStepsConfig}
                setCurrentStep={setCurrentStep}
              />
            ) : (
              <IdAndLivenessStart
                onStartVerification={() => {
                  if (!documents || documents.length <= 0) {
                    setCurrentStep(LIVENESS_STEP.SELECT_DOCUMENT)
                  } else {
                    setCurrentStep(LIVENESS_STEP.DOCUMENT)
                  }
                }}
                verificationStore={verificationStore}
              />
            )}
          </>
        )}
      </div>
    </CardScrollable>
  )
}

export default observer(IdAndLiveness)
