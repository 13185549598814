import { NavigateFunction } from 'react-router-dom'

const checkRedoNavigate = (navigate: NavigateFunction, to: any): void => {
  if (location.search?.includes('fromLink')) {
    navigate('/verification-process')
  } else if (typeof to === 'number' && to < 0 && !history.state) {
    navigate('/verification-process')
  } else {
    navigate(to)
  }
}

export { checkRedoNavigate }
