/* eslint-disable react/jsx-key */
import React, { ReactElement } from 'react'
import { ReviewUserStepProps } from '../ReviewUserData'
import FilePreview from '../../../../../filepreview/FilePreview'
import UserCompletedSectionHeader from '../components/SectionHeader'
import { getSortedRegulaImages } from '../../../../../../util/idAndLivenessHelper'

const ReviewIdAndLiveness = ({
  user,
  parentUrl,
  readonly = false
}: ReviewUserStepProps): ReactElement => {
  if (!user?.idAndLiveness) return <></>

  const { idAndLiveness } = user

  if (
    !idAndLiveness?.documentImages ||
    idAndLiveness?.documentImages.length === 0
  ) {
    return <></>
  }

  return (
    <>
      <UserCompletedSectionHeader
        title="Id & Liveness Verification"
        path={`${parentUrl}/id-and-liveness`}
        readonly={readonly}
      />
      {getSortedRegulaImages(idAndLiveness?.documentImages ?? []).map(
        (documentImage: any, index: any) => {
          return (
            <div className="mb-4">
              <FilePreview file={documentImage.value} requirementsIn={[]} />
            </div>
          )
        }
      )}
    </>
  )
}

export default ReviewIdAndLiveness
