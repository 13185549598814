import React, { ReactElement } from 'react'

interface Props {
  className?: string
  children?: React.ReactNode
}

const H4 = ({ children, className = '' }: Props): ReactElement => {
  return (
    <h4 className={`text-sm md:text-base font-semibold ${className}`}>{children}</h4>
  )
}

export default H4
