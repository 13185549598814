export const uploadRequirementsDefault = [
  {
    title: 'Good Quality',
    subTitle:
      'Please make sure the picture is of good quality and that no glares etc. occur on the ID.'
  },
  {
    title: 'Valid Document',
    subTitle: 'Please make sure that your ID is still valid.'
  }
]

export const uploadRequirementsLegalDocs = [
  {
    title: 'Good Qualitiy',
    subTitle: 'Please make sure that the document is well legible.'
  },
  {
    title: 'Valid Document',
    subTitle:
      'Please make sure that the document was issued within the last 12 months.'
  }
]

export const uploadRequirementsUtilityBill = [
  {
    title: 'Good Qualitiy',
    subTitle: 'Please make sure that the document is well legible.'
  },
  {
    title: 'Valid Document',
    subTitle:
      'Please make sure that the utility bill was issued within the last 3 months.'
  }
]
