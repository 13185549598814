import React, { ReactElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Select from 'react-select'
import TextInputSkeleton from '../TextInputSkeleton'
import SmallText from '../../text/SmallText'
import CustomDefaultOption from './Options/CustomDefaultOption'
import CustomDefaultControl from './Controls/CustomDefaultControl'

export interface DropdownProps {
  variable: any
  options: any
  title?: string
  className?: string
  required?: boolean
  disabled?: boolean
  menuPlacement?: 'auto' | 'top' | 'bottom'
  isSearchable?: boolean
  isLoading?: boolean
  customOption?: React.ComponentType<any>
  customControl?: React.ComponentType<any>
  hideTitle?: boolean
  hidePlaceholder?: boolean
}

const DropdownSelected: React.FC<DropdownProps> = ({
  variable,
  options,
  className = '',
  title,
  required = false,
  menuPlacement,
  disabled = false,
  isSearchable = true,
  customOption = CustomDefaultOption,
  customControl = CustomDefaultControl,
  isLoading,
  hideTitle = false,
  hidePlaceholder = false
}: DropdownProps): ReactElement => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  let error: any = errors
  variable.split('.').forEach((v: string) => {
    if (error && error[v]) {
      error = error[v]
    } else {
      error = null
    }
  })

  return (
    <>
      <div className={className}>
        {!hideTitle && (
          <SmallText style="secondary" className="mb-1">
            {title}
          </SmallText>
        )}
        <Controller
          name={variable}
          control={control}
          rules={{ required }}
          render={({ field: { onChange, value } }) => {
            if (isLoading) {
              return <TextInputSkeleton />
            }
            return (
              <Select
                isDisabled={disabled}
                value={value}
                menuPlacement={menuPlacement}
                menuPosition="fixed"
                menuPortalTarget={document.body}
                options={options}
                onChange={(val) => onChange(val)}
                placeholder={!hidePlaceholder ? title : undefined}
                components={{ Control: customControl, Option: customOption }}
                isSearchable={isSearchable}
                styles={{
                  menuList: (base) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 10000
                  }),
                  control: (base, state) => ({
                    ...base,
                    opacity: disabled ? 0.8 : 1,
                    background: 'var(--color-background-light)',
                    border: state.isFocused
                      ? '1px solid var(--color-primary)'
                      : error
                      ? '1px solid red'
                      : '1px solid rgb(229 231 235)', // default border color
                    boxShadow: 'none', // no box-shadow
                    ':hover': {
                      border: state.isFocused
                        ? '1px solid var(--color-primary)'
                        : '1px solid rgb(229 231 235)'
                    }
                  })
                }}
              />
            )
          }}
        />
      </div>
      <div className="h-3">
        {errors[variable] !== undefined && (
          <div className="text-red-600">Input required.</div>
        )}
      </div>
    </>
  )
}

export default DropdownSelected
