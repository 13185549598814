/* eslint-disable multiline-ternary */
import React, { ReactElement, useEffect, useState } from 'react'
import DocumentCheck from '../../../../../components/verification-steps/steps/idAndLiveness/DocumentCheck/DocumentCheck'
import LivenessDocument from '../../../../../dto/LivenessDocument.interface'
import { FlowType } from '../../../../../dto/User.interface'
import kybStore from '../../../../../stores/kyb.store'
import verificationStore from '../../../../../stores/verificaton.store'
import { Node, UpdateNode } from '../../dto/Organigram'
import ButtonRow from '../../../../../components/button/ButtonRow'
import { Content } from '../../../../../components/content/Content'
import { getSortedRegulaImages } from '../../../../../util/idAndLivenessHelper'
import { LIVENESS_STEP } from '../../../../../components/verification-steps/steps/idAndLiveness/IdAndLiveness'
import IdAndLivenessStart from '../../../../../components/verification-steps/steps/idAndLiveness/IdAndLivenessStart'
import InformationList from '../../../../../components/information/InformationList'
import BeneficialOwnerQr from '../../BeneficialOwnerQr'
import VerificationStepType from '../../../../../enums/VerificationStepType'

interface KybIdAndLivenessProps {
  addNode: (node: Node | UpdateNode) => void
  back: () => void
}

const KYBIDAndLiveness = ({
  addNode,
  back
}: KybIdAndLivenessProps): ReactElement => {
  const [currentStep, setCurrentStep] = useState(LIVENESS_STEP.NONE)
  const [document, setDocument] = useState<any>(undefined)
  const [reloadComponent, setReloadComponent] = useState<number>(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (
      /webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobile(true)
      setCurrentStep(LIVENESS_STEP.SELECT_DOCUMENT)
    }
  })

  useEffect(() => {
    const { currentNode } = kybStore
    if (currentNode && currentNode.user && currentNode.user.idAndLiveness) {
      const documents = getSortedRegulaImages(currentNode.user.idAndLiveness)
      if (documents[0]) {
        setDocument(documents[0])
      }
    }
  }, [kybStore.currentNode])

  const save = (documents: LivenessDocument[]): void => {
    const id = kybStore.currentNode ? kybStore.currentNode.id : ''
    const userId = kybStore.currentNode ? kybStore.currentNode.user?.id : ''
    addNode({
      id,
      user: {
        id: userId,
        flowType: FlowType.KYB_USER,
        isTermsAndConditionsAccepted: true,
        idAndLiveness: {
          documentImages: documents
        }
      }
    })
    back()
  }

  const renderContent = (content: JSX.Element): JSX.Element => {
    return (
      <>
        <Content>{content}</Content>
        <ButtonRow secondary={{ onClick: back }} />
      </>
    )
  }

  const navToStart = (): void => {
    if (isMobile) {
      back()
    } else {
      setCurrentStep(LIVENESS_STEP.NONE)
    }
  }

  console.log(kybStore.currentNode?.id)

  return (
    <>
      {document && renderContent(<img src={document.value} />)}
      {!document && currentStep === LIVENESS_STEP.NONE ? (
        renderContent(
          <>
            <InformationList
              title="Allowed Identification Documents:"
              className="mb-lg"
              informationList={['Passport', 'National Identity Card']}
            />
            <IdAndLivenessStart
              onStartVerification={() => {
                setCurrentStep(LIVENESS_STEP.SELECT_DOCUMENT)
              }}
              verificationStore={verificationStore}
              customQr={
                <BeneficialOwnerQr
                  stepType={VerificationStepType.ID_AND_LIVENESS}
                />
              }
            />
          </>
        )
      ) : (
        <DocumentCheck
          verificationStore={verificationStore}
          uploadEnabled
          key={reloadComponent}
          navSelected={() => {
            setReloadComponent(reloadComponent + 1)
          }}
          close={navToStart}
          customBack={navToStart}
          customSave={(documents) => {
            save(documents)
          }}
        />
      )}
    </>
  )
}

export default KYBIDAndLiveness
