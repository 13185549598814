import dayjs from 'dayjs'
import React, { ReactElement } from 'react'
import { ReviewUserStepProps } from '../ReviewUserData'
import UserCompletedAddress from '../components/Address'
import UserCompletedCountry from '../components/Country'
import UserCompletedItem from '../components/Item'
import UserCompletedList from '../components/List'
import UserCompletedSectionHeader from '../components/SectionHeader'

const ReviewDirectorApproval = ({
  user,
  parentUrl
}: ReviewUserStepProps): ReactElement => {
  if (!user?.directorApproval?.director?.personalInfo) {
    return <></>
  }

  const { director } = user.directorApproval
  const { personalInfo } = director

  return (
    <>
      <UserCompletedSectionHeader
        title="Director Verification"
        path={`${parentUrl}/officer-verification`}
      />
      <UserCompletedList>
        <UserCompletedItem header="Email address">{director.email}</UserCompletedItem>
        <UserCompletedItem header="First Name" newLine>
          {personalInfo.firstName}
        </UserCompletedItem>
        <UserCompletedItem header="Middle Name">
          {personalInfo.middleName ? personalInfo.middleName : '-'}
        </UserCompletedItem>
        <UserCompletedItem header="Last Name">
          {personalInfo.lastName}
        </UserCompletedItem>
        <UserCompletedItem header="Date of Birth">
          {dayjs(personalInfo?.dateOfBirth).format('DD.MM.YYYY')}
        </UserCompletedItem>
        <UserCompletedCountry
          header="Nationality"
          country={personalInfo?.nationality}
        />
      </UserCompletedList>
      <UserCompletedAddress address={personalInfo.address} />
    </>
  )
}

export default ReviewDirectorApproval
