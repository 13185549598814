export const branches = [
  {
    value: 'Advanced Financial Services',
    label: 'Advanced Financial Services',
    description: 'Exchange offices, money transmitters etc.'
  },
  {
    value: 'Agriculture, Forestry, Fishing and Hunting',
    label: 'Agriculture, Forestry, Fishing and Hunting',
    description:
      'Fishing, agriculture, forestry, animal breeding, wood processing'
  },
  {
    value: 'Armament',
    label: 'Armament',
    description:
      'Developers, manufacturers, traders and/or brokers of armaments, weapons, war material or military technology'
  },
  {
    value: 'Art and culture (no art trade)',
    label: 'Art and culture (no art trade)',
    description:
      'Painter, dancer, singer, sculptor, writer, photographer, actor, director, theater, fashion designer etc.'
  },
  {
    value: 'Blockchain / Crypto',
    label: 'Blockchain / Crypto',
    description:
      'Exchange, token issuer, token custodian, market place, wallet provider, lending and borrowing platform, staking provider, launchpad, incubator, investment fund, asset manager and other service providers in the blockchain/crypto industry.'
  },
  {
    value: 'Chemistry and Pharma',
    label: 'Chemistry and Pharma',
    description:
      'Companies that are exclusively or predominantly involved in the conversion of natural raw materials and in the production of synthetic raw materials: Paints, chemicals, medicines, fertilisers, plant and/or pesticides, plastics, adhesives.'
  },
  {
    value: 'Construction',
    label: 'Construction',
    description:
      'Construction, architect, road construction, ancillary construction business'
  },
  {
    value: 'Consulting Services',
    label: 'Consulting Services',
    description:
      'Tax advisor, consulting, business and management consulting, lawyer, audit and accounting, bookkeeping'
  },
  {
    value: 'Craft',
    label: 'Craft',
    description:
      'Mason, carpenter, plumber, mechanic, painter, cleaning professional, hairdresser, cosmetician, electrician, heating and ventilation engineer, interior design, tailor, shoemaker, etc. Main criterion: SME, no industrial or mass production'
  },
  {
    value: 'Education',
    label: 'Education',
    description:
      'Teacher, professor, academic assistant, scientist of an educational institution, further education provider'
  },
  {
    value: 'Entertainment',
    label: 'Entertainment',
    description:
      'Casino, horse racing track, lottery companies, betting office, gambling, erotic industry or red light milieu, night club, disco, bar, escort agency'
  },
  {
    value: 'Financial Services',
    label: 'Financial Services',
    description:
      'Bank, fiduciary, family office, insurance company, insurance broker and intermediary, asset manager etc.'
  },
  {
    value: 'Food',
    label: 'Food',
    description:
      'Food producers: butchers, bakers, winemakers etc. (no industrial production)'
  },
  {
    value: 'Healthcare',
    label: 'Healthcare',
    description:
      'Doctor, psychologist, dentist, dental technician, nurse, cosmetic surgeon, private clinic operator, pharmacist, optician, orthopedist, alternative practitioner, veterinarian etc.'
  },
  {
    value: 'IT and Telecommunications',
    label: 'IT and Telecommunications',
    description:
      'Hardware and software manufacturer, web designer, IT professional, computer scientist, telecommunication'
  },
  {
    value: 'Luxury and Wholesale Trade',
    label: 'Luxury and Wholesale Trade',
    description:
      'Luxury goods trade, e.g.: Wholesale and intermediate trade, watch trade, car trade, aircraft trade, art trade etc.'
  },
  {
    value: 'Manufacturing and Production',
    label: 'Manufacturing and Production',
    description:
      'Mechanical engineering, e.g. production of vehicles and spare parts for them, production of consumer goods or goods for final and intermediate consumers, clothing production, industrial production of food/beverages (incl. alcohol and tobacco), recycling, processing industry and production'
  },
  {
    value: 'Media and Advertising',
    label: 'Media and Advertising',
    description:
      'Newspaper and/or magazine editor, publisher, advertising agency, PR manager, journalist, TV and film producer, radio and TV broadcaster'
  },
  {
    value: 'Other (please describe)',
    label: 'Other (please describe)',
    description: 'Please provide a detailed description below'
  },
  {
    value: 'Public Service',
    label: 'Public Service',
    description:
      'Civil servants, politicians, employees in public companies and offices, judges, soldiers, police officers, ambassadors, embassy employees, public prosecutors etc.'
  },
  {
    value: 'Raw Materials / Commodities',
    label: 'Raw Materials / Commodities',
    description:
      'Raw material extractors, processors and traders: extraction, processing and sale of natural resources: mining, coal, oil and gas, marble, sand, gravel, copper, chalk, iron, aluminum and other metals, steel production, glass production, rubber processing, paper manufacture'
  },
  {
    value: 'Real Estate',
    label: 'Real Estate',
    description: 'Sale, rental, development'
  },
  {
    value: 'Retail Trade',
    label: 'Retail Trade',
    description:
      'Trading companies and/or mail-order company, retail trade (no manufacturing)'
  },
  {
    value: 'Retirement',
    label: 'Retirement',
    description: 'Persons who live off their (saved) assets'
  },
  {
    value: 'Sport',
    label: 'Sport',
    description: 'Athletes, coaches, officials, referees etc.'
  },
  {
    value: 'Tourism and Leisure Industry',
    label: 'Tourism and Leisure Industry',
    description:
      'Restaurant and catering business, travel agency, hotel, bowling center etc.'
  },
  {
    value: 'Transportation and Warehousing',
    label: 'Transportation and Warehousing',
    description:
      'Transportation companies for the transport of goods and passengers, logistics, cab, shipping company, railroad company, airlines, product storage and warehousing (only private sector)'
  },
  {
    value: 'Unemployed',
    label: 'Unemployed',
    description:
      'Person without gainful employment, student, minor, housewife, privateer etc.'
  }
]
