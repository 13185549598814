import React, { ReactElement, useEffect, useState } from 'react'
import { FaceLivenessWebComponent } from '@regulaforensics/vp-frontend-face-components-beta'
import Liveness from '../../../../../dto/Liveness'
import configStore from '../../../../../stores/config.store'
import Card from '../../../../card/Card'
import Loader from '../../../../loader/Loader'
import { VerificationStore } from '../../../../../stores/verificaton.store'

interface Props {
  verificationStore: VerificationStore
  close: () => void
  next: () => void
}

const LivenessCheck = ({
  close,
  next,
  verificationStore
}: Props): ReactElement => {
  const component = React.useRef<FaceLivenessWebComponent>(null)

  const [loading, setLoading] = useState(false)

  const memoizedListener = React.useCallback((event: any) => {
    if (event.detail.action === 'CLOSE') {
      close()
    }

    if (
      event.detail.action === 'PROCESS_FINISHED' &&
      event.detail.data.status === 1
    ) {
      const response = event.detail.data.response
      saveDocuments({
        images: response.images,
        transactionId: response.transactionId
      }).catch((err) => console.log(err))
    }
  }, [])

  useEffect(() => {
    const componentCurrent = component.current

    if (componentCurrent) {
      componentCurrent.addEventListener('face-liveness', memoizedListener)
    }

    return () => {
      if (componentCurrent) {
        componentCurrent.removeEventListener('face-liveness', memoizedListener)
      }
    }
  }, [])

  const saveDocuments = async (liveness: Liveness): Promise<void> => {
    setLoading(true)
    await verificationStore
      .saveIdAndLivenessInformation({
        liveness: {
          ...liveness
        }
      })
      .catch((err) => console.log(err))
    setLoading(false)
    next()
  }

  if (loading) {
    return (
      <Card className="flex items-center justify-center">
        <Loader color={configStore?.config?.primaryColor} />
      </Card>
    )
  }

  return (
    <div className="flex justify-center items-center rounded-md overflow-hidden">
      <face-liveness
        ref={component}
        video-recording
        locale="en"
        url={process.env.REACT_APP_LIVENESS_URL}
      ></face-liveness>
    </div>
  )
}

export default LivenessCheck
