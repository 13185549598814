/* eslint-disable @typescript-eslint/space-before-function-paren */
import { action, makeObservable, observable } from 'mobx'
import {
  createNode,
  updateNode,
  deleteNode,
  loadTree,
  verifyNode,
  completeBeneficialOwner,
  completeLegalDocuments,
  generateBeneficialOwnerQrCode
} from '../api/benificialOwnerApi'

import { loadConfig } from '../api/configAPI'
import Config from '../dto/Config.interface'
import CreateNodeDto from '../dto/CreateNode.dto'
import {
  Node,
  UpdateNode
} from '../pages/verificationSteps/beneficalOwnerVerification/dto/Organigram'
import userStore from './user.store'
import { VerificationStepStatus } from '../enums/VerificationStepStatus'
import VerificationStepType from '../enums/VerificationStepType'

export class KYBStore {
  @observable configUser: Config | undefined
  @observable configCompany: Config | undefined
  @observable treeRoot: Node | undefined
  @observable currentNode: Node | undefined
  @observable nodeLoading: boolean = false

  @observable currentQrUrl: string | undefined

  constructor() {
    makeObservable(this)
  }

  @action
  setLoading(isLoading: boolean): void {
    this.nodeLoading = isLoading
  }

  @action
  loadConfigFromServer = async (): Promise<any> => {
    const response = await loadConfig('KYB_USER')

    if (response?.status === 200) {
      this.configUser = response?.data
    }
  }

  @action
  setConfigCompany(config: Config | undefined): void {
    this.configCompany = config
  }

  loadCompanyConfigFromServer = async (): Promise<any> => {
    const response = await loadConfig('KYB_COMPANY')

    if (response?.status === 200) {
      this.setConfigCompany(response?.data)
    }
  }

  @action
  setCurrentNode = (node: Node | undefined): void => {
    this.currentNode = node
  }

  @action
  completeBeneficialOwner = async (): Promise<any> => {
    await completeBeneficialOwner()
  }

  addNode = async (node: CreateNodeDto): Promise<any> => {
    const response = await createNode(node)
    this.setLoading(true)
    if (response.status === 201) {
      this.getTree().catch((err) => console.log(err))
      this.setCurrentNode(response.data)
      this.setLoading(false)
    }
  }

  @action
  verifyNode = async (id: string): Promise<any> => {
    const response = await verifyNode(id)
    if (response.status === 200) {
      this.getTree().catch((err) => console.log(err))
    }
  }

  @action
  deleteNode = async (id: string): Promise<any> => {
    const response = await deleteNode(id)
    if (response.status === 200) {
      await this.getTree().catch((err) => console.log(err))

      if (
        userStore.user?.beneficialOwner?.status &&
        userStore.user.beneficialOwner.status !== VerificationStepStatus.STARTED
      ) {
        userStore.getMe().catch((err) => console.log(err))
      }
    }
  }

  updateNode = async (node: UpdateNode): Promise<any> => {
    this.setLoading(true)
    const response = await updateNode(node)
    if (response.status === 200 || response.status === 201) {
      this.getTree().catch((err) => console.log(err))
      this.setCurrentNode(response.data)
      this.setLoading(false)
    }
  }

  completeLegalDocuments = async (id: string): Promise<any> => {
    this.setLoading(true)
    const response = await completeLegalDocuments(id)
    if (response.status === 200) {
      this.getTree().catch((err) => console.log(err))
      this.setCurrentNode(response.data)
      this.setLoading(false)
    }
  }

  @action
  setTreeRoot(treeRoot: Node): void {
    this.treeRoot = treeRoot
  }

  getTree = async (): Promise<any> => {
    const response = await loadTree()
    if (response.status === 200) {
      this.setTreeRoot(response.data)
    }
  }

  @action
  generateQr = async (stepType: VerificationStepType): Promise<any> => {
    try {
      const result = await generateBeneficialOwnerQrCode({
        node: this.currentNode,
        step: stepType
      })
      if (result.status === 200) {
        this.currentQrUrl = result.data.url
      }
    } catch (err) {}
  }
}

const kybStore = new KYBStore()
export default kybStore
